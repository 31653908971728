import { graphql } from "@data/gql"

export const cabinetPrincipalQuery = graphql(`
  query cabinetPrincipalQuery($identifiantProfil: String!) {
    cabinetPrincipal(identifiantProfil: $identifiantProfil) {
      equipeMedicale {
        id
        civilite
        nom
        prenom
        role
        photo
      }
    }
  }
`)
