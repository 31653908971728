import { classNames } from "@primary/design-system"
import { Select } from "@headlessui/react"
import { useTranslation } from "react-i18next"

export const LigneDateDepistage = ({
  libelle,
  choix,
  onSelect,
  valeur,
  testId,
  disabled,
  invisible,
}: {
  libelle: string
  choix: (string | number)[]
  onSelect: (valeur: string) => void
  valeur: string | number | undefined
  testId: string
  disabled?: boolean
  invisible?: boolean
}) => {
  return (
    <div
      className={classNames(
        "ml-6 flex max-w-lg items-center justify-between gap-2",
        invisible ? "invisible" : "",
      )}
      data-testid={testId}
    >
      <div className="px-2 py-1 text-p-tiny">{libelle}</div>
      <div className={"px-2 py-1 text-h6 text-dark-plum"}>
        <Selector
          selected={valeur}
          choix={choix}
          onSelect={onSelect}
          testId={`select-${testId}`}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

type SelectorProps = {
  selected: string | number | undefined
  choix: (string | number)[]
  onSelect: (valeur: string) => void
  disabled?: boolean
  testId: string
}

const Selector = ({
  selected,
  choix,
  onSelect,
  disabled,
  testId,
}: SelectorProps) => {
  // TODO(acuisinier): move to DS
  const { t } = useTranslation()
  const emptySelection = selected === ""

  return (
    <div className="flex w-32 items-center rounded bg-extra-light-grey px-2 py-1 text-h6 text-dark-plum hover:cursor-pointer">
      <Select
        name="status"
        className="w-full bg-extra-light-grey outline-none hover:cursor-pointer disabled:cursor-default disabled:text-grey"
        value={!emptySelection ? selected : t("nonRenseigne")}
        onChange={(event) => {
          onSelect(event.target.value)
        }}
        disabled={disabled}
        data-testid={testId}
      >
        {choix.map((valeur) => (
          <option key={valeur}>{valeur}</option>
        ))}
        {emptySelection && (
          <option key={t("nonRenseigne")}>{t("nonRenseigne")}</option>
        )}
      </Select>
    </div>
  )
}
