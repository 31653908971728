import { ShimmerProvider } from "@ds"
import { AnalyticsIdentityBridge } from "@infra/analytics/AnalyticsIdentityBridge"
import { AuthProvider, Protected } from "@infra/auth"
import { StreamChatProvider } from "@infra/messagerie/StreamChatProvider"
import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { AnyRouter, RouterProvider } from "@tanstack/react-router"
import { asyncWithLDProvider, basicLogger } from "launchdarkly-react-client-sdk"
import React, { useMemo, useState } from "react"
import { di } from "@di"
import { TourProvider } from "@reactour/tour"
import { ApolloProvider, Client } from "@primary/data"

interface AppProps {
  router: AnyRouter
}

export const App = ({ router }: AppProps) => {
  const [LaunchDarklyProvider, setLaunchDarklyProvider] =
    useState<
      ({ children }: { children: React.ReactNode }) => React.JSX.Element | null
    >()

  const { apolloClient } = useMemo(() => {
    return new Client(
      {
        token: di.AuthService.getToken,
      },
      import.meta.env.VITE_BACKEND_URL,
    )
  }, [])

  React.useEffect(() => {
    const setup = async () => {
      const ProviderComponent = await asyncWithLDProvider({
        clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID as string,
        options: {
          logger: basicLogger({ level: "error" }),
        },
      })
      setLaunchDarklyProvider(() => ProviderComponent)
    }

    setup()
  }, [])

  if (!LaunchDarklyProvider) {
    return null // or a loading spinner
  }

  return (
    <React.StrictMode>
      <ShimmerProvider>
        <AuthProvider>
          <Protected
            component={() => (
              <LaunchDarklyProvider>
                <AnalyticsIdentityBridge />
                <ApolloProvider client={apolloClient}>
                  <QueryClientProvider
                    client={di.GraphQLQueryClient.queryClient}
                  >
                    <StreamChatProvider>
                      <TourProvider
                        steps={[]}
                        showNavigation={false}
                        showBadge={false}
                      >
                        <RouterProvider router={router} />
                      </TourProvider>
                      <ReactQueryDevtools />
                    </StreamChatProvider>
                  </QueryClientProvider>
                </ApolloProvider>
              </LaunchDarklyProvider>
            )}
          />
        </AuthProvider>
      </ShimmerProvider>
    </React.StrictMode>
  )
}
