import { useTranslation } from "react-i18next"
import { Phone } from "@primary/design-system"

export const NoChannel = () => {
  const { t } = useTranslation()

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4">
      <Phone />
      <div className="text-h6 text-grey">
        {t("messagerie.videConversations")}
      </div>
    </div>
  )
}
