import { graphql } from "@data/gql"

export const inscrireAuProgrammePrimary = graphql(`
  mutation inscrireAuProgrammePrimary(
    $identifiantProfilPatient: String!
    $identifiantMedecinReferent: String!
  ) {
    inscrireAuProgrammePrimary(
      identifiantProfilPatient: $identifiantProfilPatient
      identifiantMedecinReferent: $identifiantMedecinReferent
    )
  }
`)
