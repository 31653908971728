import { SidePanel } from "@ds"
import {
  contextePatientPanelRoute,
  preconsultationRoute,
} from "@infra/navigation/router"
import { useNavigate, useRouter } from "@tanstack/react-router"
import { ContextePatient } from "../components/ContextePatient"
import { BoutonMettreEnFormeSyntheseContexte } from "../components/BoutonMettreEnFormeSyntheseContexte"
import { PatientParIdentifiantQueryQuery } from "@data/gql/graphql"
import { Depistages } from "./Depistages"
import { di } from "@di"
import { patientParIdentifiantQuery } from "@data/patient/patientParIdentifiantQuery"

export const ContextePatientPanel = () => {
  const navigate = useNavigate()
  const { id } = preconsultationRoute.useParams()
  const { identifiantProfil } = contextePatientPanelRoute.useParams()
  const close = () => navigate({ to: preconsultationRoute.to, params: { id } })
  const router = useRouter()
  const { data } = contextePatientPanelRoute.useLoaderData()
  const patientParIdentifiant = data?.patientParIdentifiant
  const queryClient = di.GraphQLQueryClient.queryClient

  return (
    <SidePanel onClose={close}>
      <ContextePatient
        identifiantProfil={identifiantProfil}
        align={"column"}
        patient={
          patientParIdentifiant as PatientParIdentifiantQueryQuery["patientParIdentifiant"]
        }
        boutonMettreEnForme={BoutonMettreEnFormeSyntheseContexte}
        onChange={() => router.invalidate()}
      />
      <Depistages
        patientId={
          (
            patientParIdentifiant as PatientParIdentifiantQueryQuery["patientParIdentifiant"]
          ).id
        }
        onChange={async () => {
          router.invalidate()
          await queryClient.invalidateQueries({
            queryKey: [
              patientParIdentifiantQuery,
              { identifiant: identifiantProfil },
            ],
          })
        }}
      />
    </SidePanel>
  )
}
