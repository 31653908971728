import { useTranslation } from "react-i18next"
import { useChatContext, useTypingContext } from "stream-chat-react"

export const CustomTypingIndicator = () => {
  const { t } = useTranslation()
  const { typing } = useTypingContext()
  const { client } = useChatContext()

  const otherUsersTyping = Object.values(typing ?? {}).filter(
    (user) => user.user?.id !== client?.user?.id,
  )

  const typingUserNames = otherUsersTyping
    .map((user) => user.user?.prenom + " " + user.user?.nom)
    .join(", ")

  return (
    <div className="mt-2 flex h-5 items-start p-1 text-p-tiny text-light-grey">
      {otherUsersTyping?.length > 0 && (
        <>
          {typingUserNames}{" "}
          {otherUsersTyping?.length > 1
            ? t("plusieursPersonnesEcrivent")
            : t("unePersonneEcrit")}
        </>
      )}
    </div>
  )
}
