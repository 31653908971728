import { recherchePatientsQuery } from "@data/patient/recherchePatientQuery"
import { useGraphQLQuery } from "@data/useGraphQL"
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react"
import { rootRoute } from "@infra/navigation/rootRoute"
import { patientRoute } from "@infra/navigation/router"
import { useState } from "react"
import {
  PatientSelectionne,
  PatientTrouve,
} from "@features/patient/pages/PatientTrouve"
import { SearchBar } from "@features/patient/pages/SearchBar"
import { MagnifyingGlass } from "@primary/design-system"
import { useTranslation } from "react-i18next"

export const RecherchePatientModal = () => {
  const { t } = useTranslation()
  const { modal } = rootRoute.useSearch()
  const navigate = rootRoute.useNavigate()
  const isOpen = modal === true
  const [recherche, setRecherche] = useState("")

  // TODO(judithp): [Apollo] Use Apollo instead.
  const { data } = useGraphQLQuery({
    document: recherchePatientsQuery,
    variables: { recherche },
    enabled: recherche.length > 2,
    keepDataWhileLoading: recherche.length > 2,
  })

  const close = () => {
    setRecherche("")
    navigate({
      search: (prev: Record<string, unknown>) => ({
        ...prev,
        modal: undefined,
      }),
    })
  }

  const navigateToPatient = (patientSelectionne: PatientSelectionne) => {
    setRecherche("")
    navigate({ to: patientRoute.to, params: { id: patientSelectionne.id } })
  }
  return (
    <Dialog
      open={isOpen}
      onClose={close}
      className="relative z-50"
      data-testid="recherche-patient-modal"
    >
      <div className="fixed inset-0 grid w-screen grid-cols-4 grid-rows-4 bg-secondary-black">
        <DialogPanel className="col-span-2 col-start-2 row-start-2">
          <DialogTitle className="w-full justify-center rounded-xl bg-white p-6">
            <SearchBar
              icon={
                <MagnifyingGlass className="stroke-dark-plum text-xl text-light-grey group-hover:stroke-white" />
              }
              placeholder={t("rechercherUnPatient")}
              onClose={close}
              onType={setRecherche}
            />
            {data?.data?.patients && data?.data?.patients.length > 0 && (
              <>
                <div className="pt-4" />
                <div className="border-t border-t-light-grey pt-4">
                  {data?.data?.patients?.map((patient) => (
                    <PatientTrouve
                      key={patient.id}
                      patient={patient}
                      selectPatient={navigateToPatient}
                    />
                  ))}
                </div>
              </>
            )}
          </DialogTitle>
        </DialogPanel>
      </div>
    </Dialog>
  )
}
