import { useGQLMutation, useGraphQLQuery } from "@data/useGraphQL"
import { rendezVousParIdentifiantQuery } from "@data/rendezVous/queries/rendezVousParIdentifiantQuery"
import { PageLayout } from "@ds"
import { HourGlass, Subtract, EnteteTag } from "@primary/design-system"
import { preconsultationRoute } from "@infra/navigation/router"
import { t } from "i18next"
import { formatRelative, isBefore, isEqual } from "date-fns"
import { Outlet, useNavigate } from "@tanstack/react-router"
import { Synthese } from "@features/rendezVous/detailPreconsultation/components/Synthese"
import { fr } from "date-fns/locale"
import frLocaleJson from "@locale/fr.json"
import { ToolbarSynthese } from "../components/ToolbarSynthese"
import { SidebarSynthese } from "../components/SidebarSynthese"
import { useCallback, useEffect, useMemo, useState } from "react"
import { commencerPreconsultation as commencerPreconsultationQuery } from "@data/preconsultation/mutations/commencerPreconsultation"
import { rendezVousParCabinetQuery } from "@data/rendezVous/queries/rendezVousParCabinetQuery"
import {
  StatutDePreconsultation,
  StatutProchainDepistage,
} from "@data/gql/graphql"
import { EntetePatient } from "../components/EntetePatient"
import { rootRoute } from "@infra/navigation/rootRoute"
import { patientParIdentifiantQuery } from "@data/patient/patientParIdentifiantQuery"

export const DetailDUnePreconsultation = () => {
  const navigate = useNavigate()
  const { id: identifiant } = preconsultationRoute.useParams()
  // TODO(judithp): [Apollo] Use Apollo instead.
  const { data } = useGraphQLQuery({
    document: rendezVousParIdentifiantQuery,
    variables: { identifiant },
    staleTime: 10000,
  })

  // TODO(judithp): [Apollo] Use Apollo instead.
  const { data: patientData } = useGraphQLQuery({
    document: patientParIdentifiantQuery,
    variables: {
      // @ts-expect-error("TODO: move to a loader")
      identifiant: data?.data?.rendezVousParIdentifiant?.patient?.id,
    },
    enabled: !!data?.data?.rendezVousParIdentifiant?.patient?.id,
  })

  // TODO(judithp): [Apollo] Use Apollo instead.
  const { mutate: commencerPreconsultation } = useGQLMutation(
    commencerPreconsultationQuery,
    {
      invalidateQueryKeys: [
        [rendezVousParIdentifiantQuery, { identifiant }],
        [rendezVousParCabinetQuery],
      ],
    },
  )

  const rendezVous = data?.data?.rendezVousParIdentifiant
  const patient = patientData?.data?.patientParIdentifiant

  const [dejaCommencee, setDejaCommencee] = useState<boolean>(false) // preconsultation.validee || preconsultation.enCours == true

  const today = Date.now()

  const depistagesNonVerifies = useMemo(() => {
    if (
      patientData?.data?.patientParIdentifiant &&
      patientData?.data?.patientParIdentifiant.depistages
    ) {
      const depistagesEligiblesNonRenseignes =
        patientData?.data?.patientParIdentifiant.depistages
          .filter(
            (depistage) =>
              isBefore(depistage.dateDEligibilite, today) ||
              isEqual(depistage.dateDEligibilite, today),
          )
          .filter(
            (depistage) =>
              depistage.statutProchainDepistage ===
              StatutProchainDepistage.NonRenseigne,
          )
      return depistagesEligiblesNonRenseignes.length > 0
    } else {
      return false
    }
  }, [patientData])

  useEffect(() => {
    if (
      rendezVous?.preconsultation?.validee ||
      rendezVous?.preconsultation?.statutDePreconsultation ===
        StatutDePreconsultation.EnCours
    ) {
      setDejaCommencee(true)
    }
  }, [rendezVous])

  const passerEnCours = useCallback(async () => {
    if (!dejaCommencee) {
      await commencerPreconsultation({ identifiantDuRendezVous: identifiant })
      setDejaCommencee(true)
    }
  }, [commencerPreconsultation, dejaCommencee, identifiant])

  return (
    <PageLayout className="flex flex-col">
      {rendezVous && patient && (
        <div className="mx-10 mt-4 flex flex-1 flex-col">
          <EntetePatient
            patient={patient}
            onBack={() => navigate({ to: rootRoute.to })}
            info={
              <div className="flex flex-row gap-1">
                <EnteteTag
                  color="bg-secondary-blue"
                  content={
                    t("consultation") +
                    " · " +
                    formatRelative(rendezVous.dateHeureDeDebut, new Date(), {
                      locale: {
                        ...fr,
                        formatRelative: (token) =>
                          frLocaleJson["formatRelative"][token],
                      },
                    })
                  }
                />
                <EnteteTag
                  color="bg-secondary-blue"
                  icon={
                    rendezVous.medecin?.photo && (
                      <img
                        className="h-4 w-4 rounded-xl"
                        src={rendezVous.medecin.photo}
                      />
                    )
                  }
                  content={
                    rendezVous.medecin.civilite + " " + rendezVous.medecin.nom
                  }
                />
                {rendezVous.preconsultation.validee && (
                  <EnteteTag
                    color="bg-secondary-green"
                    content={t("preparationTerminee")}
                    icon={
                      <Subtract
                        data-testid="preparation-validee"
                        className="pb-[1px] text-[10px] text-valid"
                      />
                    }
                  />
                )}
                {rendezVous.preconsultation.statutDePreconsultation ===
                  StatutDePreconsultation.EnCours && (
                  <EnteteTag
                    color="bg-secondary-yellow"
                    content={t("preparationEnCours")}
                    icon={
                      <HourGlass
                        data-testid="preparation-encours"
                        className="text-h5 text-yellow"
                      />
                    }
                  />
                )}
              </div>
            }
            showContexte={true}
          />
          <Synthese
            className="flex-1"
            rendezVousId={rendezVous.id}
            synthese={rendezVous.preconsultation.synthese}
            toolbar={(markdown, setMarkdown, setDisable) => (
              <ToolbarSynthese
                className="sticky bottom-0"
                markdown={markdown}
                patient={rendezVous.patient}
                synthese={rendezVous.preconsultation.synthese}
                questionnaire={
                  rendezVous.preconsultation.preparation.questionnaire
                }
                validee={rendezVous.preconsultation.validee}
                dateHeureDeDebut={rendezVous.dateHeureDeDebut}
                consultationTerminee={rendezVous.consultationTerminee}
                consultationDePrevention={rendezVous?.rendezVousDePrevention}
                setMarkdown={setMarkdown}
                setMiseEnFormeEnCours={setDisable}
                depistagesNonVerifies={depistagesNonVerifies}
              />
            )}
            sidebar={(markdown) => <SidebarSynthese markdown={markdown} />}
            onEditionStarted={passerEnCours}
          />
        </div>
      )}
      <Outlet />
    </PageLayout>
  )
}
