import { graphql } from "@data/gql"
import { RendezVousParCabinetQueryQuery } from "@data/gql/graphql"

export type RendezVousParCabinetAPI =
  RendezVousParCabinetQueryQuery["rendezVousParCabinet"][number]

export const rendezVousParCabinetQuery = graphql(`
  query rendezVousParCabinetQuery(
    $dateDeDebut: OffsetDateTime!
    $dateDeFin: OffsetDateTime!
    $identifiantCabinet: String!
  ) {
    rendezVousParCabinet(
      dateDeDebut: $dateDeDebut
      dateDeFin: $dateDeFin
      identifiantCabinet: $identifiantCabinet
    ) {
      id
      dateHeureDeDebut
      dateHeureDeFin
      motif
      typeDeRendezVous
      rendezVousDePrevention
      patient {
        id
        prenom
        nom
        compte {
          identifiantUtilisateur
          numeroDeTelephone
          email
        }
        dateDeNaissance
        sexe
        nouveauPatient
        programme {
          statut
        }
      }
      consultationTerminee
      medecin {
        civilite
        nom
        nomLibelleLong
        photo
      }
      preconsultation {
        motif {
          nom
        }
        preparation {
          statut
          viaLLM
        }
        validee
      }
      preconsultationANePasFaire
      patientEnSalleDAttente
      lieu
      statut
    }
  }
`)
