import { Select } from "@headlessui/react"
import { CategoriesDeDocumentsDocument, useQuery } from "@primary/data"
import { ChevronDown, classNames } from "@primary/design-system"
import { useTranslation } from "react-i18next"

interface SelectCategorieDeDocumentProps {
  value: string
  onChange: (value: string) => unknown
  className?: string
  disabled?: boolean
}

export const SelectCategorieDeDocument = ({
  value,
  onChange,
  className,
  disabled,
}: SelectCategorieDeDocumentProps) => {
  const { t } = useTranslation()

  const { data } = useQuery(CategoriesDeDocumentsDocument, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  })

  return (
    <div className="relative">
      <Select
        disabled={disabled}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={classNames(
          "w-full appearance-none rounded-xl bg-extra-light-grey px-6 py-4 text-p-small text-dark-grey",
          className,
        )}
      >
        <>
          {value === "" && (
            <option key="null" value="">
              {t("messagerie.selectionnerUneCategorie")}
            </option>
          )}
          {data?.categoriesDeDocument.map((categorieDeDocument) => (
            <option key={categorieDeDocument.id} value={categorieDeDocument.id}>
              {categorieDeDocument.nom}
            </option>
          ))}
        </>
      </Select>
      <ChevronDown className="absolute right-4 top-1/2 -translate-y-1/2 text-p-medium" />
    </div>
  )
}
