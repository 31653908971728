import { Channel, useChatContext } from "stream-chat-react"
import { CustomTypingIndicator } from "@features/messagerie/CustomTypingIndicator"
import { CustomSystemMessage } from "@features/messagerie/CustomSystemMessage"
import { ChannelInner } from "@features/messagerie/ChannelInner"
import { CustomDateSeparator } from "./CustomDateSeparator"
import { useArchivage } from "./hooks/useArchivage"
import { conversationRoute } from "@infra/navigation/router"

export const Conversation = () => {
  const { client } = useChatContext()
  const { conversationId } = conversationRoute.useParams()

  const channel = client.getChannelById("messaging", conversationId, {})
  const { Modale, setAfficherConfirmation } = useArchivage(channel)

  return (
    <Channel
      channel={channel}
      UnreadMessagesSeparator={() => {
        return <div className="w-full border-[1px] border-t-extra-light-grey" />
      }}
      TypingIndicator={CustomTypingIndicator}
      UnreadMessagesNotification={() => <div />}
      MessageSystem={CustomSystemMessage}
      EmptyStateIndicator={() => null}
      DateSeparator={CustomDateSeparator}
      activeUnreadHandler={() => {
        // do nothing not to update document.title here
      }}
    >
      <ChannelInner
        channel={channel}
        setAfficherConfirmation={setAfficherConfirmation}
      />
      <Modale />
    </Channel>
  )
}
