import { intervalToDuration } from "date-fns"
import { TFunction } from "i18next"

export const estUnBebe = (dateDeNaissance: Date) => {
  const intervalle = intervalToDuration({
    start: dateDeNaissance,
    end: new Date(),
  })
  return (
    (intervalle.years && intervalle.years < 2) ||
    (!intervalle.years && intervalle.months && intervalle.months < 24)
  )
}

export const estUnEnfant = (dateDeNaissance: Date) => {
  const intervalle = intervalToDuration({
    start: dateDeNaissance,
    end: new Date(),
  })
  return intervalle.years && intervalle.years > 2 && intervalle.years < 13
}

export const agePatient = (
  dateDeNaissance: Date,
  t: TFunction<"translation", undefined>,
): string => {
  const intervalle = intervalToDuration({
    start: dateDeNaissance,
    end: new Date(),
  })
  if (estUnBebe(dateDeNaissance)) {
    if (intervalle.years) {
      const mois =
        intervalle.years * 12 + (intervalle.months ? intervalle.months : 0)
      return `${mois} ${t("mois")}`
    }
    return `${intervalle.months} ${t("mois")}`
  }
  return intervalle.years ? `${intervalle.years} ${t("ans")}` : `0 ${t("ans")}`
}
