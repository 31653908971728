import { classNames, Attachment } from "@primary/design-system"
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react"
import { useFlags } from "launchdarkly-react-client-sdk"
import { useState } from "react"
import { useTranslation } from "react-i18next"

interface ModalProps {
  open: boolean
  onClose: () => unknown
  identifiantDuProfilPatient: string
}

interface PieceJointeProps {
  className?: string
  modaleDossierPatient?: (props: ModalProps) => React.ReactElement
  modaleDUpload?: (props: ModalProps) => React.ReactElement
  identifiantDuProfilPatient: string
}

export const PieceJointe = ({
  className,
  modaleDUpload,
  modaleDossierPatient,
  identifiantDuProfilPatient,
}: PieceJointeProps) => {
  const { consoleMessagerieImportDocument } = useFlags()
  const { t } = useTranslation()
  const [modaleDossierPatientOpen, setModaleDossierPatientOpen] =
    useState(false)
  const [modaleDUploadOpen, setModaleDUploadOpen] = useState(false)
  if (consoleMessagerieImportDocument) {
    return (
      <>
        <Menu>
          <MenuButton
            className={classNames(
              "flex items-center justify-center rounded bg-extra-light-grey hover:opacity-60 active:opacity-100",
              className,
            )}
          >
            <Attachment className="text-h3 text-gray-400" />
          </MenuButton>
          <MenuItems
            anchor="bottom start"
            transition
            className="flex origin-bottom-left flex-col items-stretch rounded-lg bg-white p-3 shadow-drop-shadow transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
          >
            <MenuItem>
              <button
                className="rounded-lg p-2 text-left hover:bg-extra-light-grey"
                onClick={() => setModaleDossierPatientOpen(true)}
              >
                {t("messagerie.choisirParmiLesDocumentsPatient")}
              </button>
            </MenuItem>
            <MenuItem>
              <button
                className="rounded-lg p-2 text-left hover:bg-extra-light-grey"
                onClick={() => setModaleDUploadOpen(true)}
              >
                {t("messagerie.importerUnNouveauDocument")}
              </button>
            </MenuItem>
          </MenuItems>
        </Menu>
        {modaleDossierPatient?.({
          open: modaleDossierPatientOpen,
          onClose: () => setModaleDossierPatientOpen(false),
          identifiantDuProfilPatient,
        })}
        {modaleDUpload?.({
          open: modaleDUploadOpen,
          onClose: () => setModaleDUploadOpen(false),
          identifiantDuProfilPatient,
        })}
      </>
    )
  }
  // TODO: Clean after MEP
  return (
    <>
      <button
        onClick={() => setModaleDossierPatientOpen(true)}
        className={classNames(
          "flex items-center justify-center rounded-lg bg-white shadow-drop-shadow hover:opacity-60 active:opacity-100",
          className,
        )}
      >
        <Attachment className="text-h3 text-gray-400" />
      </button>
      {modaleDossierPatient?.({
        open: modaleDossierPatientOpen,
        onClose: () => setModaleDossierPatientOpen(false),
        identifiantDuProfilPatient,
      })}
    </>
  )
}
