import { di } from "@di"
import { createRootRouteWithContext, Outlet } from "@tanstack/react-router"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useAuth } from "@infra/auth"

type RootRouteSearchParams = {
  modal?: boolean
}

type PrimaryConsoleContext = {
  auth: ReturnType<typeof useAuth>
  user: string
  isAuthenticated: boolean
  loginWithRedirect: () => void
}

export const rootRoute = createRootRouteWithContext<PrimaryConsoleContext>()({
  component: () => {
    const { RecherchePatientModal } = di
    return (
      <>
        <Outlet />
        <RecherchePatientModal />
        <ToastContainer />
      </>
    )
  },
  validateSearch: (search: Record<string, unknown>): RootRouteSearchParams => {
    delete search["code"] // remove Auth0 code after redirect
    delete search["state"] // remove Auth0 state after redirect
    return {
      modal: typeof search.modal == "boolean" ? search.modal : undefined,
    }
  },
})
