import { ParticipantProps } from "@features/messagerie/Participant"
import { buildNomComplet } from "../domain/Soignant"
import { useChannelStateContext, useChatContext } from "stream-chat-react"
import { Soignant } from "../domain/Soignant"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { Modal } from "@ds"

export const useParticipants = () => {
  const { client } = useChatContext()
  const { channel } = useChannelStateContext()
  const { t } = useTranslation()
  const [medecinParticipant, setMedecinParticipant] = useState<Soignant | null>(
    null,
  )
  const auteur = buildNomComplet(client.user as Soignant)
  const auteurId = client.user!.id

  let participants =
    channel.state?.members &&
    Object.values(channel.state?.members)
      .map((member) => member.user)
      .filter((user) => user?.role === "soignant")
      .map((user) => user as unknown as ParticipantProps)
      .reduce(
        (categories, member) => [...categories, { ...member }],
        [] as Soignant[],
      )

  const updateParticipants = (soignantsSelectionnes: Soignant[]) => {
    participants =
      channel.state?.members &&
      Object.values(channel.state?.members)
        .map((member) => member.user)
        .filter((user) => user?.role === "soignant")
        .map((user) => user as unknown as ParticipantProps)
    const ids = participants.map((soignant) => soignant.id)
    const newIds = soignantsSelectionnes.map((soignant) => soignant.id)
    const soignantsAjoutes = soignantsSelectionnes.filter(
      (soignant) => !ids.includes(soignant.id),
    )
    const soignantsRetires = participants.filter(
      (soignant) => !newIds.includes(soignant.id),
    )
    if (soignantsAjoutes.length > 0) {
      soignantsAjoutes.forEach((soignant) => {
        channel.addMembers(
          [soignant.id],
          {
            //@ts-expect-error(force SDK to generate message id)
            id: null,
            type: "system",
            text:
              auteurId === soignant.id
                ? t("messagerie.aRejointLaConversation", { qui: auteur })
                : t("messagerie.aEteAjouteALaConversation", {
                    qui: auteur,
                    nouveauParticipant: buildNomComplet(soignant),
                  }),
            user_id: soignant.id,
            silent: true,
            event: { type: "member.added" },
          },
          {
            skip_push: true,
          },
        )
      })
    }
    if (soignantsRetires.length > 0) {
      soignantsRetires.forEach((soignant) => {
        if (soignant.metier === "medecin") {
          setMedecinParticipant(soignant)
        } else {
          enleverUnParticipant(soignant)
        }
      })
    }
  }

  const enleverUnParticipant = (soignant: Soignant) => {
    channel.removeMembers(
      [soignant.id],
      {
        //@ts-expect-error(force SDK to generate message id)
        id: null,
        type: "system",
        text:
          auteurId === soignant.id
            ? t("messagerie.aQuitteLaConversation", { qui: auteur })
            : t("messagerie.aEteRetireDeLaConversation", {
                qui: auteur,
                ancienParticipant: buildNomComplet(soignant),
              }),
        user_id: soignant.id,
        silent: true,
        event: { type: "member.removed" },
      },
      {
        skip_push: true,
      },
    )
  }

  const rejoindreConversation = () => {
    if (client.user) {
      channel.addMembers(
        [client.user?.id],
        {
          //@ts-expect-error(force SDK to generate message id)
          id: null,
          type: "system",
          text: t("messagerie.aRejointLaConversation", {
            qui: buildNomComplet(client.user as Soignant),
          }),
          user: client.user,
          silent: true,
          event: { type: "member.added" },
        },
        {
          skip_push: true,
        },
      )
    }
  }

  const ModaleDeConfirmationDeRetrait = () => {
    return (
      <>
        {medecinParticipant && (
          <Modal
            open={true}
            onClose={() => setMedecinParticipant(null)}
            onConfirm={async () => {
              enleverUnParticipant(medecinParticipant as Soignant)
              setMedecinParticipant(null)
            }}
            title={t("messagerie.titreConfirmationRetraitMedecin")}
            dataTestId={"modal-confirmation-retrait-medecin"}
            size="small"
          >
            {t("messagerie.messageConfirmationRetraitMedecin", {
              nomMedecin: buildNomComplet(medecinParticipant as Soignant),
            })}
          </Modal>
        )}
      </>
    )
  }

  return {
    ModaleDeConfirmationDeRetrait,
    participants,
    updateParticipants,
    rejoindreConversation,
  }
}
