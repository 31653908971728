import ReactDOM from "react-dom/client"
import "./index.css"
import { useAuth } from "@infra/auth"
import "./i18n.ts"
import { register } from "@di"
import { register as registerPages } from "@infra/navigation/router"
import { SelecteurDeCabinet } from "@features/cabinet/SelecteurDeCabinet"
import { PreConsultations } from "./features/rendezVous/PreConsultations.page"
import { ChargementDesCabinets } from "@features/cabinet/ChargementDesCabinets.page"
import { DetailDUnePreconsultation } from "@features/rendezVous/detailPreconsultation/pages/DetailDUnePreconsultation.page"
import { RecherchePatientModal } from "@features/patient/pages/RecherchePatient.modal"
import { QuestionnairePanel } from "@features/rendezVous/detailPreconsultation/pages/QuestionnairePanel.page"
import { Patient } from "@features/patient/pages/Patient.page"
import { MixpanelAnalytics } from "@infra/analytics/MixpanelAnalytics"
import { registerSW } from "virtual:pwa-register"
import { UserProfile } from "@features/user/UserProfile"
import { CallbackUpdateService } from "@infra/updates/CallbackUpdateService"
import * as Sentry from "@sentry/react"
import { MessagerieCabinet } from "@features/messagerie/MessagerieCabinet"
import { UnreadCount } from "@features/messagerie/UnreadCount"
import { pdfjs } from "react-pdf"
import "react-pdf/dist/Page/TextLayer.css"
import "react-pdf/dist/Page/AnnotationLayer.css"
import { Conversation } from "@features/messagerie/Conversation"
import { NoChannel } from "@features/messagerie/NoChannel"
import { ContextePatientPanel } from "@features/patient/pages/ContextePatientPanel"
import { GraphQLQueryClient } from "@infra/queryClient/GraphQLQueryClient"
import { AuthService } from "@infra/auth/AuthService"
import { PreferencesService } from "@infra/preferences/PreferencesService"
import { StreamChatService } from "@features/messagerie/infra/out/StreamChatService"
import { createRouter } from "@infra/navigation/createRouter"
import { App } from "./App"
import { UpdateAvailableCard } from "@features/consoleUpdates/UpdateAvailableCard"

const router = createRouter()

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString()

Sentry.init({
  enabled: import.meta.env.PROD,
  environment: import.meta.env.MODE,
  dsn: "https://90c2e51c41c99b278eefac1f029b208b@o4507311965732864.ingest.de.sentry.io/4507312025305168",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.tanstackRouterBrowserTracingIntegration(router),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    import.meta.env.VITE_FRONT_URL as string,
    import.meta.env.VITE_BACKEND_URL as string,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const rootElement = document.getElementById("root")!

const updateSW = registerSW({
  onNeedRefresh() {
    updateService.canUpdate = true
  },
  onRegisteredSW(_, registration) {
    if (registration) {
      setInterval(() => {
        registration.update()
      }, 60 * 1000) // Check for updates every minute
    }
  },
})

const updateService = new CallbackUpdateService(updateSW)

if ("serviceWorker" in navigator && import.meta.env.PROD) {
  navigator.serviceWorker.register("/service-worker.js", {
    type: "classic",
  })
}

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)

  const authService = new AuthService()
  const graphQLQueryClient = new GraphQLQueryClient(authService)
  const preferencesService = new PreferencesService()
  const streamChatService = new StreamChatService(
    graphQLQueryClient,
    preferencesService,
  )

  register({
    UnreadCount,
    SelecteurDeCabinet,
    RecherchePatientModal,
    analytics: new MixpanelAnalytics(),
    useAuth: useAuth,
    updateServiceWorker: updateSW,
    UpdateAvailableCard,
    UserProfile,
    updateService,
    AuthService: authService,
    GraphQLQueryClient: graphQLQueryClient,
    PreferencesService: preferencesService,
    StreamChatService: streamChatService,
  })

  registerPages({
    ChargementPage: ChargementDesCabinets,
    ConsultationsPage: PreConsultations,
    DetailPreconsultationPage: DetailDUnePreconsultation,
    QuestionnairePanel: QuestionnairePanel,
    PatientPage: Patient,
    MessageriePage: MessagerieCabinet,
    ConversationPage: Conversation,
    MessagerieIndexPage: NoChannel,
    ContextePatientPanel: ContextePatientPanel,
  })

  root.render(<App router={router} />)
}
