import { graphql } from "@data/gql"

export const refuserInscriptionAuProgrammePrimary = graphql(`
  mutation refuserInscriptionAuProgrammePrimary(
    $identifiantProfilPatient: String!
  ) {
    refuserInscriptionAuProgrammePrimary(
      identifiantProfilPatient: $identifiantProfilPatient
    )
  }
`)
