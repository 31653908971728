import { Shimmer } from "@ds"
import {
  classNames,
  PlainButton,
  Cross,
  Plus,
  Send,
} from "@primary/design-system"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  useChannelStateContext,
  useChatContext,
  useMessageInputContext,
} from "stream-chat-react"
import { Participant } from "./Participant"
import { ReponsesRapides } from "./ReponsesRapides"
import { Textarea } from "@headlessui/react"
import { useParticipants } from "@features/messagerie/hooks/useParticipants"
import { BoutonMettreEnForme } from "@features/rendezVous/detailPreconsultation/components/BoutonMettreEnForme"
import { aideALaRedactionQuery } from "@data/messagerie/aideALaRedactionQuery"
import { useGQLMutation } from "@data/useGraphQL"
import { useFlags } from "launchdarkly-react-client-sdk"
import { di } from "@di"
import { PieceJointe } from "./components/piecesJointes/PieceJointe"
import { ChoisirUnDocumentDialog } from "./components/piecesJointes/ChoisirUnDocumentDialog"
import { DocumentsQueryQuery } from "@data/gql/graphql"
import { PrevisualisationDocument } from "./PrevisualisationDocument"
import { ImporterUnNouveauDocumentDialog } from "./components/piecesJointes/ImporterUnNouveauDocumentDialog"

export interface CustomMessageInputProps {
  onClickReactiver: () => void
}

type Document = DocumentsQueryQuery["documents"][number]

export const CustomMessageInput = ({
  onClickReactiver,
}: CustomMessageInputProps) => {
  const { text, setText, handleChange, handleSubmit } = useMessageInputContext()
  const [documentsAJoindre, setDocumentsAJoindre] = useState<Document[]>([])
  const { client } = useChatContext()
  const { channel } = useChannelStateContext()
  const { t } = useTranslation()
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const { aideRedactionMessage } = useFlags()
  const { mutate: mettreEnForme, isPending: miseEnFormeEnCours } =
    // TODO(judithp): [Apollo] Use Apollo instead.
    useGQLMutation(aideALaRedactionQuery, {
      onSuccess: (res) => {
        di.analytics.trackEvent("Copilot Aide à la Rédaction", {
          type: "miseEnForme",
          inputLength: text.length,
          outputLength: JSON.stringify(res?.aideALaRedactionDeMessage).length,
        })
      },
    })

  const archive = !!channel.data?.archive_le

  const handleSubmitCustom = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    for (const document of documentsAJoindre) {
      await channel.sendMessage({
        // @ts-expect-error("customType" is not part of the Stream SDK types")
        customType: "DocumentEnvoyeV2",
        uuidDuDocument: document.id,
        uuidDuPatient: document.identifiantPatient,
        nomDeLaCategorie: document.categoriePrincipale.nom,
        nomDuDocument: document.nom,
        text: "Document",
      })
    }
    if (text && client.user) {
      handleSubmit(event, { user: { id: client.user.id } })
    }
  }

  const { participants, rejoindreConversation } = useParticipants()

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto"
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`
    }
  }, [text])

  if (archive) {
    return (
      <div className="mb-4 mt-2 flex h-16 items-center justify-center gap-4 rounded-xl bg-extra-light-grey py-4">
        <div data-testid="reactiver-conversation">
          <PlainButton
            title={t("messagerie.reactiverCourt")}
            onClick={onClickReactiver}
            type="outlined"
          />
        </div>
      </div>
    )
  }

  if (
    Object.values(channel.state?.members).filter(
      (member) => member.user_id === client.user?.id,
    ).length === 0
  ) {
    return (
      // FIXME
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {!archive && (
          <div className="mb-4 mt-2 flex h-16 items-center justify-center gap-4 rounded-xl bg-extra-light-grey py-4">
            <div className="flex items-center gap-2 text-p-tiny">
              <div>{t("membreDuCabinet", { count: participants.length })}</div>
              <div className="flex -space-x-1">
                {participants
                  ?.reverse()
                  .map((participant) => (
                    <Participant
                      key={participant.id}
                      {...participant}
                      className="text-md h-7 w-7 border border-extra-light-grey"
                    />
                  ))}
              </div>
            </div>
            <div
              data-testid="rejoindre-conversation"
              onClick={rejoindreConversation}
            >
              <PlainButton
                title={t("rejoindre")}
                icon={<Plus />}
                type={"outlined"}
              />
            </div>
          </div>
        )}
      </>
    )
  }

  const disabledSend =
    (!text || text?.length < 1) && documentsAJoindre.length === 0

  return (
    <div className="mb-4 mt-2 flex flex-col items-stretch rounded-xl border-[1px] border-extra-light-grey p-1">
      <div className="mb-4 flex gap-3 overflow-auto">
        {documentsAJoindre.map((document) => (
          <DocumentJointPreview
            key={document.id}
            document={document}
            className="ml-2 mt-2"
            onDelete={() =>
              setDocumentsAJoindre((documentsAJoindre) =>
                documentsAJoindre.filter((d) => d.id !== document.id),
              )
            }
          />
        ))}
      </div>
      <div className="relative flex flex-col">
        <Textarea
          //autoFocus //TODO(anaisc): uncomment when no conversation rerender
          value={text}
          className={classNames(
            "m-2 resize-none overflow-hidden border-none bg-transparent text-p-small outline-none transition-opacity",
            {
              "opacity-0": miseEnFormeEnCours,
            },
          )}
          onChange={handleChange}
          disabled={miseEnFormeEnCours}
          placeholder={t("contenuDuMessage")}
          ref={inputRef}
        />
        {miseEnFormeEnCours && (
          <div className="absolute left-2 right-4 top-2 flex flex-wrap gap-x-2 gap-y-1">
            <Shimmer className="h-4 w-44 max-w-full rounded bg-extra-light-grey" />
            <Shimmer className="h-4 w-10 max-w-full rounded bg-extra-light-grey" />
            <Shimmer className="h-4 w-32 max-w-full rounded bg-extra-light-grey" />
            <div className="h-0 basis-full" />
            <Shimmer className="h-4 w-5 max-w-full rounded bg-extra-light-grey" />
            <Shimmer className="h-4 w-8 max-w-full rounded bg-extra-light-grey" />
          </div>
        )}
        <div className="flex items-stretch gap-2 p-4">
          <ReponsesRapides
            text={text}
            setText={(reponseRapide) => {
              setText(reponseRapide)
              // FIXME(tpucci): it does not focus the textarea...
              inputRef.current?.focus()
            }}
            className="w-9"
          />
          {import.meta.env.VITE_MESSAGERIE_IMPORT_DOCUMENT &&
            typeof channel.data?.idProfilPatient === "string" && (
              <PieceJointe
                className="w-9"
                identifiantDuProfilPatient={channel.data?.idProfilPatient}
                modaleDossierPatient={(props) => (
                  // FIXME
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {props.open && (
                      <ChoisirUnDocumentDialog
                        {...props}
                        onDocumentJoint={(document) => {
                          setDocumentsAJoindre((documentsAJoindre) => [
                            ...documentsAJoindre,
                            document,
                          ])
                        }}
                      />
                    )}
                  </>
                )}
                modaleDUpload={(props) => (
                  // FIXME
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {props.open && (
                      <ImporterUnNouveauDocumentDialog
                        {...props}
                        onDocumentJoint={(document) => {
                          setDocumentsAJoindre((documentsAJoindre) => [
                            ...documentsAJoindre,
                            document,
                          ])
                        }}
                      />
                    )}
                  </>
                )}
              />
            )}
          {aideRedactionMessage && (
            <BoutonMettreEnForme
              contenu={text}
              setContenu={setText}
              mettreEnForme={() =>
                new Promise((resolve, reject) =>
                  mettreEnForme(
                    // FIXME: not null assertion
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    { message: text, identifiantDeLaConversation: channel.id! },
                    {
                      onSuccess: (data) =>
                        resolve(data?.data?.aideALaRedactionDeMessage ?? text),
                      onError: reject,
                    },
                  ),
                )
              }
              miseEnFormeEnCours={miseEnFormeEnCours}
            />
          )}
          <div className="flex-1" />
          <button
            className={classNames(
              "flex w-9 items-center justify-center rounded text-h3",
              disabledSend ? "bg-extra-light-grey" : "bg-yellow",
            )}
            onClick={handleSubmitCustom}
            disabled={disabledSend}
          >
            <Send />
          </button>
        </div>
      </div>
    </div>
  )
}

const DocumentJointPreview = ({
  document,
  className,
  onDelete,
}: {
  document: Document
  className?: string
  onDelete: () => unknown
}) => {
  return (
    <div className={className}>
      <div className="flex flex-col items-center">
        <div className="relative mt-2">
          <PrevisualisationDocument
            identifiantDuDocument={document.id}
            nom=""
            className="h-24 w-16 rounded border border-light-grey bg-light-grey"
          />
          <button
            className="absolute -right-2 -top-2 z-50 flex h-5 w-5 items-center justify-center rounded-full bg-white shadow"
            onClick={onDelete}
          >
            <Cross className="text-md" />
          </button>
        </div>
        <div className="text-p-tiny">{document.nom}</div>
      </div>
    </div>
  )
}
