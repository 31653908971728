import { useTrackPageView } from "@infra/analytics/useTrackPageView"
import { useAuth } from "@infra/auth"
import { usePreferences } from "@infra/preferences/usePreferences"
import { useLDClient } from "launchdarkly-react-client-sdk"
import { useEffect } from "react"
import {
  appRoute,
  chargementPageRoute,
  contextePatientPanelRoute,
  conversationRoute,
  messagerieIndexRoute,
  messagerieRoute,
  patientRoute,
  preconsultationQuestionnairePanelRoute,
  preconsultationRoute,
} from "./router"
import { rootRoute } from "./rootRoute"
import {
  createRouter as createTSRouter,
  useNavigate,
} from "@tanstack/react-router"

const routeTree = rootRoute.addChildren([
  appRoute,
  chargementPageRoute,
  preconsultationRoute.addChildren([
    preconsultationQuestionnairePanelRoute,
    contextePatientPanelRoute,
  ]),
  patientRoute,
  messagerieRoute.addChildren([conversationRoute, messagerieIndexRoute]),
])

export const createRouter = () =>
  createTSRouter({
    routeTree,
    InnerWrap: ({ children }) => {
      const { isAuthenticated, getIdTokenClaims } = useAuth()
      const { identifiantDuCabinet } = usePreferences()
      const client = useLDClient()

      const navigate = useNavigate()
      useTrackPageView()
      useEffect(() => {
        if (client?.getContext().anonymous) {
          getIdTokenClaims().then((claims) => {
            if (claims) {
              client.identify({
                kind: "user",
                key: claims?.sub,
                name: claims?.name,
                email: claims?.email,
              })
            }
          })
        }
        if (isAuthenticated && !identifiantDuCabinet) {
          navigate({ to: chargementPageRoute.to })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isAuthenticated, identifiantDuCabinet, navigate])
      return children
    },
    context: {
      // FIXME: not null assertion
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      user: undefined!,
      isAuthenticated: false,
      // FIXME: not null assertion
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      loginWithRedirect: undefined!,
      // FIXME: not null assertion
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      auth: undefined!,
    },
  })
