import { graphql } from "@data/gql"

export const aideALaRedactionQuery = graphql(`
  query aideALaRedaction(
    $identifiantDeLaConversation: String!
    $message: String!
  ) {
    aideALaRedactionDeMessage(
      identifiantDeLaConversation: $identifiantDeLaConversation
      message: $message
    )
  }
`)
