import { streamChatQuery } from "@data/messagerie/streamChatQuery"
import { GraphQLQueryClient } from "@infra/queryClient/GraphQLQueryClient"
import {
  BehaviorSubject,
  combineLatest,
  delay,
  interval,
  mergeMap,
  retry,
  startWith,
} from "rxjs"
import {
  Channel,
  ChannelFilters,
  ChannelSort,
  DefaultGenerics,
  StreamChat,
} from "stream-chat"
import { IStreamChatService } from "./IStreamChatService"
import { MAX_QUERY_CHANNELS_LIMIT } from "stream-chat-react"
import { IPreferencesService } from "@infra/preferences/IPreferencesService"

const apiKey = import.meta.env.VITE_STREAM_API_KEY as string
const weekInMs = 7 * 24 * 60 * 60 * 1000

export class StreamChatService implements IStreamChatService {
  constructor(
    private readonly graphQLQueryClient: GraphQLQueryClient,
    private readonly preferencesService: IPreferencesService,
  ) {
    this.weeklyRefreshStreamChatClient()
    this.initializeMaBoiteChannels()
  }

  client = new BehaviorSubject(new StreamChat<DefaultGenerics>(apiKey))
  maBoiteChannels = new BehaviorSubject<Channel<DefaultGenerics>[]>([])

  private weeklyRefreshStreamChatClient = () => {
    interval(weekInMs)
      .pipe(
        startWith(0),
        delay(1000),
        mergeMap(async () => {
          const { data } = await this.graphQLQueryClient.fetch(
            streamChatQuery,
            {},
          )

          const token = data?.streamChat?.token
          const userId = data?.streamChat?.userId

          if (!!userId && !!token) {
            const client = new StreamChat<DefaultGenerics>(apiKey)

            const currentClient = this.client.value

            await client.connectUser({ id: userId }, token)
            this.client.next(client)

            currentClient.disconnectUser()
          }
        }),
        retry(),
      )
      .subscribe()
  }

  private initializeMaBoiteChannels = async () => {
    combineLatest([this.client, this.preferencesService.preferences]).subscribe(
      async ([client, preferences]) => {
        if (preferences.identifiantDuCabinet === null) {
          return
        }

        const { user } = client
        if (!user) {
          return
        }

        const isUserMedecin = user.metier === "medecin"
        const filtreDesConversations = filtreConversations(
          preferences.identifiantDuCabinet!,
          user.id,
          isUserMedecin,
        )

        const sort = isUserMedecin
          ? ({
              demandeAideMedecin: 1,
              last_message_at: -1,
            } as unknown as ChannelSort)
          : undefined
        const results = await client.queryChannels(
          filtreDesConversations,
          sort,
          {
            limit: MAX_QUERY_CHANNELS_LIMIT,
            message_limit: 300,
            watch: true,
          },
        )

        this.maBoiteChannels.next(results)
      },
    )
  }
}

const filtreConversations = (
  identifiantDuCabinet: string,
  clientUserId: string,
  isUserMedecin: boolean,
): ChannelFilters => {
  const baseFilter = {
    type: "messaging",
    last_message_at: { $gte: "2024-01-01T00:00:00.00Z" },
  }
  const memberFilter = { members: { $in: [clientUserId] } }
  const teamFilter = { team: identifiantDuCabinet }
  const nonArchiveFilter = { archive_le: { $exists: false } }

  switch (isUserMedecin) {
    case true:
      return {
        $and: [baseFilter, memberFilter, nonArchiveFilter],
      }

    case false:
      return {
        $and: [
          baseFilter,
          teamFilter,
          {
            $or: [memberFilter, { team: identifiantDuCabinet }],
          },
          nonArchiveFilter,
        ],
      }
  }
}
