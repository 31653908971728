import { useTranslation } from "react-i18next"
import { PatientParIdentifiantQueryQuery } from "@data/gql/graphql"

interface DescriptionExamenProps {
  depistage: PatientParIdentifiantQueryQuery["patientParIdentifiant"]["depistages"][0]
}

export const DescriptionExamen = ({ depistage }: DescriptionExamenProps) => {
  const { t } = useTranslation()

  return (
    <>
      {depistage.description && (
        <div
          className="flex max-w-lg items-center justify-between gap-2"
          data-testid="description"
        >
          <div className="px-2 py-1 text-p-tiny text-dark-grey">
            {depistage.description}
          </div>
        </div>
      )}
      {depistage.examen && (
        <div
          className="flex max-w-lg items-center justify-between gap-2"
          data-testid="conditions"
        >
          <div
            className="px-2 py-1 text-p-tiny text-dark-grey"
            // à cause de bilan sanguin & prélèvement
            dangerouslySetInnerHTML={{
              __html: t("examen", { nom: depistage.examen }),
            }}
          ></div>
        </div>
      )}
    </>
  )
}
