import { PageTitleLayoutWithMargin } from "@ds"
import { useTranslation } from "react-i18next"
import { useConnectedToChat } from "@infra/messagerie/useConnectedToChat"
import { useNouveauMessage } from "@features/messagerie/hooks/useNouveauMessage"
import { useFlags } from "launchdarkly-react-client-sdk"
import { usePreferences } from "@infra/preferences/usePreferences"
import { Outlet } from "@tanstack/react-router"
import { ListeDesConversations } from "./ListeDesConversations"

export const Messagerie = () => {
  const { t } = useTranslation()

  const { BoutonNouveauMessage, ModaleNouveauMessage } = useNouveauMessage()

  const { messagerieOutbound } = useFlags()

  const { filtreInboxMessagerie } = usePreferences()

  const isConnected = useConnectedToChat()
  if (!isConnected) return

  return (
    <PageTitleLayoutWithMargin
      title={t("messagerie.messagerie")}
      margin="ml-8 mt-8"
      className="flex h-screen flex-col"
      actions={
        <>
          {messagerieOutbound && <BoutonNouveauMessage />}
          {messagerieOutbound && <ModaleNouveauMessage />}
        </>
      }
    >
      {filtreInboxMessagerie && (
        <div className="mx-8 grid flex-1 grid-cols-[16rem_1fr] gap-4 overflow-hidden">
          <ListeDesConversations />
          <Outlet />
        </div>
      )}
    </PageTitleLayoutWithMargin>
  )
}
