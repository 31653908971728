import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react"
import { Avatar, ChevronDown, ChevronUp } from "@primary/design-system"
import {
  CabinetPrincipalQueryQuery,
  MembreEquipeMedicale,
} from "@data/gql/graphql"

interface ListeDesMedecinsReferentsProps {
  medecins: CabinetPrincipalQueryQuery["cabinetPrincipal"]["equipeMedicale"]
  selection: MembreEquipeMedicale | null | undefined
  setSelection: (value: MembreEquipeMedicale) => void
}

export const ListeDesMedecinsReferents = ({
  medecins,
  selection,
  setSelection,
}: ListeDesMedecinsReferentsProps) => {
  return (
    <Listbox value={selection} onChange={setSelection} as="div">
      {({ open }) => (
        <>
          <ListboxButton className="group w-80">
            <div className="flex w-80 items-center justify-between gap-2 rounded-md border border-transparent bg-extra-light-grey px-3 py-2 text-h6 group-hover:border-black group-hover:bg-white">
              {!selection && <div className="h-[20px]">&#160;</div>}
              {selection && (
                <div
                  data-testid={"selected-placeholder"}
                  className="flex items-center gap-2 truncate text-h5 text-dark-plum"
                >
                  <div>
                    {" "}
                    {selection?.photo ? (
                      <img className="h-5 w-5 rounded" src={selection.photo} />
                    ) : (
                      <Avatar
                        name={selection?.prenom + " " + selection?.nom}
                        style="small"
                      />
                    )}
                  </div>
                  <div className="text-h5 text-dark-plum">
                    {(selection?.civilite ? selection?.civilite + " " : "") +
                      selection?.nom}
                  </div>
                </div>
              )}
              <div className="text-[16px]">
                {open ? <ChevronUp /> : <ChevronDown />}
              </div>
            </div>
          </ListboxButton>
          <div className="relative">
            <ListboxOptions
              anchor="bottom"
              className="w-80 gap-1 overflow-auto rounded-xl bg-white p-3 shadow-light-shadow"
            >
              {medecins.map((membre) => (
                <ListboxOption
                  key={membre?.id}
                  data-testid={"medecin-referent-" + membre?.id}
                  value={membre}
                  className="flex cursor-pointer items-center gap-2 rounded-lg px-3 py-2 text-p-tiny ui-selected:bg-extra-light-grey ui-active:bg-extra-light-grey ui-not-active:bg-inherit"
                >
                  <div className="flex gap-2 truncate ui-selected:text-dark-plum">
                    <div>
                      {" "}
                      {membre?.photo ? (
                        <img className="h-5 w-5 rounded" src={membre.photo} />
                      ) : (
                        <Avatar
                          name={membre?.prenom + " " + membre.nom}
                          style="small"
                        />
                      )}
                    </div>
                    <div className="text-h5 font-light text-dark-plum">
                      {(membre?.civilite ? membre?.civilite + " " : "") +
                        membre?.nom}
                    </div>
                  </div>
                </ListboxOption>
              ))}
            </ListboxOptions>
          </div>
        </>
      )}
    </Listbox>
  )
}
