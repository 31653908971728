import {
  Field,
  Fieldset,
  Input,
  Label,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react"
import { Fragment, useRef, useState } from "react"
import { classNames, Bin, Plus, Vaccine } from "@primary/design-system"
import {
  DonneeBiometriqueProps,
  useDonneesSaisiesPatient,
} from "../hooks/useDonneesSaisiesPatient"
import { useTranslation } from "react-i18next"
import { FormVaccin } from "./FormVaccin"
import { useFlags } from "launchdarkly-react-client-sdk"

type DonneesDeSaisiesPatientProps = {
  rendezVousId: string
  onChange?: () => unknown
}

export const DonneesDeSaisiesPatient = ({
  rendezVousId: identifiant,
  onChange,
}: DonneesDeSaisiesPatientProps) => {
  const { donneesBiometriquesVaccins } = useFlags()
  const { t } = useTranslation()
  const {
    donneesAAfficher: donneesBiometriquesAAfficher,
    mettreAJourUneDonneeBiometrique,
    ajouterUneDonneeBiometrique,
    supprimerUneDonneeBiometrique,
    donneesDuMenu: donneesBiometriquesDuMenu,
    vaccins: vaccinsAAfficher,
    ajouterUnVaccin,
    mettreAJourUnVaccin,
    supprimerUnVaccin,
  } = useDonneesSaisiesPatient({
    rendezVousId: identifiant,
    onChange,
  })

  return (
    <div>
      <Fieldset
        className="grid w-full grid-cols-2 rounded-xl border"
        data-testid="liste-donnees-biometriques"
      >
        {donneesBiometriquesAAfficher.map((donnee) => (
          <ChampDonneeBiometrique
            key={donnee.code}
            donneeBiometrique={donnee}
            mettreAJour={mettreAJourUneDonneeBiometrique}
            supprimer={supprimerUneDonneeBiometrique}
          />
        ))}
        {donneesBiometriquesVaccins &&
          vaccinsAAfficher?.map((vaccin) => (
            <FormVaccin
              key={vaccin.index}
              vaccin={vaccin}
              mettreAJour={mettreAJourUnVaccin}
              supprimer={supprimerUnVaccin}
            />
          ))}
      </Fieldset>
      <Menu>
        <MenuButton
          className="hover:cursor-pointer disabled:cursor-not-allowed"
          disabled={donneesBiometriquesDuMenu.length === 0}
        >
          <div className="flex items-center justify-center gap-1 p-2 text-p-tiny text-grey">
            <Plus className="text-h4" />
            {t("ajouter")}
          </div>
        </MenuButton>
        <MenuItems
          anchor="top start"
          className="w-52 rounded-xl bg-white p-3 shadow-light-shadow"
        >
          {donneesBiometriquesDuMenu.map((donnees) => (
            <MenuItem key={"menu-" + donnees.code}>
              <div
                className="block cursor-pointer rounded-lg p-2 text-p-tiny hover:bg-extra-light-grey"
                onClick={() => ajouterUneDonneeBiometrique(donnees.code)}
              >
                <div className="flex flex-row items-center gap-1">
                  {donnees.icone}
                  {donnees.nom}
                </div>
              </div>
            </MenuItem>
          ))}
          {donneesBiometriquesVaccins && (
            <MenuItem key={"menu-vaccin"}>
              <div
                className="block cursor-pointer rounded-lg p-2 text-p-tiny hover:bg-extra-light-grey"
                onClick={ajouterUnVaccin}
              >
                <div className="flex flex-row items-center gap-1">
                  <Vaccine />
                  {t("vaccin")}
                </div>
              </div>
            </MenuItem>
          )}
        </MenuItems>
      </Menu>
    </div>
  )
}

type ChampDonneeBiometriqueProps = {
  donneeBiometrique: DonneeBiometriqueProps
  mettreAJour: (code: string, valeur: string | undefined) => void
  supprimer: (code: string) => void
}

export const ChampDonneeBiometrique = ({
  donneeBiometrique,
  mettreAJour,
  supprimer,
}: ChampDonneeBiometriqueProps) => {
  const { code, nom, valeur, unite, icone, suppression } = donneeBiometrique
  const [valeurMiseAJour, setValeurMiseAJour] = useState(valeur)
  const [showUnit, setShowUnit] = useState(false)
  const [error, setError] = useState(false)

  const ref = useRef<HTMLInputElement>(null)

  const inputSize =
    valeurMiseAJour?.toString().length &&
    valeurMiseAJour?.toString().length * 0.75 >= 1
      ? valeurMiseAJour?.toString().length
      : 1

  const setFocus = () => {
    ref?.current?.focus()
    setShowUnit(true)
  }

  return (
    <Field
      key={code}
      className={classNames(
        "p-2 text-p-small odd:border-r",
        valeurMiseAJour ? "text-black" : "text-grey",
      )}
    >
      <div className="group flex flex-row items-center justify-between rounded-xl p-2 hover:cursor-pointer hover:bg-extra-light-grey">
        <div
          className="flex flex-1 items-center gap-1"
          onClick={() => setFocus()}
        >
          <div className="flex min-w-0 flex-row items-center gap-1">
            {icone}
            <Label className="truncate">{nom}</Label>
          </div>
          <Input as={Fragment}>
            {({ focus }) => (
              <input
                ref={ref}
                data-testid={"input-" + code}
                className={classNames(
                  "border-box rounded p-2 group-hover:bg-extra-light-grey firefox:pr-0",
                  {
                    "bg-extra-light-grey outline-none": focus,
                    "border-2 border-error": error,
                  },
                )}
                size={inputSize}
                name={"valeur-" + code}
                type="text"
                onChange={(e) => {
                  setValeurMiseAJour(e.target?.value?.toString().trim())
                }}
                value={valeurMiseAJour || ""}
                onBlur={() => {
                  setShowUnit(false)
                  const valeurValide =
                    ChampDonneeBiometrique.donneeBiometriqueEstValide(
                      valeurMiseAJour?.toString() ?? "",
                    )
                  setError(!valeurValide)
                  if (valeurValide) {
                    mettreAJour(code, valeurMiseAJour?.toString())
                  }
                }}
                onFocus={() => setShowUnit(true)}
              />
            )}
          </Input>
          <div
            className={classNames(
              valeurMiseAJour && "text-black",
              showUnit && !valeurMiseAJour && "text-grey",
              !showUnit &&
                !valeurMiseAJour &&
                "text-transparent group-hover:text-grey",
            )}
          >
            {unite}
          </div>
        </div>

        {suppression && (
          <div
            className="rounded-md border border-transparent p-1 text-transparent hover:border hover:border-grey hover:text-grey group-hover:text-black"
            data-testid={"delete-" + code}
            onClick={() => {
              setShowUnit(false)
              setValeurMiseAJour(undefined)
              setError(false)
              supprimer(code)
            }}
          >
            <Bin />
          </div>
        )}
      </div>
    </Field>
  )
}

ChampDonneeBiometrique.donneeBiometriqueEstValide = (
  valeur: string,
): boolean => {
  if (valeur === "") return true
  const regex = /^\d+([.,]\d*)?$/
  return regex.test(valeur)
}
