import { Flag } from "@primary/design-system"
import { classNames } from "@primary/design-system"
import { Channel } from "stream-chat"
import { Participant, ParticipantProps } from "./Participant"
import { formatDistanceStrict } from "date-fns"
import { fr } from "date-fns/locale"
import { abregerUnites } from "./abregerUnites"
import { useChatContext } from "stream-chat-react"
import { useFlags } from "launchdarkly-react-client-sdk"
import { capitalize } from "@utils/capitalize"
import { conversationRoute } from "@infra/navigation/router"
import { Link } from "@tanstack/react-router"
import { highlight } from "@utils/highlight"
import { cropAround } from "@utils/crop"
import { ReactNode } from "react"

type ConversationCardProps = {
  latestMessage?: string | ReactNode
  channel: Channel
  unread?: number
  displaysNew?: boolean
  highlight?: string
  message?: string
}

export const ConversationCard = (props: ConversationCardProps) => {
  const { client } = useChatContext()
  const { messagerieMedecins } = useFlags()
  const channelData = props.channel.data
  const nom = (channelData?.created_by as { nom?: string })?.nom
  const prenom = (channelData?.created_by as { prenom?: string })?.prenom
  const titre = channelData?.objet as string
  const channelAvecDemandeAideMedecin = channelData?.demandeAideMedecin === true

  const channelAAuMoinsUneAM = Object.values(
    props.channel.state?.members ?? [],
  ).some((m) => m.user?.metier === "assistante_medicale")

  const archive = channelData?.archive_le !== undefined

  const nouvelleConversation = !channelAAuMoinsUneAM && !!props.displaysNew

  let unreadCount = props?.unread ?? 0
  if (client.user?.metier === "medecin") {
    if (!channelAvecDemandeAideMedecin) {
      unreadCount = 0
    }
  } else {
    if (nouvelleConversation) {
      unreadCount =
        props.channel?.state?.messages.filter(
          (message) => message.type === "regular",
        ).length ?? 0
    }
  }

  const participantsPrimary =
    props?.channel?.state?.members &&
    Object.values(props?.channel?.state?.members)
      .map((member) => member.user)
      .filter((user) => user?.role === "soignant")
      .map((user) => user as unknown as ParticipantProps)
      .reverse()

  let dernierMessageDepuiNTemps = ""
  if (props?.channel?.state?.last_message_at) {
    const dernierMessageDepuis = formatDistanceStrict(
      props?.channel?.state?.last_message_at as Date,
      new Date(),
      {
        locale: fr,
      },
    )
    dernierMessageDepuiNTemps = abregerUnites(dernierMessageDepuis)
  }

  const userEstMembre =
    (client?.userID &&
      Object.values(props.channel?.state?.members ?? []).some(
        (member) => member.user_id === client.userID,
      )) ||
    false

  return (
    <Link
      className={classNames(
        "group mt-1 flex flex-col gap-2 rounded-md p-3 hover:cursor-pointer hover:bg-extra-light-grey",
        archive && "opacity-50",
      )}
      data-testid="conversation-card"
      to={conversationRoute.to}
      params={{
        // FIXME: not null assertion
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        conversationId: props.channel.id!,
      }}
      activeProps={{
        className: "bg-extra-light-grey",
      }}
      onDoubleClick={() => {
        if (import.meta.env.MODE === "staging") {
          client.deleteChannels([props.channel.cid])
        }
      }}
    >
      {({ isActive }) => (
        <>
          <div
            className={classNames(
              "flex items-center gap-1",
              unreadCount ? "text-h6" : "text-p-tiny",
            )}
          >
            <div className="itemps-center flex flex-1 gap-1 overflow-hidden">
              {messagerieMedecins && channelAvecDemandeAideMedecin && (
                <Flag
                  className="rounded bg-yellow p-0.5 text-p-medium text-white"
                  data-testid="demande-aide-medecin-icone"
                />
              )}
              <div data-testid="patient-name" className="truncate">
                {highlight(
                  `${capitalize(prenom)} ${nom ? nom.toUpperCase() : ""}`,
                  props.highlight,
                )}
              </div>
            </div>
            <div
              className="relative flex flex-row gap-1"
              key={props.channel?.id}
            >
              <div className="flex -space-x-1">
                {participantsPrimary &&
                  participantsPrimary.length >= 1 &&
                  participantsPrimary.map((participant) => (
                    <Participant
                      key={participant.id}
                      {...participant}
                      className={classNames(
                        "border text-h7",
                        isActive
                          ? "border-extra-light-grey"
                          : "border-white group-hover:border-extra-light-grey",
                      )}
                    />
                  ))}
              </div>
            </div>
          </div>
          <div className="pointer-events-none flex items-end gap-1">
            <div
              className={classNames(
                "line-clamp-2 flex-1 overflow-hidden",
                unreadCount ? "text-h6" : "text-p-tiny",
              )}
              data-testid="channel-name"
            >
              {highlight(titre, props.highlight)}
              <div>
                {props?.message &&
                  highlight(
                    cropAround(props.message, props.highlight),
                    props.highlight,
                  )}
              </div>
            </div>
            <div className="flex flex-row items-center gap-1">
              <div
                className={classNames(
                  "flex text-p-tiny",
                  !userEstMembre || unreadCount === 0
                    ? "text-grey"
                    : "text-yellow",
                )}
              >
                {dernierMessageDepuiNTemps}
              </div>
              {unreadCount > 0 && (
                <div
                  className="flex h-4 min-w-4 items-center justify-center rounded-full bg-yellow p-1 text-p-tiny text-white"
                  data-testid="unread-count"
                >
                  {unreadCount}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </Link>
  )
}
