/* eslint-disable */
import * as types from './graphql';



/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
type Documents = {
    "\n  query membreEquipeMedicale {\n    membreEquipeMedicaleConnecte {\n      civilite\n      nom\n      prenom\n      photo\n    }\n  }\n": typeof types.MembreEquipeMedicaleDocument,
    "\n  query aideALaRedaction(\n    $identifiantDeLaConversation: String!\n    $message: String!\n  ) {\n    aideALaRedactionDeMessage(\n      identifiantDeLaConversation: $identifiantDeLaConversation\n      message: $message\n    )\n  }\n": typeof types.AideALaRedactionDocument,
    "\n  query categoriesDeDocuments {\n    categoriesDeDocument {\n      id\n      nom\n    }\n  }\n": typeof types.CategoriesDeDocumentsDocument,
    "\n  mutation creerUneConversationAvecLePatientMutation(\n    $identifiantProfil: String!\n    $identifiantDuCabinet: String!\n  ) {\n    creerUneConversationAvecLePatient(\n      identifiantProfil: $identifiantProfil\n      identifiantDuCabinet: $identifiantDuCabinet\n    ) {\n      identifiantStreamChat\n    }\n  }\n": typeof types.CreerUneConversationAvecLePatientMutationDocument,
    "\n  query demanderLImportDUnDocument(\n    $identifiantDuPatient: String!\n    $identifiantDuDocument: String!\n    $identifiantDeLaCategorie: String!\n    $nom: String!\n    $extension: String!\n  ) {\n    demandeDImportDeDocument(\n      identifiantDuPatient: $identifiantDuPatient\n      identifiantDuDocument: $identifiantDuDocument\n      identifiantDeLaCategorie: $identifiantDeLaCategorie\n      nom: $nom\n      extension: $extension\n    ) {\n      adresse\n    }\n  }\n": typeof types.DemanderLImportDUnDocumentDocument,
    "\n  query reponsesRapidesQuery($identifiantCabinet: String!) {\n    reponsesRapides(identifiantCabinet: $identifiantCabinet) {\n      id\n      titre\n      contenu\n    }\n  }\n": typeof types.ReponsesRapidesQueryDocument,
    "\n  query streamChatQuery {\n    streamChat {\n      token\n      userId\n    }\n  }\n": typeof types.StreamChatQueryDocument,
    "\n  query cabinetPrincipalQuery($identifiantProfil: String!) {\n    cabinetPrincipal(identifiantProfil: $identifiantProfil) {\n      equipeMedicale {\n        id\n        civilite\n        nom\n        prenom\n        role\n        photo\n      }\n    }\n  }\n": typeof types.CabinetPrincipalQueryDocument,
    "\n  mutation enregistrerDepistage(\n    $identifiantProfilPatient: String!\n    $identifiantDepistage: String!\n    $statutDepistage: StatutDepistage!\n    $dateDepistage: LocalDate\n  ) {\n    enregistrerDepistage(\n      identifiantProfilPatient: $identifiantProfilPatient\n      identifiantDepistage: $identifiantDepistage\n      statutDepistage: $statutDepistage\n      dateDepistage: $dateDepistage\n    ) {\n      id\n      frequenceEnAnnees\n      evaluationDuRisque\n      descriptionEvaluationDuRisque\n      questionDateDernierExamen\n      statutDernierDepistage\n      dateDernierDepistage\n      dateDEligibilite\n      dateProchainDepistage\n      statutProchainDepistage\n      patientId\n    }\n  }\n": typeof types.EnregistrerDepistageDocument,
    "\n  mutation enregistrerHabitudeDeVie(\n    $identifiantProfilPatient: String!\n    $identifiantHabitudeDeVie: String!\n    $valeur: String!\n  ) {\n    enregistrerHabitudeDeVie(\n      identifiantProfilPatient: $identifiantProfilPatient\n      identifiantHabitudeDeVie: $identifiantHabitudeDeVie\n      valeur: $valeur\n    )\n  }\n": typeof types.EnregistrerHabitudeDeVieDocument,
    "\n  mutation enregistrerSyntheseContexte(\n    $identifiantProfilPatient: String!\n    $contenu: String!\n  ) {\n    enregistrerSyntheseContexte(\n      identifiantProfilPatient: $identifiantProfilPatient\n      contenu: $contenu\n    ) {\n      synthese {\n        contenu\n      }\n    }\n  }\n": typeof types.EnregistrerSyntheseContexteDocument,
    "\n  query mettreEnFormeLaSyntheseContexte($contenu: String!) {\n    miseEnFormeSyntheseContexte(contenu: $contenu)\n  }\n": typeof types.MettreEnFormeLaSyntheseContexteDocument,
    "\n  query documentParIdentifiantQuery($identifiant: String!) {\n    demandeDeTelechargement(identifiantDuDocument: $identifiant) {\n      adresse\n    }\n  }\n": typeof types.DocumentParIdentifiantQueryDocument,
    "\n  query documentsQuery($identifiantDuProfilPatient: String!) {\n    documents(identifiantProfil: $identifiantDuProfilPatient) {\n      id\n      nom\n      dateDuDocument\n      source\n      identifiantPatient\n      categoriePrincipale {\n        nom\n      }\n    }\n  }\n": typeof types.DocumentsQueryDocument,
    "\n  query patientParIdentifiantQuery($identifiant: String!) {\n    patientParIdentifiant(identifiant: $identifiant) {\n      id\n      prenom\n      nom\n      dateDeNaissance\n      sexe\n      numeroDeTelephone\n      compte {\n        profilPrincipal {\n          id\n        }\n      }\n      contexte {\n        synthese {\n          contenu\n        }\n        habitudesDeVie {\n          id\n          picto\n          nom\n          valeur\n          choix\n        }\n      }\n      lienDoctolib\n      nouveauPatient\n      depistages {\n        id\n        evaluationDuRisque\n        descriptionEvaluationDuRisque\n        statutDernierDepistage\n        dateDernierDepistage\n        dateDEligibilite\n        dateProchainDepistage\n        statutProchainDepistage\n        nom\n        description\n        examen\n        titreQuestion\n        questionDepistage\n        sousTitreQuestion\n        questionDateDernierExamen\n        patientId\n      }\n      programme {\n        statut\n        medecinReferent {\n          id\n          medecin {\n            id\n            civilite\n            nom\n            photo\n          }\n        }\n      }\n    }\n  }\n": typeof types.PatientParIdentifiantQueryDocument,
    "\n  mutation inscrireAuProgrammePrimary(\n    $identifiantProfilPatient: String!\n    $identifiantMedecinReferent: String!\n  ) {\n    inscrireAuProgrammePrimary(\n      identifiantProfilPatient: $identifiantProfilPatient\n      identifiantMedecinReferent: $identifiantMedecinReferent\n    )\n  }\n": typeof types.InscrireAuProgrammePrimaryDocument,
    "\n  mutation refuserInscriptionAuProgrammePrimary(\n    $identifiantProfilPatient: String!\n  ) {\n    refuserInscriptionAuProgrammePrimary(\n      identifiantProfilPatient: $identifiantProfilPatient\n    )\n  }\n": typeof types.RefuserInscriptionAuProgrammePrimaryDocument,
    "\n  query patientsQuery($recherche: String!) {\n    patients(recherche: $recherche) {\n      id\n      prenom\n      nom\n      dateDeNaissance\n      sexe\n      numeroDeTelephone\n      compte {\n        email\n        identifiantUtilisateur\n        numeroDeTelephone\n      }\n      cabinetPrincipal {\n        id\n      }\n    }\n  }\n": typeof types.PatientsQueryDocument,
    "\n  query aideExamensConseilles($contenu: String!) {\n    aideALaPreanamnese(contenu: $contenu) {\n      examensConseilles {\n        contenu\n      }\n    }\n  }\n": typeof types.AideExamensConseillesDocument,
    "\n  query aideMettreEnForme($contenu: String!) {\n    aideALaPreanamnese(contenu: $contenu) {\n      miseEnForme {\n        contenu\n      }\n    }\n  }\n": typeof types.AideMettreEnFormeDocument,
    "\n  query aideSuggestionsQuery($contenu: String!) {\n    aideALaPreanamnese(contenu: $contenu) {\n      suggestions {\n        contenu\n      }\n    }\n  }\n": typeof types.AideSuggestionsQueryDocument,
    "\n  mutation commencerPreconsultation($identifiantDuRendezVous: String!) {\n    commencerPreconsultation(identifiantRendezVous: $identifiantDuRendezVous)\n  }\n": typeof types.CommencerPreconsultationDocument,
    "\n  mutation enregistrerDonneesBiometriques(\n    $identifiantDuRendezVous: String!\n    $donnees: DonneesBiometriquesInput!\n  ) {\n    enregistrerDonneesBiometriques(\n      identifiantRendezVous: $identifiantDuRendezVous\n      donneesBiometriques: $donnees\n    ) {\n      frequenceCardiaque {\n        valeurEnBPM\n      }\n    }\n  }\n": typeof types.EnregistrerDonneesBiometriquesDocument,
    "\n  mutation enregistrerSyntheseMutation(\n    $identifiantDuRendezVous: String!\n    $contenu: String!\n  ) {\n    enregistrerSynthese(\n      identifiantRendezVous: $identifiantDuRendezVous\n      contenu: $contenu\n    ) {\n      id\n    }\n  }\n": typeof types.EnregistrerSyntheseMutationDocument,
    "\n  mutation enregistrerVaccins(\n    $identifiantDuRendezVous: String!\n    $vaccins: [VaccinInput!]!\n  ) {\n    enregistrerVaccins(\n      identifiantRendezVous: $identifiantDuRendezVous\n      vaccins: $vaccins\n    ) {\n      id\n    }\n  }\n": typeof types.EnregistrerVaccinsDocument,
    "\n  mutation envoyerLaPreconsultationDansDoctolib(\n    $identifiantDuRendezVous: String!\n  ) {\n    envoyerLaPreconsultationDansDoctolib(\n      identifiantRendezVous: $identifiantDuRendezVous\n    )\n  }\n": typeof types.EnvoyerLaPreconsultationDansDoctolibDocument,
    "\n  mutation marquerPreconsultationANePasFaire(\n    $identifiantDuRendezVous: String!\n  ) {\n    marquerPreconsultationANePasFaire(\n      identifiantRendezVous: $identifiantDuRendezVous\n    )\n  }\n": typeof types.MarquerPreconsultationANePasFaireDocument,
    "\n  mutation validerPreconsultationMutation($identifiantDuRendezVous: String!) {\n    validerPreconsultation(identifiantRendezVous: $identifiantDuRendezVous) {\n      id\n      validee\n    }\n  }\n": typeof types.ValiderPreconsultationMutationDocument,
    "\n  query rechercherVaccinsQuery($recherche: String!) {\n    vaccins(recherche: $recherche) {\n      id\n      nom\n    }\n  }\n": typeof types.RechercherVaccinsQueryDocument,
    "\n  query rendezVousQuery($identifiantProfil: String!) {\n    rendezVous(identifiantProfil: $identifiantProfil) {\n      typeDeRendezVous\n      id\n      dateHeureDeDebut\n      dateHeureDeFin\n      motif\n      rendezVousDePrevention\n      patient {\n        id\n        prenom\n        nom\n        sexe\n        dateDeNaissance\n        numeroDeTelephone\n        compte {\n          identifiantUtilisateur\n          numeroDeTelephone\n          email\n        }\n        lienDoctolib\n        nouveauPatient\n        programme {\n          statut\n        }\n      }\n      medecin {\n        civilite\n        nom\n        nomLibelleLong\n        photo\n      }\n      consultation {\n        complete\n      }\n      preconsultation {\n        motif {\n          nom\n        }\n        preparation {\n          statut\n          viaLLM\n        }\n        validee\n      }\n      preconsultationANePasFaire\n      patientEnSalleDAttente\n      lieu\n      consultationTerminee\n      statut\n    }\n  }\n": typeof types.RendezVousQueryDocument,
    "\n  query rendezVousParCabinetQuery(\n    $dateDeDebut: OffsetDateTime!\n    $dateDeFin: OffsetDateTime!\n    $identifiantCabinet: String!\n  ) {\n    rendezVousParCabinet(\n      dateDeDebut: $dateDeDebut\n      dateDeFin: $dateDeFin\n      identifiantCabinet: $identifiantCabinet\n    ) {\n      id\n      dateHeureDeDebut\n      dateHeureDeFin\n      motif\n      typeDeRendezVous\n      rendezVousDePrevention\n      patient {\n        id\n        prenom\n        nom\n        compte {\n          identifiantUtilisateur\n          numeroDeTelephone\n          email\n        }\n        dateDeNaissance\n        sexe\n        nouveauPatient\n        programme {\n          statut\n        }\n      }\n      consultationTerminee\n      medecin {\n        civilite\n        nom\n        nomLibelleLong\n        photo\n      }\n      preconsultation {\n        motif {\n          nom\n        }\n        preparation {\n          statut\n          viaLLM\n        }\n        validee\n      }\n      preconsultationANePasFaire\n      patientEnSalleDAttente\n      lieu\n      statut\n    }\n  }\n": typeof types.RendezVousParCabinetQueryDocument,
    "\n  query RendezVousParIdentifiant($identifiant: String!) {\n    rendezVousParIdentifiant(identifiant: $identifiant) {\n      id\n      dateHeureDeDebut\n      motif\n      consultationTerminee\n      rendezVousDePrevention\n      patient {\n        id\n        prenom\n        nom\n        sexe\n        dateDeNaissance\n        compte {\n          identifiantUtilisateur\n          numeroDeTelephone\n          email\n        }\n        lienDoctolib\n        nouveauPatient\n      }\n      medecin {\n        civilite\n        nom\n        photo\n        nomLibelleLong\n      }\n      preconsultation {\n        statutDePreconsultation\n        preparation {\n          statut\n          viaLLM\n          questionnaire {\n            identifiant\n            libelleQuestion\n            libelleReponse\n          }\n        }\n        validee\n        motif {\n          nom\n          precisionMotif\n        }\n        synthese {\n          statut\n          contenu\n          dateDeMiseAJour\n          miseAJourPar\n        }\n        donneesBiometriques {\n          pressionArterielleSystolique {\n            valeurEnMmHg\n          }\n          pressionArterielleDiastolique {\n            valeurEnMmHg\n          }\n          temperature {\n            valeurEnDegresCelcius\n          }\n          frequenceCardiaque {\n            valeurEnBPM\n          }\n          taille {\n            valeurEnCm\n          }\n          poids {\n            valeurEnKg\n          }\n          saturationEnOxygene {\n            valeurEnPourcentage\n          }\n        }\n        vaccins {\n          id\n          idDoctolib\n          nom\n          numeroDeLot\n          dateDExpiration\n          dateDInjection\n        }\n      }\n    }\n  }\n": typeof types.RendezVousParIdentifiantDocument,
    "\n  query cabinetsQuery {\n    cabinets {\n      id\n      nom\n    }\n  }\n": typeof types.CabinetsQueryDocument,
};
const documents: Documents = {
    "\n  query membreEquipeMedicale {\n    membreEquipeMedicaleConnecte {\n      civilite\n      nom\n      prenom\n      photo\n    }\n  }\n": types.MembreEquipeMedicaleDocument,
    "\n  query aideALaRedaction(\n    $identifiantDeLaConversation: String!\n    $message: String!\n  ) {\n    aideALaRedactionDeMessage(\n      identifiantDeLaConversation: $identifiantDeLaConversation\n      message: $message\n    )\n  }\n": types.AideALaRedactionDocument,
    "\n  query categoriesDeDocuments {\n    categoriesDeDocument {\n      id\n      nom\n    }\n  }\n": types.CategoriesDeDocumentsDocument,
    "\n  mutation creerUneConversationAvecLePatientMutation(\n    $identifiantProfil: String!\n    $identifiantDuCabinet: String!\n  ) {\n    creerUneConversationAvecLePatient(\n      identifiantProfil: $identifiantProfil\n      identifiantDuCabinet: $identifiantDuCabinet\n    ) {\n      identifiantStreamChat\n    }\n  }\n": types.CreerUneConversationAvecLePatientMutationDocument,
    "\n  query demanderLImportDUnDocument(\n    $identifiantDuPatient: String!\n    $identifiantDuDocument: String!\n    $identifiantDeLaCategorie: String!\n    $nom: String!\n    $extension: String!\n  ) {\n    demandeDImportDeDocument(\n      identifiantDuPatient: $identifiantDuPatient\n      identifiantDuDocument: $identifiantDuDocument\n      identifiantDeLaCategorie: $identifiantDeLaCategorie\n      nom: $nom\n      extension: $extension\n    ) {\n      adresse\n    }\n  }\n": types.DemanderLImportDUnDocumentDocument,
    "\n  query reponsesRapidesQuery($identifiantCabinet: String!) {\n    reponsesRapides(identifiantCabinet: $identifiantCabinet) {\n      id\n      titre\n      contenu\n    }\n  }\n": types.ReponsesRapidesQueryDocument,
    "\n  query streamChatQuery {\n    streamChat {\n      token\n      userId\n    }\n  }\n": types.StreamChatQueryDocument,
    "\n  query cabinetPrincipalQuery($identifiantProfil: String!) {\n    cabinetPrincipal(identifiantProfil: $identifiantProfil) {\n      equipeMedicale {\n        id\n        civilite\n        nom\n        prenom\n        role\n        photo\n      }\n    }\n  }\n": types.CabinetPrincipalQueryDocument,
    "\n  mutation enregistrerDepistage(\n    $identifiantProfilPatient: String!\n    $identifiantDepistage: String!\n    $statutDepistage: StatutDepistage!\n    $dateDepistage: LocalDate\n  ) {\n    enregistrerDepistage(\n      identifiantProfilPatient: $identifiantProfilPatient\n      identifiantDepistage: $identifiantDepistage\n      statutDepistage: $statutDepistage\n      dateDepistage: $dateDepistage\n    ) {\n      id\n      frequenceEnAnnees\n      evaluationDuRisque\n      descriptionEvaluationDuRisque\n      questionDateDernierExamen\n      statutDernierDepistage\n      dateDernierDepistage\n      dateDEligibilite\n      dateProchainDepistage\n      statutProchainDepistage\n      patientId\n    }\n  }\n": types.EnregistrerDepistageDocument,
    "\n  mutation enregistrerHabitudeDeVie(\n    $identifiantProfilPatient: String!\n    $identifiantHabitudeDeVie: String!\n    $valeur: String!\n  ) {\n    enregistrerHabitudeDeVie(\n      identifiantProfilPatient: $identifiantProfilPatient\n      identifiantHabitudeDeVie: $identifiantHabitudeDeVie\n      valeur: $valeur\n    )\n  }\n": types.EnregistrerHabitudeDeVieDocument,
    "\n  mutation enregistrerSyntheseContexte(\n    $identifiantProfilPatient: String!\n    $contenu: String!\n  ) {\n    enregistrerSyntheseContexte(\n      identifiantProfilPatient: $identifiantProfilPatient\n      contenu: $contenu\n    ) {\n      synthese {\n        contenu\n      }\n    }\n  }\n": types.EnregistrerSyntheseContexteDocument,
    "\n  query mettreEnFormeLaSyntheseContexte($contenu: String!) {\n    miseEnFormeSyntheseContexte(contenu: $contenu)\n  }\n": types.MettreEnFormeLaSyntheseContexteDocument,
    "\n  query documentParIdentifiantQuery($identifiant: String!) {\n    demandeDeTelechargement(identifiantDuDocument: $identifiant) {\n      adresse\n    }\n  }\n": types.DocumentParIdentifiantQueryDocument,
    "\n  query documentsQuery($identifiantDuProfilPatient: String!) {\n    documents(identifiantProfil: $identifiantDuProfilPatient) {\n      id\n      nom\n      dateDuDocument\n      source\n      identifiantPatient\n      categoriePrincipale {\n        nom\n      }\n    }\n  }\n": types.DocumentsQueryDocument,
    "\n  query patientParIdentifiantQuery($identifiant: String!) {\n    patientParIdentifiant(identifiant: $identifiant) {\n      id\n      prenom\n      nom\n      dateDeNaissance\n      sexe\n      numeroDeTelephone\n      compte {\n        profilPrincipal {\n          id\n        }\n      }\n      contexte {\n        synthese {\n          contenu\n        }\n        habitudesDeVie {\n          id\n          picto\n          nom\n          valeur\n          choix\n        }\n      }\n      lienDoctolib\n      nouveauPatient\n      depistages {\n        id\n        evaluationDuRisque\n        descriptionEvaluationDuRisque\n        statutDernierDepistage\n        dateDernierDepistage\n        dateDEligibilite\n        dateProchainDepistage\n        statutProchainDepistage\n        nom\n        description\n        examen\n        titreQuestion\n        questionDepistage\n        sousTitreQuestion\n        questionDateDernierExamen\n        patientId\n      }\n      programme {\n        statut\n        medecinReferent {\n          id\n          medecin {\n            id\n            civilite\n            nom\n            photo\n          }\n        }\n      }\n    }\n  }\n": types.PatientParIdentifiantQueryDocument,
    "\n  mutation inscrireAuProgrammePrimary(\n    $identifiantProfilPatient: String!\n    $identifiantMedecinReferent: String!\n  ) {\n    inscrireAuProgrammePrimary(\n      identifiantProfilPatient: $identifiantProfilPatient\n      identifiantMedecinReferent: $identifiantMedecinReferent\n    )\n  }\n": types.InscrireAuProgrammePrimaryDocument,
    "\n  mutation refuserInscriptionAuProgrammePrimary(\n    $identifiantProfilPatient: String!\n  ) {\n    refuserInscriptionAuProgrammePrimary(\n      identifiantProfilPatient: $identifiantProfilPatient\n    )\n  }\n": types.RefuserInscriptionAuProgrammePrimaryDocument,
    "\n  query patientsQuery($recherche: String!) {\n    patients(recherche: $recherche) {\n      id\n      prenom\n      nom\n      dateDeNaissance\n      sexe\n      numeroDeTelephone\n      compte {\n        email\n        identifiantUtilisateur\n        numeroDeTelephone\n      }\n      cabinetPrincipal {\n        id\n      }\n    }\n  }\n": types.PatientsQueryDocument,
    "\n  query aideExamensConseilles($contenu: String!) {\n    aideALaPreanamnese(contenu: $contenu) {\n      examensConseilles {\n        contenu\n      }\n    }\n  }\n": types.AideExamensConseillesDocument,
    "\n  query aideMettreEnForme($contenu: String!) {\n    aideALaPreanamnese(contenu: $contenu) {\n      miseEnForme {\n        contenu\n      }\n    }\n  }\n": types.AideMettreEnFormeDocument,
    "\n  query aideSuggestionsQuery($contenu: String!) {\n    aideALaPreanamnese(contenu: $contenu) {\n      suggestions {\n        contenu\n      }\n    }\n  }\n": types.AideSuggestionsQueryDocument,
    "\n  mutation commencerPreconsultation($identifiantDuRendezVous: String!) {\n    commencerPreconsultation(identifiantRendezVous: $identifiantDuRendezVous)\n  }\n": types.CommencerPreconsultationDocument,
    "\n  mutation enregistrerDonneesBiometriques(\n    $identifiantDuRendezVous: String!\n    $donnees: DonneesBiometriquesInput!\n  ) {\n    enregistrerDonneesBiometriques(\n      identifiantRendezVous: $identifiantDuRendezVous\n      donneesBiometriques: $donnees\n    ) {\n      frequenceCardiaque {\n        valeurEnBPM\n      }\n    }\n  }\n": types.EnregistrerDonneesBiometriquesDocument,
    "\n  mutation enregistrerSyntheseMutation(\n    $identifiantDuRendezVous: String!\n    $contenu: String!\n  ) {\n    enregistrerSynthese(\n      identifiantRendezVous: $identifiantDuRendezVous\n      contenu: $contenu\n    ) {\n      id\n    }\n  }\n": types.EnregistrerSyntheseMutationDocument,
    "\n  mutation enregistrerVaccins(\n    $identifiantDuRendezVous: String!\n    $vaccins: [VaccinInput!]!\n  ) {\n    enregistrerVaccins(\n      identifiantRendezVous: $identifiantDuRendezVous\n      vaccins: $vaccins\n    ) {\n      id\n    }\n  }\n": types.EnregistrerVaccinsDocument,
    "\n  mutation envoyerLaPreconsultationDansDoctolib(\n    $identifiantDuRendezVous: String!\n  ) {\n    envoyerLaPreconsultationDansDoctolib(\n      identifiantRendezVous: $identifiantDuRendezVous\n    )\n  }\n": types.EnvoyerLaPreconsultationDansDoctolibDocument,
    "\n  mutation marquerPreconsultationANePasFaire(\n    $identifiantDuRendezVous: String!\n  ) {\n    marquerPreconsultationANePasFaire(\n      identifiantRendezVous: $identifiantDuRendezVous\n    )\n  }\n": types.MarquerPreconsultationANePasFaireDocument,
    "\n  mutation validerPreconsultationMutation($identifiantDuRendezVous: String!) {\n    validerPreconsultation(identifiantRendezVous: $identifiantDuRendezVous) {\n      id\n      validee\n    }\n  }\n": types.ValiderPreconsultationMutationDocument,
    "\n  query rechercherVaccinsQuery($recherche: String!) {\n    vaccins(recherche: $recherche) {\n      id\n      nom\n    }\n  }\n": types.RechercherVaccinsQueryDocument,
    "\n  query rendezVousQuery($identifiantProfil: String!) {\n    rendezVous(identifiantProfil: $identifiantProfil) {\n      typeDeRendezVous\n      id\n      dateHeureDeDebut\n      dateHeureDeFin\n      motif\n      rendezVousDePrevention\n      patient {\n        id\n        prenom\n        nom\n        sexe\n        dateDeNaissance\n        numeroDeTelephone\n        compte {\n          identifiantUtilisateur\n          numeroDeTelephone\n          email\n        }\n        lienDoctolib\n        nouveauPatient\n        programme {\n          statut\n        }\n      }\n      medecin {\n        civilite\n        nom\n        nomLibelleLong\n        photo\n      }\n      consultation {\n        complete\n      }\n      preconsultation {\n        motif {\n          nom\n        }\n        preparation {\n          statut\n          viaLLM\n        }\n        validee\n      }\n      preconsultationANePasFaire\n      patientEnSalleDAttente\n      lieu\n      consultationTerminee\n      statut\n    }\n  }\n": types.RendezVousQueryDocument,
    "\n  query rendezVousParCabinetQuery(\n    $dateDeDebut: OffsetDateTime!\n    $dateDeFin: OffsetDateTime!\n    $identifiantCabinet: String!\n  ) {\n    rendezVousParCabinet(\n      dateDeDebut: $dateDeDebut\n      dateDeFin: $dateDeFin\n      identifiantCabinet: $identifiantCabinet\n    ) {\n      id\n      dateHeureDeDebut\n      dateHeureDeFin\n      motif\n      typeDeRendezVous\n      rendezVousDePrevention\n      patient {\n        id\n        prenom\n        nom\n        compte {\n          identifiantUtilisateur\n          numeroDeTelephone\n          email\n        }\n        dateDeNaissance\n        sexe\n        nouveauPatient\n        programme {\n          statut\n        }\n      }\n      consultationTerminee\n      medecin {\n        civilite\n        nom\n        nomLibelleLong\n        photo\n      }\n      preconsultation {\n        motif {\n          nom\n        }\n        preparation {\n          statut\n          viaLLM\n        }\n        validee\n      }\n      preconsultationANePasFaire\n      patientEnSalleDAttente\n      lieu\n      statut\n    }\n  }\n": types.RendezVousParCabinetQueryDocument,
    "\n  query RendezVousParIdentifiant($identifiant: String!) {\n    rendezVousParIdentifiant(identifiant: $identifiant) {\n      id\n      dateHeureDeDebut\n      motif\n      consultationTerminee\n      rendezVousDePrevention\n      patient {\n        id\n        prenom\n        nom\n        sexe\n        dateDeNaissance\n        compte {\n          identifiantUtilisateur\n          numeroDeTelephone\n          email\n        }\n        lienDoctolib\n        nouveauPatient\n      }\n      medecin {\n        civilite\n        nom\n        photo\n        nomLibelleLong\n      }\n      preconsultation {\n        statutDePreconsultation\n        preparation {\n          statut\n          viaLLM\n          questionnaire {\n            identifiant\n            libelleQuestion\n            libelleReponse\n          }\n        }\n        validee\n        motif {\n          nom\n          precisionMotif\n        }\n        synthese {\n          statut\n          contenu\n          dateDeMiseAJour\n          miseAJourPar\n        }\n        donneesBiometriques {\n          pressionArterielleSystolique {\n            valeurEnMmHg\n          }\n          pressionArterielleDiastolique {\n            valeurEnMmHg\n          }\n          temperature {\n            valeurEnDegresCelcius\n          }\n          frequenceCardiaque {\n            valeurEnBPM\n          }\n          taille {\n            valeurEnCm\n          }\n          poids {\n            valeurEnKg\n          }\n          saturationEnOxygene {\n            valeurEnPourcentage\n          }\n        }\n        vaccins {\n          id\n          idDoctolib\n          nom\n          numeroDeLot\n          dateDExpiration\n          dateDInjection\n        }\n      }\n    }\n  }\n": types.RendezVousParIdentifiantDocument,
    "\n  query cabinetsQuery {\n    cabinets {\n      id\n      nom\n    }\n  }\n": types.CabinetsQueryDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query membreEquipeMedicale {\n    membreEquipeMedicaleConnecte {\n      civilite\n      nom\n      prenom\n      photo\n    }\n  }\n"): typeof import('./graphql').MembreEquipeMedicaleDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query aideALaRedaction(\n    $identifiantDeLaConversation: String!\n    $message: String!\n  ) {\n    aideALaRedactionDeMessage(\n      identifiantDeLaConversation: $identifiantDeLaConversation\n      message: $message\n    )\n  }\n"): typeof import('./graphql').AideALaRedactionDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query categoriesDeDocuments {\n    categoriesDeDocument {\n      id\n      nom\n    }\n  }\n"): typeof import('./graphql').CategoriesDeDocumentsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation creerUneConversationAvecLePatientMutation(\n    $identifiantProfil: String!\n    $identifiantDuCabinet: String!\n  ) {\n    creerUneConversationAvecLePatient(\n      identifiantProfil: $identifiantProfil\n      identifiantDuCabinet: $identifiantDuCabinet\n    ) {\n      identifiantStreamChat\n    }\n  }\n"): typeof import('./graphql').CreerUneConversationAvecLePatientMutationDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query demanderLImportDUnDocument(\n    $identifiantDuPatient: String!\n    $identifiantDuDocument: String!\n    $identifiantDeLaCategorie: String!\n    $nom: String!\n    $extension: String!\n  ) {\n    demandeDImportDeDocument(\n      identifiantDuPatient: $identifiantDuPatient\n      identifiantDuDocument: $identifiantDuDocument\n      identifiantDeLaCategorie: $identifiantDeLaCategorie\n      nom: $nom\n      extension: $extension\n    ) {\n      adresse\n    }\n  }\n"): typeof import('./graphql').DemanderLImportDUnDocumentDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query reponsesRapidesQuery($identifiantCabinet: String!) {\n    reponsesRapides(identifiantCabinet: $identifiantCabinet) {\n      id\n      titre\n      contenu\n    }\n  }\n"): typeof import('./graphql').ReponsesRapidesQueryDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query streamChatQuery {\n    streamChat {\n      token\n      userId\n    }\n  }\n"): typeof import('./graphql').StreamChatQueryDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query cabinetPrincipalQuery($identifiantProfil: String!) {\n    cabinetPrincipal(identifiantProfil: $identifiantProfil) {\n      equipeMedicale {\n        id\n        civilite\n        nom\n        prenom\n        role\n        photo\n      }\n    }\n  }\n"): typeof import('./graphql').CabinetPrincipalQueryDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation enregistrerDepistage(\n    $identifiantProfilPatient: String!\n    $identifiantDepistage: String!\n    $statutDepistage: StatutDepistage!\n    $dateDepistage: LocalDate\n  ) {\n    enregistrerDepistage(\n      identifiantProfilPatient: $identifiantProfilPatient\n      identifiantDepistage: $identifiantDepistage\n      statutDepistage: $statutDepistage\n      dateDepistage: $dateDepistage\n    ) {\n      id\n      frequenceEnAnnees\n      evaluationDuRisque\n      descriptionEvaluationDuRisque\n      questionDateDernierExamen\n      statutDernierDepistage\n      dateDernierDepistage\n      dateDEligibilite\n      dateProchainDepistage\n      statutProchainDepistage\n      patientId\n    }\n  }\n"): typeof import('./graphql').EnregistrerDepistageDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation enregistrerHabitudeDeVie(\n    $identifiantProfilPatient: String!\n    $identifiantHabitudeDeVie: String!\n    $valeur: String!\n  ) {\n    enregistrerHabitudeDeVie(\n      identifiantProfilPatient: $identifiantProfilPatient\n      identifiantHabitudeDeVie: $identifiantHabitudeDeVie\n      valeur: $valeur\n    )\n  }\n"): typeof import('./graphql').EnregistrerHabitudeDeVieDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation enregistrerSyntheseContexte(\n    $identifiantProfilPatient: String!\n    $contenu: String!\n  ) {\n    enregistrerSyntheseContexte(\n      identifiantProfilPatient: $identifiantProfilPatient\n      contenu: $contenu\n    ) {\n      synthese {\n        contenu\n      }\n    }\n  }\n"): typeof import('./graphql').EnregistrerSyntheseContexteDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query mettreEnFormeLaSyntheseContexte($contenu: String!) {\n    miseEnFormeSyntheseContexte(contenu: $contenu)\n  }\n"): typeof import('./graphql').MettreEnFormeLaSyntheseContexteDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query documentParIdentifiantQuery($identifiant: String!) {\n    demandeDeTelechargement(identifiantDuDocument: $identifiant) {\n      adresse\n    }\n  }\n"): typeof import('./graphql').DocumentParIdentifiantQueryDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query documentsQuery($identifiantDuProfilPatient: String!) {\n    documents(identifiantProfil: $identifiantDuProfilPatient) {\n      id\n      nom\n      dateDuDocument\n      source\n      identifiantPatient\n      categoriePrincipale {\n        nom\n      }\n    }\n  }\n"): typeof import('./graphql').DocumentsQueryDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query patientParIdentifiantQuery($identifiant: String!) {\n    patientParIdentifiant(identifiant: $identifiant) {\n      id\n      prenom\n      nom\n      dateDeNaissance\n      sexe\n      numeroDeTelephone\n      compte {\n        profilPrincipal {\n          id\n        }\n      }\n      contexte {\n        synthese {\n          contenu\n        }\n        habitudesDeVie {\n          id\n          picto\n          nom\n          valeur\n          choix\n        }\n      }\n      lienDoctolib\n      nouveauPatient\n      depistages {\n        id\n        evaluationDuRisque\n        descriptionEvaluationDuRisque\n        statutDernierDepistage\n        dateDernierDepistage\n        dateDEligibilite\n        dateProchainDepistage\n        statutProchainDepistage\n        nom\n        description\n        examen\n        titreQuestion\n        questionDepistage\n        sousTitreQuestion\n        questionDateDernierExamen\n        patientId\n      }\n      programme {\n        statut\n        medecinReferent {\n          id\n          medecin {\n            id\n            civilite\n            nom\n            photo\n          }\n        }\n      }\n    }\n  }\n"): typeof import('./graphql').PatientParIdentifiantQueryDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation inscrireAuProgrammePrimary(\n    $identifiantProfilPatient: String!\n    $identifiantMedecinReferent: String!\n  ) {\n    inscrireAuProgrammePrimary(\n      identifiantProfilPatient: $identifiantProfilPatient\n      identifiantMedecinReferent: $identifiantMedecinReferent\n    )\n  }\n"): typeof import('./graphql').InscrireAuProgrammePrimaryDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation refuserInscriptionAuProgrammePrimary(\n    $identifiantProfilPatient: String!\n  ) {\n    refuserInscriptionAuProgrammePrimary(\n      identifiantProfilPatient: $identifiantProfilPatient\n    )\n  }\n"): typeof import('./graphql').RefuserInscriptionAuProgrammePrimaryDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query patientsQuery($recherche: String!) {\n    patients(recherche: $recherche) {\n      id\n      prenom\n      nom\n      dateDeNaissance\n      sexe\n      numeroDeTelephone\n      compte {\n        email\n        identifiantUtilisateur\n        numeroDeTelephone\n      }\n      cabinetPrincipal {\n        id\n      }\n    }\n  }\n"): typeof import('./graphql').PatientsQueryDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query aideExamensConseilles($contenu: String!) {\n    aideALaPreanamnese(contenu: $contenu) {\n      examensConseilles {\n        contenu\n      }\n    }\n  }\n"): typeof import('./graphql').AideExamensConseillesDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query aideMettreEnForme($contenu: String!) {\n    aideALaPreanamnese(contenu: $contenu) {\n      miseEnForme {\n        contenu\n      }\n    }\n  }\n"): typeof import('./graphql').AideMettreEnFormeDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query aideSuggestionsQuery($contenu: String!) {\n    aideALaPreanamnese(contenu: $contenu) {\n      suggestions {\n        contenu\n      }\n    }\n  }\n"): typeof import('./graphql').AideSuggestionsQueryDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation commencerPreconsultation($identifiantDuRendezVous: String!) {\n    commencerPreconsultation(identifiantRendezVous: $identifiantDuRendezVous)\n  }\n"): typeof import('./graphql').CommencerPreconsultationDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation enregistrerDonneesBiometriques(\n    $identifiantDuRendezVous: String!\n    $donnees: DonneesBiometriquesInput!\n  ) {\n    enregistrerDonneesBiometriques(\n      identifiantRendezVous: $identifiantDuRendezVous\n      donneesBiometriques: $donnees\n    ) {\n      frequenceCardiaque {\n        valeurEnBPM\n      }\n    }\n  }\n"): typeof import('./graphql').EnregistrerDonneesBiometriquesDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation enregistrerSyntheseMutation(\n    $identifiantDuRendezVous: String!\n    $contenu: String!\n  ) {\n    enregistrerSynthese(\n      identifiantRendezVous: $identifiantDuRendezVous\n      contenu: $contenu\n    ) {\n      id\n    }\n  }\n"): typeof import('./graphql').EnregistrerSyntheseMutationDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation enregistrerVaccins(\n    $identifiantDuRendezVous: String!\n    $vaccins: [VaccinInput!]!\n  ) {\n    enregistrerVaccins(\n      identifiantRendezVous: $identifiantDuRendezVous\n      vaccins: $vaccins\n    ) {\n      id\n    }\n  }\n"): typeof import('./graphql').EnregistrerVaccinsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation envoyerLaPreconsultationDansDoctolib(\n    $identifiantDuRendezVous: String!\n  ) {\n    envoyerLaPreconsultationDansDoctolib(\n      identifiantRendezVous: $identifiantDuRendezVous\n    )\n  }\n"): typeof import('./graphql').EnvoyerLaPreconsultationDansDoctolibDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation marquerPreconsultationANePasFaire(\n    $identifiantDuRendezVous: String!\n  ) {\n    marquerPreconsultationANePasFaire(\n      identifiantRendezVous: $identifiantDuRendezVous\n    )\n  }\n"): typeof import('./graphql').MarquerPreconsultationANePasFaireDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation validerPreconsultationMutation($identifiantDuRendezVous: String!) {\n    validerPreconsultation(identifiantRendezVous: $identifiantDuRendezVous) {\n      id\n      validee\n    }\n  }\n"): typeof import('./graphql').ValiderPreconsultationMutationDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query rechercherVaccinsQuery($recherche: String!) {\n    vaccins(recherche: $recherche) {\n      id\n      nom\n    }\n  }\n"): typeof import('./graphql').RechercherVaccinsQueryDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query rendezVousQuery($identifiantProfil: String!) {\n    rendezVous(identifiantProfil: $identifiantProfil) {\n      typeDeRendezVous\n      id\n      dateHeureDeDebut\n      dateHeureDeFin\n      motif\n      rendezVousDePrevention\n      patient {\n        id\n        prenom\n        nom\n        sexe\n        dateDeNaissance\n        numeroDeTelephone\n        compte {\n          identifiantUtilisateur\n          numeroDeTelephone\n          email\n        }\n        lienDoctolib\n        nouveauPatient\n        programme {\n          statut\n        }\n      }\n      medecin {\n        civilite\n        nom\n        nomLibelleLong\n        photo\n      }\n      consultation {\n        complete\n      }\n      preconsultation {\n        motif {\n          nom\n        }\n        preparation {\n          statut\n          viaLLM\n        }\n        validee\n      }\n      preconsultationANePasFaire\n      patientEnSalleDAttente\n      lieu\n      consultationTerminee\n      statut\n    }\n  }\n"): typeof import('./graphql').RendezVousQueryDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query rendezVousParCabinetQuery(\n    $dateDeDebut: OffsetDateTime!\n    $dateDeFin: OffsetDateTime!\n    $identifiantCabinet: String!\n  ) {\n    rendezVousParCabinet(\n      dateDeDebut: $dateDeDebut\n      dateDeFin: $dateDeFin\n      identifiantCabinet: $identifiantCabinet\n    ) {\n      id\n      dateHeureDeDebut\n      dateHeureDeFin\n      motif\n      typeDeRendezVous\n      rendezVousDePrevention\n      patient {\n        id\n        prenom\n        nom\n        compte {\n          identifiantUtilisateur\n          numeroDeTelephone\n          email\n        }\n        dateDeNaissance\n        sexe\n        nouveauPatient\n        programme {\n          statut\n        }\n      }\n      consultationTerminee\n      medecin {\n        civilite\n        nom\n        nomLibelleLong\n        photo\n      }\n      preconsultation {\n        motif {\n          nom\n        }\n        preparation {\n          statut\n          viaLLM\n        }\n        validee\n      }\n      preconsultationANePasFaire\n      patientEnSalleDAttente\n      lieu\n      statut\n    }\n  }\n"): typeof import('./graphql').RendezVousParCabinetQueryDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query RendezVousParIdentifiant($identifiant: String!) {\n    rendezVousParIdentifiant(identifiant: $identifiant) {\n      id\n      dateHeureDeDebut\n      motif\n      consultationTerminee\n      rendezVousDePrevention\n      patient {\n        id\n        prenom\n        nom\n        sexe\n        dateDeNaissance\n        compte {\n          identifiantUtilisateur\n          numeroDeTelephone\n          email\n        }\n        lienDoctolib\n        nouveauPatient\n      }\n      medecin {\n        civilite\n        nom\n        photo\n        nomLibelleLong\n      }\n      preconsultation {\n        statutDePreconsultation\n        preparation {\n          statut\n          viaLLM\n          questionnaire {\n            identifiant\n            libelleQuestion\n            libelleReponse\n          }\n        }\n        validee\n        motif {\n          nom\n          precisionMotif\n        }\n        synthese {\n          statut\n          contenu\n          dateDeMiseAJour\n          miseAJourPar\n        }\n        donneesBiometriques {\n          pressionArterielleSystolique {\n            valeurEnMmHg\n          }\n          pressionArterielleDiastolique {\n            valeurEnMmHg\n          }\n          temperature {\n            valeurEnDegresCelcius\n          }\n          frequenceCardiaque {\n            valeurEnBPM\n          }\n          taille {\n            valeurEnCm\n          }\n          poids {\n            valeurEnKg\n          }\n          saturationEnOxygene {\n            valeurEnPourcentage\n          }\n        }\n        vaccins {\n          id\n          idDoctolib\n          nom\n          numeroDeLot\n          dateDExpiration\n          dateDInjection\n        }\n      }\n    }\n  }\n"): typeof import('./graphql').RendezVousParIdentifiantDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query cabinetsQuery {\n    cabinets {\n      id\n      nom\n    }\n  }\n"): typeof import('./graphql').CabinetsQueryDocument;


export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}
