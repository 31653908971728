import { useTranslation } from "react-i18next"
import { usePreferences } from "@infra/preferences/usePreferences"

export const ChannelListEmptyStateIndicator = () => {
  const { t } = useTranslation()
  const { filtreInboxMessagerie } = usePreferences()

  return (
    <div className="flex h-full flex-col items-start justify-center text-p-tiny text-grey">
      {filtreInboxMessagerie === "ma-boite" && t("messagerie.maBoiteVide")}
      {filtreInboxMessagerie === "tous" && t("messagerie.tousVide")}
      {filtreInboxMessagerie === "archive" && t("messagerie.archivesVide")}
    </div>
  )
}
