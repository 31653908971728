import { graphql } from "@data/gql"

export const documentsQuery = graphql(`
  query documentsQuery($identifiantDuProfilPatient: String!) {
    documents(identifiantProfil: $identifiantDuProfilPatient) {
      id
      nom
      dateDuDocument
      source
      identifiantPatient
      categoriePrincipale {
        nom
      }
    }
  }
`)
