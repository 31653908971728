import { graphql } from "@data/gql"
import {
  PatientsQueryQuery,
  PatientsQueryQueryVariables,
  TypedDocumentString,
} from "@data/gql/graphql"

export const recherchePatientsQuery: TypedDocumentString<
  PatientsQueryQuery,
  PatientsQueryQueryVariables
> = graphql(`
  query patientsQuery($recherche: String!) {
    patients(recherche: $recherche) {
      id
      prenom
      nom
      dateDeNaissance
      sexe
      numeroDeTelephone
      compte {
        email
        identifiantUtilisateur
        numeroDeTelephone
      }
      cabinetPrincipal {
        id
      }
    }
  }
`)
