import { useTranslation } from "react-i18next"
import { PageTitleLayoutWithMargin } from "@ds"
import { Calendar, classNames, PlainButton, Tabs } from "@primary/design-system"
import { ListeDeRendezVousParCabinet } from "@features/rendezVous/listeDeRendezVous/ListeDeRendezVousParCabinet"
import { useRendezVousParCabinet } from "@data/rendezVous/hooks/useRendezVousParCabinet"
import { appRoute } from "@infra/navigation/router"
import { format, isBefore, isToday, parse } from "date-fns"
import { useState } from "react"
import { useNavigate } from "@tanstack/react-router"
import { FiltreMedecin } from "@features/medecin/FiltreMedecins"
import { usePreferences } from "@infra/preferences/usePreferences"
import { LieuDuRendezVous, StatutRendezVous } from "@data/gql/graphql"
import { rootRoute } from "@infra/navigation/rootRoute"

export const PreConsultations = () => {
  const { t } = useTranslation()
  const { date, tabIndex } = appRoute.useSearch()
  const navigate = useNavigate({ from: rootRoute.fullPath })
  const { filtreMedecin, identifiantDuCabinet, setPreferences } =
    usePreferences()

  const searchDate = date ? parse(date, "yyyy-MM-dd", new Date()) : new Date()

  const { data: rendezVous } = useRendezVousParCabinet({
    date: searchDate,
  })

  const rendezVousFiltresParMedecin = rendezVous.filter(
    (rdv) =>
      (!filtreMedecin?.[date + "-" + identifiantDuCabinet]?.length ||
        filtreMedecin?.[date + "-" + identifiantDuCabinet]?.some(
          (medecin) => medecin.id === rdv.medecin.nomLibelleLong,
        )) &&
      rdv.statut !== StatutRendezVous.Annule,
  )

  const rendezVousAPreparer = rendezVousFiltresParMedecin.filter(
    (rdv) =>
      !rdv.preconsultation.validee &&
      !rdv.consultationTerminee &&
      rdv.lieu === LieuDuRendezVous.EnCabinet &&
      !rdv.preconsultationANePasFaire &&
      rdv.statut !== StatutRendezVous.Annule,
  )

  const children = [
    {
      title: t("aPreparer"),
      showCount: true,
      count: rendezVousAPreparer.length,
      content: (
        <ListeDeRendezVousParCabinet
          rendezVous={rendezVousAPreparer}
          peutMarquerANePasFaire={true}
        />
      ),
    },
    {
      title: t("toutes"),
      showCount: true,
      count: rendezVousFiltresParMedecin.length,
      content: (
        <ListeDeRendezVousParCabinet
          rendezVous={rendezVousFiltresParMedecin}
          peutMarquerANePasFaire={false}
        />
      ),
    },
  ]

  const medecins = rendezVous.reduce(
    (acc, rdv) => {
      acc[rdv.medecin.nomLibelleLong] = {
        id: rdv.medecin.nomLibelleLong,
        name: rdv.medecin.civilite
          ? rdv.medecin.civilite + " " + rdv.medecin.nom
          : rdv.medecin.nomLibelleLong,
      }
      return acc
    },
    {} as { [key: string]: { id: string; name: string } },
  )

  const actions = [
    <FiltreMedecin
      medecins={Object.entries(medecins).map(([, value]) => value)}
      selection={filtreMedecin?.[date + "-" + identifiantDuCabinet] || []}
      setSelection={(value) =>
        setPreferences({
          filtreMedecin: { [date + "-" + identifiantDuCabinet]: value },
        })
      }
    />,
    <CalendarDatePickerButton
      date={parse(date, "yyyy-MM-dd", new Date())}
      setDate={(date: Date) => {
        if (isBefore(date, new Date(1900, 1, 1))) {
          return
        }
        navigate({
          search: () => ({
            date: format(date, "yyyy-MM-dd"),
          }),
        })
      }}
    />,
  ]

  return (
    <PageTitleLayoutWithMargin title={t("preconsultations")}>
      <div className="mx-20">
        <Tabs
          selectedIndex={tabIndex}
          onChange={(index) => {
            navigate({
              search: (prev: Record<string, string>) => ({
                ...prev,
                tabIndex: index,
              }),
            })
          }}
          headerActions={actions}
          children={children}
        />
      </div>
    </PageTitleLayoutWithMargin>
  )
}

interface CalendarDatePickerButtonProps {
  date: Date
  setDate: (date: Date) => void
}

const CalendarDatePickerButton = ({
  date,
  setDate,
}: CalendarDatePickerButtonProps) => {
  const { t } = useTranslation()
  const [showInput, setShowInput] = useState(!isToday(date))
  const [localDate, setLocalDate] = useState(format(date, "yyyy-MM-dd"))

  return (
    <PlainButton
      type="filled"
      className="relative text-h3"
      onClick={() => setShowInput(true)}
    >
      <div className={classNames(showInput && "invisible")}>
        {t("aujourdhui")}
      </div>
      <div className={classNames(showInput && "invisible")}>
        <Calendar />
      </div>
      {showInput && (
        <input
          type="date"
          value={localDate}
          className="absolute bottom-0 left-0 right-0 top-0 bg-transparent px-2"
          onBlur={(e) => {
            try {
              setDate(parse(e.target.value, "yyyy-MM-dd", new Date()))
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
            } catch (_) {
              // ignore
            }
          }}
          onChange={(e) => {
            setLocalDate(e.target.value)
          }}
        />
      )}
    </PlainButton>
  )
}
