import { StatutDepistage, StatutProchainDepistage } from "@data/gql/graphql"
import { ChevronDown, ChevronUp } from "@primary/design-system"
import { useTranslation } from "react-i18next"
import { format, getYear } from "date-fns"
import { useMemo, useState } from "react"
import { TagStatut } from "./TagStatut"
import { LigneDateDepistage } from "./LigneDateDepistage"
import { DescriptionExamen } from "@features/patient/components/CardDepistage/DescriptionExamen"
import { di } from "@di"
import {
  DepistagePatientFragment,
  EnregistrerDepistageDocument,
  useMutation,
} from "@primary/data"
import { Shimmer } from "@ds"

export type CardDepistageProps = {
  depistage: DepistagePatientFragment
  onChange?: () => void
}

interface EnregistrementDepistage {
  identifiantProfilPatient: string
  identifiantDepistage: string
  statutDepistage: StatutDepistage
  dateDepistage: Date | null
}

export const CardDepistage = ({ depistage, onChange }: CardDepistageProps) => {
  const [ouverte, setOuverte] = useState(
    depistage.statutProchainDepistage === StatutProchainDepistage.AFaire ||
      depistage.statutProchainDepistage === StatutProchainDepistage.EnRetard,
  )

  const [mutate, { loading: enregistrementEnCours }] = useMutation(
    EnregistrerDepistageDocument,
    {
      onCompleted: () => {
        onChange?.()
        di.analytics.trackEvent("Dépistage mis à jour", {
          idPatient: depistage.patientId,
          idDepistage: depistage.id,
          idAssistante: di.StreamChatService.client.value.userID,
        })
      },
      optimisticResponse: (vars) => ({
        enregistrerDepistage: {
          ...depistage,
          statutDernierDepistage: vars.statutDepistage,
          dateDepistage: vars.dateDepistage,
        },
      }),
    },
  )

  const enregistrerDepistagePatient = (params: EnregistrementDepistage) => {
    mutate({
      variables: {
        identifiantProfilPatient: params.identifiantProfilPatient,
        identifiantDepistage: params.identifiantDepistage,
        statutDepistage: params.statutDepistage,
        dateDepistage: params.dateDepistage,
      },
    })
  }

  return (
    <div
      className="flex flex-col gap-2 rounded-xl p-4 shadow-very-light-shadow"
      data-testid={"depistage-" + depistage.id}
    >
      <div className="flex justify-between">
        <div
          className="flex cursor-pointer gap-1"
          onClick={() => setOuverte(!ouverte)}
        >
          {ouverte && <ChevronUp />}
          {!ouverte && <ChevronDown />}
          <div className="text-h4">{depistage.nom}</div>
        </div>
        <Shimmer loading={enregistrementEnCours}>
          <TagStatut statut={depistage.statutProchainDepistage} />
        </Shimmer>
      </div>
      {ouverte && (
        <div className="flex flex-col">
          <EvaluationDuRisque
            depistage={depistage}
            enregistrerDepistage={enregistrerDepistagePatient}
          />

          <QuestionSupplementaireRisque
            depistage={depistage}
            enregistrerDepistage={enregistrerDepistagePatient}
          />

          <DernierDepistage
            depistage={depistage}
            enregistrerDepistage={enregistrerDepistagePatient}
          />

          <ProchainDepistage depistage={depistage} />

          <DescriptionExamen depistage={depistage} />
        </div>
      )}
    </div>
  )
}

interface QuestionSupplementaireRisqueProps {
  depistage: CardDepistageProps["depistage"]
  enregistrerDepistage: (params: EnregistrementDepistage) => void
}

const QuestionSupplementaireRisque = ({
  depistage,
  enregistrerDepistage,
}: QuestionSupplementaireRisqueProps) => {
  const { t } = useTranslation()

  const depistageFait = useMemo(() => {
    switch (depistage.statutDernierDepistage) {
      case StatutDepistage.Fait:
        return t("oui")
      case StatutDepistage.NonFait:
        return t("non")
      case null:
        return ""
      case StatutDepistage.ARisque:
        return ""
    }
  }, [depistage.statutDernierDepistage, t])

  const onSelect = (valeur: string) => {
    switch (valeur) {
      case t("oui"):
        enregistrerDepistage({
          identifiantProfilPatient: depistage.patientId,
          identifiantDepistage: depistage.id,
          statutDepistage: StatutDepistage.Fait,
          dateDepistage: null,
        })
        break
      case t("non"):
        enregistrerDepistage({
          identifiantProfilPatient: depistage.patientId,
          identifiantDepistage: depistage.id,
          statutDepistage: StatutDepistage.NonFait,
          dateDepistage: null,
        })
        break
    }
  }

  const visible =
    depistage.statutDernierDepistage &&
    [
      StatutDepistage.Fait,
      StatutDepistage.NonFait,
      StatutDepistage.ARisque,
    ].includes(depistage.statutDernierDepistage)

  return (
    !depistage.questionDateDernierExamen && (
      <LigneDateDepistage
        libelle={depistage.questionDepistage}
        choix={[t("oui"), t("non")]}
        valeur={depistageFait}
        onSelect={(valeur) => onSelect(valeur)}
        testId="questionSupplementaireRisque"
        invisible={!visible}
      />
    )
  )
}

interface EvaluationDuRisqueProps {
  depistage: CardDepistageProps["depistage"]
  enregistrerDepistage: (params: EnregistrementDepistage) => void
}

const EvaluationDuRisque = ({
  depistage,
  enregistrerDepistage,
}: EvaluationDuRisqueProps) => {
  const { t } = useTranslation()

  const aRisque = useMemo(() => {
    switch (depistage.statutDernierDepistage) {
      case StatutDepistage.PasARisque:
        return t("non")
      case null:
        return ""
      default:
        return t("oui")
    }
  }, [depistage.statutDernierDepistage, t])

  const onSelect = (valeur: string) => {
    switch (valeur) {
      case t("oui"):
        enregistrerDepistage({
          identifiantProfilPatient: depistage.patientId,
          identifiantDepistage: depistage.id,
          statutDepistage: StatutDepistage.ARisque,
          dateDepistage: null,
        })
        break
      case t("non"):
        enregistrerDepistage({
          identifiantProfilPatient: depistage.patientId,
          identifiantDepistage: depistage.id,
          statutDepistage: StatutDepistage.PasARisque,
          dateDepistage: null,
        })
        break
    }
  }

  return (
    depistage.evaluationDuRisque && (
      <LigneDateDepistage
        libelle={depistage.evaluationDuRisque}
        choix={[t("oui"), t("non")]}
        valeur={aRisque}
        onSelect={(valeur) => onSelect(valeur)}
        testId="evaluationDuRisque"
      />
    )
  )
}

interface DernierDepistageProps {
  depistage: CardDepistageProps["depistage"]
  enregistrerDepistage: (params: EnregistrementDepistage) => void
}

const DernierDepistage = ({
  depistage,
  enregistrerDepistage,
}: DernierDepistageProps) => {
  const { t } = useTranslation()

  const dateDernierDepistage = useMemo(() => {
    switch (depistage.statutDernierDepistage) {
      case StatutDepistage.Fait:
        return depistage.dateDernierDepistage
          ? getYear(depistage.dateDernierDepistage)
          : t("dateInconnue")
      case StatutDepistage.NeSaitPas:
      case StatutDepistage.NonFait:
        return t("jamaisFait")
      case null:
        return ""
    }
  }, [depistage.dateDernierDepistage, depistage.statutDernierDepistage, t])

  const changerDateDernierDepistage = (valeur: string) => {
    switch (valeur) {
      case t("jamaisFait"):
        enregistrerDepistage({
          identifiantProfilPatient: depistage.patientId,
          identifiantDepistage: depistage.id,
          statutDepistage: StatutDepistage.NonFait,
          dateDepistage: null,
        })
        break
      case t("dateInconnue"):
        enregistrerDepistage({
          identifiantProfilPatient: depistage.patientId,
          identifiantDepistage: depistage.id,
          statutDepistage: StatutDepistage.Fait,
          dateDepistage: null,
        })
        break
      default:
        const dateDernierDepistage = format(
          new Date(parseInt(valeur), 0),
          "yyyy-MM-dd",
        )
        enregistrerDepistage({
          identifiantProfilPatient: depistage.patientId,
          identifiantDepistage: depistage.id,
          statutDepistage: StatutDepistage.Fait,
          dateDepistage: dateDernierDepistage as unknown as Date,
        })
        break
    }
  }

  const anneeCourante = new Date().getFullYear()
  const dixDernieresAnnees = Array.from(
    { length: 10 },
    (_, i) => anneeCourante - i,
  )
  const choixAnnees = [
    ...dixDernieresAnnees,
    t("jamaisFait"),
    t("dateInconnue"),
  ]

  const visible =
    !depistage.statutDernierDepistage ||
    depistage.statutDernierDepistage === StatutDepistage.ARisque ||
    depistage.statutDernierDepistage === StatutDepistage.Fait ||
    depistage.statutDernierDepistage === StatutDepistage.NonFait

  if (depistage.questionDateDernierExamen) {
    return (
      <LigneDateDepistage
        libelle={t("dernierDepistage")}
        choix={choixAnnees}
        valeur={dateDernierDepistage}
        onSelect={(valeur) => changerDateDernierDepistage(valeur)}
        testId="dateDernierDepistage"
        invisible={!visible}
      />
    )
  }
}

const ProchainDepistage = ({ depistage }: CardDepistageProps) => {
  const { t } = useTranslation()

  if (!depistage.evaluationDuRisque) {
    return (
      <LigneDateDepistage
        libelle={t("prochainDepistage")}
        choix={[getYear(depistage.dateProchainDepistage)]}
        valeur={getYear(depistage.dateProchainDepistage)}
        onSelect={() => {
          // FIXME: empty callback
        }}
        testId="prochainDepistage"
        disabled
        invisible={!depistage.dateDernierDepistage}
      />
    )
  }
}
