import {
  SuggestionAPI,
  aideSuggestions,
} from "@data/preconsultation/mutations/aideSuggestions"
import { useGQLMutation } from "@data/useGraphQL"
import { useTranslation } from "react-i18next"
import { useThrottleMutateOnChange } from "../hooks/useThrottleMutateOnChange"
import { useOrderedStringsState } from "../hooks/useOrderedStringsState"
import { motion, AnimatePresence, useIsPresent } from "framer-motion"
import { classNames, Ai } from "@primary/design-system"
import {
  ExamenConseilleAPI,
  aideExamensConseilles,
} from "@data/preconsultation/mutations/aideExamensConseilles"
import { di } from "@di"

interface SidebarSyntheseProps {
  markdown: string
  // For testing purposes ONLY
  throttleDueTime?: number
}

export const SidebarSynthese = ({
  markdown,
  throttleDueTime,
}: SidebarSyntheseProps) => {
  const { t } = useTranslation()
  const [suggestionsData, setSuggestionsData] = useOrderedStringsState(
    (s: SuggestionAPI) => s.contenu,
  )
  const {
    mutate: recupererSuggestions,
    isPending: recuperationDesSuggestionsEnCours,
    // TODO(judithp): [Apollo] Use Apollo instead.
  } = useGQLMutation(aideSuggestions, {
    onSuccess: (data) => {
      di.analytics.trackEvent("Copilot Preconsultation", {
        type: "suggestions",
        inputLength: markdown.length,
        outputLength: JSON.stringify(data?.aideALaPreanamnese).length,
      })
      setSuggestionsData(data?.aideALaPreanamnese.suggestions)
    },
  })
  useThrottleMutateOnChange(
    (contenu) => recupererSuggestions({ contenu }),
    recuperationDesSuggestionsEnCours,
    markdown,
    {
      throttleDueTime: throttleDueTime ?? 5000,
      filter: (value) => value.length >= 5,
    },
  )

  const [examensConseillesData, setExamensConseillesData] =
    useOrderedStringsState((s: ExamenConseilleAPI) => s.contenu)
  const {
    mutate: recupererExamensConseilles,
    isPending: recuperationDesExamensConseillesEnCours,
    // TODO(judithp): [Apollo] Use Apollo instead.
  } = useGQLMutation(aideExamensConseilles, {
    onSuccess: (data) => {
      di.analytics.trackEvent("Copilot Preconsultation", {
        type: "examensConseilles",
        inputLength: markdown.length,
        outputLength:
          (data?.aideALaPreanamnese &&
            JSON.stringify(data?.aideALaPreanamnese).length) ||
          0,
      })
      setExamensConseillesData(data?.aideALaPreanamnese.examensConseilles)
    },
  })
  useThrottleMutateOnChange(
    (contenu) => recupererExamensConseilles({ contenu }),
    recuperationDesExamensConseillesEnCours,
    markdown,
    { throttleDueTime: throttleDueTime ?? 1200, immediate: true },
  )

  const suggestions = suggestionsData
  const examensConseilles = examensConseillesData

  return (
    <div className="sticky bottom-0 top-0 flex flex-col items-start gap-4 px-11 pt-11">
      <AnimatePresence>
        {(suggestions == null || suggestions.length === 0) &&
          (examensConseilles == null || examensConseilles.length === 0) && (
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, scale: 0 }}
              className="mx-auto min-w-20 max-w-[60%] text-center text-p-medium text-dark-grey"
            >
              {t("continuezLInterrogatoirePourLesSuggestions")}
            </motion.p>
          )}
        {(suggestions ?? [])
          .filter((_, index) => index < 5)
          .map((suggestion) => (
            <Item
              key={`suggestion-${suggestion.contenu}`}
              contenu={suggestion.contenu}
            />
          ))}
        {examensConseilles && examensConseilles.length > 0 && (
          <>
            {suggestions && suggestions.length > 0 && <div className="h-8" />}
            <h4 className="text-h4">
              {t("examensConseilles", { count: examensConseilles.length })}
            </h4>
            {examensConseilles.map((examen) => (
              <Item
                key={`examen-${examen.contenu}`}
                contenu={examen.contenu}
                className="!bg-indigo-100"
              />
            ))}
          </>
        )}
      </AnimatePresence>
    </div>
  )
}

const Item = ({
  contenu,
  className,
}: {
  contenu: string
  className?: string
}) => {
  const isPresent = useIsPresent()
  return (
    <motion.p
      layout
      className={classNames(
        "rounded bg-sky-100 px-2 py-1 text-h4 text-indigo-900",
        !isPresent && "absolute hidden",
        className,
      )}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
    >
      <Ai className="relative bottom-[1px] mr-1 inline text-amber-400" />
      {contenu}
    </motion.p>
  )
}
