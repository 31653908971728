import { usePreferences } from "@infra/preferences/usePreferences"
import { addDays, isToday, startOfDay } from "date-fns"
import { RendezVousParCabinetDocument, useQuery } from "@primary/data"

export type UseRendezVousParCabinetProps = {
  date: Date
}

export const useRendezVousParCabinet = ({
  date,
}: UseRendezVousParCabinetProps) => {
  const { identifiantDuCabinet } = usePreferences()

  if (!identifiantDuCabinet) {
    throw new Error("identifiantDuCabinet is missing")
  }

  const { data } = useQuery(RendezVousParCabinetDocument, {
    pollInterval: isToday(date) ? 5000 : undefined,
    variables: {
      identifiantCabinet: identifiantDuCabinet,
      dateDeDebut: startOfDay(date),
      dateDeFin: startOfDay(addDays(date, 1)),
    },
  })

  if (data?.rendezVousParCabinet === undefined) {
    return {
      data: [],
      count: 0,
    }
  }

  return {
    data: data.rendezVousParCabinet,
    count: data.rendezVousParCabinet.length,
  }
}
