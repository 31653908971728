import { StepType } from "@reactour/tour"
import { useTranslation } from "react-i18next"

type TourDepistagesPatientProps = {
  preconsultation?: boolean
}

// FIXME: This component is not exported from the file
// eslint-disable-next-line react-refresh/only-export-components
const TourDepistagesPatient = ({
  preconsultation,
}: TourDepistagesPatientProps) => {
  const { t } = useTranslation()

  return (
    <div style={{ position: "relative", background: "white" }}>
      <div
        style={
          preconsultation
            ? {
                position: "absolute",
                bottom: "148px",
                left: "16%",
                transform: "translateX(-50%)",
                width: "0",
                height: "0",
                borderLeft: "10px solid transparent",
                borderRight: "10px solid transparent",
                borderBottom: "10px solid white",
              }
            : {
                position: "absolute",
                bottom: "-32px",
                left: "50%",
                transform: "translateX(-50%)",
                width: "0",
                height: "0",
                borderLeft: "10px solid transparent",
                borderRight: "10px solid transparent",
                borderTop: "10px solid white",
              }
        }
      />
      <div className="flex flex-col gap-6">
        <div className="text-h3">{t("cestNouveau")}</div>
        <div className="text-p-small text-dark-plum">
          {t("vousPouvezMaintenantVerifierLesDepistages")}&#160;
          {preconsultation
            ? "Ils seront envoyés vers Doctolib en même temps que la pré-anamnèse."
            : ""}
        </div>
      </div>
    </div>
  )
}

export const stepTourDepistagesPatient: StepType = {
  selector: "#depistages",
  content: () => <TourDepistagesPatient />,
  position: "top",
  styles: {
    popover: (base) => ({ ...base, borderRadius: "12px" }),
  },
}

export const stepTourDepistagesPreconsultation: StepType = {
  selector: "#voir-le-profil",
  content: () => <TourDepistagesPatient preconsultation={true} />,
  position: "bottom",
  styles: {
    popover: (base) => ({ ...base, borderRadius: "12px" }),
  },
}
