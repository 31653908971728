import { EventComponentProps } from "stream-chat-react"
import { useEffect, useRef } from "react"
import { format } from "date-fns"
import { useTranslation } from "react-i18next"

export const CustomSystemMessage = (props: EventComponentProps) => {
  const { message } = props
  const { t } = useTranslation()
  const messageDiv = useRef<HTMLDivElement>(null)

  const { event } = message

  useEffect(() => {
    if (messageDiv.current?.parentElement?.parentElement) {
      const messagesList = messageDiv.current.parentElement.parentElement
      messagesList.scrollBy({
        top: messagesList.scrollHeight,
        behavior: "smooth",
      })
    }
  }, [message])

  return (
    event &&
    message.text && (
      <div ref={messageDiv} className="my-2 flex items-center justify-center">
        <div className="rounded-full bg-extra-light-grey px-2 py-1 text-h7 text-grey">
          {t("messagerie.aTelleHeure", {
            quoi: message.text,
            heure: format(message.created_at as Date, "HH:mm"),
          }).toUpperCase()}
        </div>
      </div>
    )
  )
}
