import {
  AppState,
  Auth0ContextInterface,
  IdToken,
  LogoutOptions,
  RedirectLoginOptions,
  User,
} from "@auth0/auth0-react"
import { BehaviorSubject } from "rxjs"
import { IAuthService } from "./IAuthService"

export class AuthService implements IAuthService {
  private notInitialized = () => {
    throw new Error("AuthService not initialized")
  }

  #isAuthenticated: BehaviorSubject<boolean> | undefined
  #id: BehaviorSubject<string | undefined> | undefined
  #getIdTokenClaims: () => Promise<IdToken | undefined> = this.notInitialized

  get isAuthenticated() {
    if (this.#isAuthenticated === undefined) {
      return this.notInitialized()
    }
    return this.#isAuthenticated
  }

  get id() {
    if (this.#id === undefined) {
      return this.notInitialized()
    }
    return this.#id
  }

  loginWithRedirect: (
    options?: RedirectLoginOptions<AppState>,
  ) => Promise<void> = this.notInitialized
  logout: (options?: LogoutOptions) => Promise<void> = this.notInitialized
  getAccessTokenSilently: Auth0ContextInterface<User>["getAccessTokenSilently"] =
    this.notInitialized
  getIdTokenClaims = async (): Promise<IdToken | undefined> => {
    let idToken = await this.#getIdTokenClaims()

    if (!idToken) {
      throw new Error("No idToken")
    }

    if (!idToken.exp) {
      throw new Error("idToken has no expiration date")
    }

    if (idToken.exp - 30 < Date.now() / 1000) {
      try {
        await this.getAccessTokenSilently({
          cacheMode: "off",
        })
        idToken = await this.#getIdTokenClaims()
      } catch (error) {
        await this.logout()
        return Promise.reject({ message: "Could not refresh token", error })
      }
    }

    return idToken
  }
  getToken = async () => {
    const idToken = await this.getIdTokenClaims()
    return idToken?.__raw ?? null
  }

  updateFromAuth0Interface(auth0Interface: Auth0ContextInterface<User>) {
    this.loginWithRedirect = auth0Interface.loginWithRedirect
    this.#getIdTokenClaims = auth0Interface.getIdTokenClaims
    this.logout = auth0Interface.logout
    this.getAccessTokenSilently = auth0Interface.getAccessTokenSilently

    if (this.#id === undefined) {
      this.#id = new BehaviorSubject(auth0Interface.user?.sub)
    }
    if (this.#isAuthenticated === undefined) {
      this.#isAuthenticated = new BehaviorSubject(
        auth0Interface.isAuthenticated,
      )
    }

    if (this.#id.value !== auth0Interface.user?.sub) {
      this.#id.next(auth0Interface.user?.sub)
    }

    if (this.#isAuthenticated.value !== auth0Interface.isAuthenticated) {
      this.#isAuthenticated.next(auth0Interface.isAuthenticated)
    }
  }
}
