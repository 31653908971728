import {
  CabinetFragmentDoc,
  CabinetsDocument,
  getFragmentData,
  useQuery,
} from "@primary/data"
import { classNames } from "@primary/design-system"

interface CabinetTagProps {
  className?: string
  cabinetId: unknown
}

export const CabinetTag = ({ className, cabinetId }: CabinetTagProps) => {
  const { data } = useQuery(CabinetsDocument)

  const maybeCabinet = data?.cabinets.find(
    (cabinet) => getFragmentData(CabinetFragmentDoc, cabinet).id === cabinetId,
  )

  if (!maybeCabinet) {
    return null
  }

  const cabinetName = getFragmentData(CabinetFragmentDoc, maybeCabinet).nom

  return (
    <div
      className={classNames(
        "rounded bg-secondary-blue px-1 py-0.5 text-h7",
        className,
      )}
    >
      {cabinetName.toLocaleUpperCase()}
    </div>
  )
}
