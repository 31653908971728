import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react"
import { Cross } from "@primary/design-system"
import { useTranslation } from "react-i18next"
import { SubmitButton, SubmitButtonProps } from "../button/SubmitButton"
import { ReactNode, useState } from "react"
import { classNames } from "../classNames"

export interface ModalProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  title: string
  dataTestId?: string
  children?: string | ReactNode
  cancelButton?: string | boolean
  confirmButtonTitle?: string
  disabled?: boolean
  loading?: boolean
  dangerButton?: string | false
  onDangerConfirm?: () => void
  dangerConfirmationTitle?: string
  confirmButtonType?: SubmitButtonProps["type"]
  autosize?: boolean
  size: "small" | "medium"
}

export const Modal = ({
  open,
  onClose,
  onConfirm,
  title,
  children,
  dataTestId,
  cancelButton,
  confirmButtonTitle,
  disabled,
  loading,
  dangerButton,
  onDangerConfirm,
  dangerConfirmationTitle,
  confirmButtonType,
  autosize,
  size = "small",
}: ModalProps) => {
  const { t } = useTranslation()
  const [dangerConfirmationModalOpen, setDangerConfirmationModalOpen] =
    useState(false)

  if (dangerButton && !onDangerConfirm) {
    throw new Error(
      "You must provide onDangerConfirm when dangerButton is enabled",
    )
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        className="relative z-50"
        data-testid={dataTestId}
      >
        <div
          className={classNames(
            "fixed inset-0 grid w-screen grid-rows-5 bg-secondary-black p-6",
            size === "small" ? "grid-cols-6" : "grid-cols-4",
          )}
        >
          <DialogPanel
            className={classNames(
              "col-span-2 row-start-2 flex flex-col",
              autosize ? "items-center" : "items-stretch",
              size === "small" ? "col-start-3" : "col-start-2",
            )}
          >
            <div className="flex flex-col rounded-xl bg-white">
              <DialogTitle className="flex w-full justify-center gap-1 rounded-t-xl bg-white p-6 text-h4">
                <Cross className="invisible text-p-medium" />
                <div className="w-full flex-1 text-center">{title}</div>
                <button data-testid="modal-close" onClick={onClose}>
                  <Cross className="text-p-medium text-black" />
                </button>
              </DialogTitle>
              <div className="text-centertext-p-tiny flex items-center justify-center bg-white px-6">
                {children}
              </div>
              <div
                className={classNames(
                  "flex justify-center gap-4 rounded-b-xl bg-white p-6",
                  dangerButton && "flex-col items-stretch",
                )}
              >
                {(cancelButton === undefined || cancelButton) && (
                  <SubmitButton
                    title={
                      typeof cancelButton === "string"
                        ? cancelButton
                        : t("annuler")
                    }
                    onClick={onClose}
                    type="outlined"
                  />
                )}
                <SubmitButton
                  title={
                    confirmButtonTitle ? confirmButtonTitle : t("confirmer")
                  }
                  onClick={onConfirm}
                  type={confirmButtonType ?? "tonal"}
                  disabled={disabled}
                  loading={loading}
                  className={classNames(dangerButton && "w-auto")}
                />
                {dangerButton && (
                  <SubmitButton
                    title={dangerButton}
                    onClick={() => setDangerConfirmationModalOpen(true)}
                    type="danger-text"
                    className={classNames(dangerButton && "w-auto")}
                  />
                )}
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
      {dangerButton && dangerConfirmationTitle && onDangerConfirm && (
        <Modal
          open={dangerConfirmationModalOpen}
          onClose={() => setDangerConfirmationModalOpen(false)}
          onConfirm={() => {
            setDangerConfirmationModalOpen(false)
            onDangerConfirm()
          }}
          title={dangerConfirmationTitle}
          confirmButtonTitle={t("oui")}
          confirmButtonType="danger"
          cancelButton={t("non")}
          autosize
          size="small"
        />
      )}
    </>
  )
}
