import { useFlags } from "launchdarkly-react-client-sdk"
import { useChatContext } from "stream-chat-react"
import { useMessagerieUnreadCount } from "./hooks/useMessagerieUnreadCount"
import { usePreferences } from "@infra/preferences/usePreferences"
import { classNames, Flag } from "@primary/design-system"
import { useEffect } from "react"

export const UnreadCount = () => {
  const { sideBarOuverte } = usePreferences()
  const { messagerieStream } = useFlags()
  const { unreadCount: baseUnreadCount, unreadCountAideMedecin } =
    useMessagerieUnreadCount()
  const { client } = useChatContext()

  const unreadCount =
    client.user?.metier === "medecin" ? unreadCountAideMedecin : baseUnreadCount
  const userIsMedecin = client.user?.metier === "medecin"

  useEffect(() => {
    const originalTitle = document.title.replace(/^\(\d+\)\s*/, "")
    document.title =
      unreadCount > 0 ? `(${unreadCount}) ${originalTitle}` : originalTitle
  }, [unreadCount])

  if (!messagerieStream || unreadCount === 0) {
    return <></>
  }

  return (
    <div
      className={classNames(
        !sideBarOuverte && "absolute",
        userIsMedecin ? "right-[-10px]" : "right-0",
        "bottom-2",
      )}
    >
      <div className="flex h-4 min-w-4 items-center justify-center gap-[2px] rounded-full bg-yellow p-1 text-p-tiny text-white">
        <div>{unreadCount}</div>
        {userIsMedecin && <Flag className="text-p-tiny" />}
      </div>
    </div>
  )
}
