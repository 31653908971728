import { Chat } from "stream-chat-react"
import { ReactNode } from "react"
import { useObservable } from "@utils/useObservable"
import { di } from "@di"

export const StreamChatProvider = ({ children }: { children: ReactNode }) => {
  const client = useObservable(
    di.StreamChatService.client,
    di.StreamChatService.client.value,
  )
  return (
    <Chat client={client} key={client.key}>
      {children}
    </Chat>
  )
}
