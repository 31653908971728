import { RendezVousParIdentifiantQuery, VaccinInput } from "@data/gql/graphql"
import { ReactNode, useEffect, useMemo, useState } from "react"
import { useGQLMutation, useGraphQLQuery } from "@data/useGraphQL"
import { enregistrerDonneesBiometriques } from "@data/preconsultation/mutations/enregistrerDonneesBiometriques"
import { rendezVousParIdentifiantQuery } from "@data/rendezVous/queries/rendezVousParIdentifiantQuery"
import { donneesBiometriquesStatiques } from "./donneesBiometriquesStatiques"
import { useChampsPresentes } from "./useChampsPresentes"
import { enregistrerVaccins } from "@data/preconsultation/mutations/enregistrerVaccins"
import { IndexedVaccin } from "../components/FormVaccin"
import { format } from "date-fns"

type DonneesBiometriques =
  RendezVousParIdentifiantQuery["rendezVousParIdentifiant"]["preconsultation"]["donneesBiometriques"]

type UseDonneesBiometriquesProps = {
  rendezVousId: string
  onChange?: () => void
}

export type DonneeBiometriqueProps = {
  code: string
  nom: string
  valeur?: string | number
  unite: string
  icone: ReactNode
  suppression?: boolean
}

export const useDonneesSaisiesPatient = ({
  rendezVousId,
  onChange,
}: UseDonneesBiometriquesProps) => {
  // TODO(judithp): [Apollo] Use Apollo instead.
  const { data } = useGraphQLQuery({
    document: rendezVousParIdentifiantQuery,
    variables: { identifiant: rendezVousId },
    staleTime: 10000,
  })
  // TODO(judithp): [Apollo] Use Apollo instead.
  const { mutate: mutateDonnees } = useGQLMutation(
    enregistrerDonneesBiometriques,
    {
      invalidateQueryKeys: [
        [rendezVousParIdentifiantQuery, { identifiant: rendezVousId }],
      ],
      onSuccess: onChange,
    },
  )

  // TODO(judithp): [Apollo] Use Apollo instead.
  const { mutate: mutateVaccins } = useGQLMutation(enregistrerVaccins, {
    invalidateQueryKeys: [
      [rendezVousParIdentifiantQuery, { identifiant: rendezVousId }],
    ],
    onSuccess: onChange,
  })

  const donneesBiometriques =
    data?.data?.rendezVousParIdentifiant?.preconsultation?.donneesBiometriques

  const [vaccins, setVaccins] = useState<IndexedVaccin[]>(
    data?.data?.rendezVousParIdentifiant?.preconsultation?.vaccins?.map(
      (vaccin, index) => ({
        index: index,
        ...vaccin,
      }),
    ) ?? [],
  )

  const { champsPresentes, ajouterUnChampPresente, supprimerUnChampPresente } =
    useChampsPresentes({
      nouveauPatient:
        data?.data?.rendezVousParIdentifiant?.patient?.nouveauPatient,
    })

  const [donneesAAfficher, setDonneesAAfficher] = useState(
    donneesAvecUnitesEtIcones(donneesBiometriques, champsPresentes),
  )

  useEffect(() => {
    setDonneesAAfficher(
      donneesAvecUnitesEtIcones(donneesBiometriques, champsPresentes),
    )
  }, [champsPresentes, donneesBiometriques])

  const donneesAAjouter = useMemo(
    () =>
      donneesBiometriquesStatiques.filter(
        (donnee) => !donneesAAfficher.find((d) => d.code === donnee.code),
      ),
    [donneesAAfficher],
  )

  const mettreAJourUneDonneeBiometrique = (
    code: string,
    valeur: string | undefined,
  ) => {
    const nouvellesDonnees = { ...donneesBiometriques! }
    switch (code) {
      case "PAS":
        nouvellesDonnees.pressionArterielleSystolique = {
          valeurEnMmHg: valeur ?? null,
        }
        break
      case "PAD":
        nouvellesDonnees.pressionArterielleDiastolique = {
          valeurEnMmHg: valeur ?? null,
        }
        break
      case "Temp":
        nouvellesDonnees.temperature = { valeurEnDegresCelcius: valeur ?? null }
        break
      case "FC":
        nouvellesDonnees.frequenceCardiaque = { valeurEnBPM: valeur ?? null }
        break
      case "Poids":
        nouvellesDonnees.poids = { valeurEnKg: valeur ?? null }
        break
      case "Taille":
        nouvellesDonnees.taille = { valeurEnCm: valeur ?? null }
        break
      case "SpO2":
        nouvellesDonnees.saturationEnOxygene = {
          valeurEnPourcentage: valeur ?? null,
        }
        break
      default:
        break
    }

    mutateDonnees({
      identifiantDuRendezVous: rendezVousId,
      donnees: nouvellesDonnees,
    })
  }

  const supprimerUneDonneeBiometrique = (code: string) => {
    const nouvellesDonnees = { ...donneesBiometriques! }
    switch (code) {
      case "PAS":
        nouvellesDonnees.pressionArterielleSystolique = { valeurEnMmHg: null }
        break
      case "PAD":
        nouvellesDonnees.pressionArterielleDiastolique = { valeurEnMmHg: null }
        break
      case "Temp":
        nouvellesDonnees.temperature = { valeurEnDegresCelcius: null }
        break
      case "FC":
        nouvellesDonnees.frequenceCardiaque = { valeurEnBPM: null }
        break
      case "Poids":
        nouvellesDonnees.poids = { valeurEnKg: null }
        break
      case "Taille":
        nouvellesDonnees.taille = { valeurEnCm: null }
        break
      case "SpO2":
        nouvellesDonnees.saturationEnOxygene = { valeurEnPourcentage: null }
        break

      default:
        break
    }
    supprimerUnChampPresente(code)
    mutateDonnees({
      identifiantDuRendezVous: rendezVousId,
      donnees: nouvellesDonnees,
    })
  }

  const ajouterUnVaccin = () => {
    setVaccins([...vaccins, { index: vaccins.length }])
  }

  const mettreAJourUnVaccin = (vaccin: IndexedVaccin) => {
    const vaccinsMisAJour = vaccins.map((v) => {
      if (v.index === vaccin.index) {
        return vaccin
      }
      return v
    })
    setVaccins(vaccinsMisAJour)
    mutateVaccins({
      vaccins: vaccinsMisAJour?.map((v) => ({
        id: v.id,
        nom: v.nom,
        idDoctolib: v.idDoctolib,
        dateDInjection: format(new Date(), "yyyy-MM-dd"),
        dateDExpiration: v.dateDExpiration,
        numeroDeLot: v.numeroDeLot,
      })) as VaccinInput[],
      identifiantDuRendezVous: rendezVousId,
    })
  }

  const supprimerUnVaccin = (index: number) => {
    const vaccinsMisAJour = vaccins.filter((v) => v.index !== index)
    setVaccins(vaccinsMisAJour)
    mutateVaccins({
      vaccins: vaccinsMisAJour?.map((v) => ({
        id: v.id,
        nom: v.nom,
        idDoctolib: v.idDoctolib,
        dateDInjection: v.dateDInjection,
        dateDExpiration: v.dateDExpiration,
        numeroDeLot: v.numeroDeLot,
      })) as VaccinInput[],
      identifiantDuRendezVous: rendezVousId,
    })
  }

  return {
    donneesAAfficher,
    mettreAJourUneDonneeBiometrique,
    ajouterUneDonneeBiometrique: ajouterUnChampPresente,
    supprimerUneDonneeBiometrique,
    donneesDuMenu: donneesAAjouter,
    vaccins,
    ajouterUnVaccin,
    mettreAJourUnVaccin,
    supprimerUnVaccin,
  }
}

const donneesAvecUnitesEtIcones = (
  donneesBiometriques: DonneesBiometriques | undefined,
  champsPresentes: string[],
) => {
  return donneesBiometriquesStatiques
    .map((element) => {
      switch (element.code) {
        case "PAS":
          return {
            ...element,
            valeur:
              donneesBiometriques?.pressionArterielleSystolique.valeurEnMmHg?.toString(),
          }

        case "PAD":
          return {
            ...element,
            valeur:
              donneesBiometriques?.pressionArterielleDiastolique.valeurEnMmHg?.toString(),
          }

        case "Temp":
          return {
            ...element,
            valeur:
              donneesBiometriques?.temperature.valeurEnDegresCelcius?.toString(),
          }

        case "FC":
          return {
            ...element,
            valeur:
              donneesBiometriques?.frequenceCardiaque.valeurEnBPM?.toString(),
          }

        case "Poids":
          return {
            ...element,
            valeur: donneesBiometriques?.poids.valeurEnKg?.toString(),
          }

        case "Taille":
          return {
            ...element,
            valeur: donneesBiometriques?.taille.valeurEnCm?.toString(),
          }

        case "SpO2":
          return {
            ...element,
            valeur:
              donneesBiometriques?.saturationEnOxygene.valeurEnPourcentage?.toString(),
          }

        default:
          return element
      }
    })

    .filter(
      (element) =>
        champsPresentes.includes(element.code) ||
        (element.valeur !== null && element.valeur !== undefined),
    )
}
