import { Select } from "@headlessui/react"
import { useState } from "react"
import { useTranslation } from "react-i18next"

type HabitudeDeVieSelectorProps = {
  selected: string
  choix: string[]
  onSelect: (valeur: string) => void
}

export const HabitudeDeVieSelector = ({
  selected,
  choix,
  onSelect,
}: HabitudeDeVieSelectorProps) => {
  const { t } = useTranslation()
  const emptyHabitude = selected === ""
  const [selectedValue, setSelectedValue] = useState(
    !emptyHabitude ? selected : t("choisir"),
  )

  return (
    <div className="flex w-36 items-center rounded bg-extra-light-grey px-2 py-1 text-h6 text-dark-plum hover:cursor-pointer">
      <Select
        name="status"
        aria-label="habitude-de-vie"
        className="w-full bg-extra-light-grey outline-none hover:cursor-pointer"
        value={selectedValue}
        onChange={(event) => {
          onSelect(event.target.value)
          setSelectedValue(event.target.value)
        }}
      >
        {choix.map((valeur) => (
          <option key={valeur}>{valeur}</option>
        ))}
        {emptyHabitude && <option key={t("choisir")}>{t("choisir")}</option>}
      </Select>
    </div>
  )
}
