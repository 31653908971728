import {
  MembreEquipeMedicale,
  PatientParIdentifiantQueryQuery,
  StatutInscriptionProgrammePrimaryCare,
} from "@data/gql/graphql"
import { ChevronRight, Logo } from "@primary/design-system"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { Modal } from "@ds"
import { useGraphQLQuery } from "@data/useGraphQL"
import { cabinetPrincipalQuery } from "@data/patient/cabinetPrincipalQuery"
import { ListeDesMedecinsReferents } from "@features/rendezVous/detailPreconsultation/components/ListeDesMedecinsReferents"
import { useGQLMutation } from "@data/useGraphQL"
import { refuserInscriptionAuProgrammePrimary } from "@data/patient/programmePrimary/refuserInscriptionAuProgrammePrimary"
import { patientParIdentifiantQuery } from "@data/patient/patientParIdentifiantQuery"
import { toast } from "@infra/toast/toast"
import { inscrireAuProgrammePrimary } from "@data/patient/programmePrimary/inscrireAuProgrammePrimary"

interface BoutonInscrireAuProgrammePrimaryProps {
  patient: PatientParIdentifiantQueryQuery["patientParIdentifiant"]
}

export const BoutonInscrireAuProgrammePrimary = ({
  patient,
}: BoutonInscrireAuProgrammePrimaryProps) => {
  const { t } = useTranslation()
  const [modaleOuverte, setModaleOuverte] = useState(false)

  // TODO(judithp): [Apollo] Use Apollo instead.
  const { data } = useGraphQLQuery({
    document: cabinetPrincipalQuery,
    variables: { identifiantProfil: patient.id },
  })

  const libelleDuBoutonDInscription = (): string => {
    switch (patient.programme.statut) {
      case StatutInscriptionProgrammePrimaryCare.Inscrit:
        return t("programmePrimary.inscritAuProgramme")
      case StatutInscriptionProgrammePrimaryCare.NonInscrit:
      case StatutInscriptionProgrammePrimaryCare.Refuse:
        return t("programmePrimary.inscrireAuProgramme")
      case StatutInscriptionProgrammePrimaryCare.Interesse:
      case StatutInscriptionProgrammePrimaryCare.Preinscrit:
        return t("programmePrimary.inscriptionEnCours")
    }
  }

  const medecins = data?.data?.cabinetPrincipal?.equipeMedicale.filter(
    (membre) => membre?.role === "MEDECIN_GENERALISTE",
  )

  const [medecinReferent, setMedecinReferent] = useState<
    MembreEquipeMedicale | undefined | null
  >(
    medecins?.find(
      (membre) => membre?.id === patient.programme.medecinReferent?.id,
    ) as unknown as MembreEquipeMedicale,
  )

  useEffect(() => {
    const medecinReferentDuProgramme = medecins?.find(
      (membre) => membre?.id === patient.programme.medecinReferent?.id,
    ) as unknown as MembreEquipeMedicale
    if (medecinReferentDuProgramme) {
      setMedecinReferent(medecinReferentDuProgramme)
    }
  }, [medecins])

  const afficherBoutonRefuser = (): boolean => {
    switch (patient.programme.statut) {
      case StatutInscriptionProgrammePrimaryCare.Inscrit:
      case StatutInscriptionProgrammePrimaryCare.NonInscrit:
      case StatutInscriptionProgrammePrimaryCare.Refuse:
        return false
      case StatutInscriptionProgrammePrimaryCare.Interesse:
      case StatutInscriptionProgrammePrimaryCare.Preinscrit:
        return true
    }
  }

  const { mutate: validerInscription, isPending: enCoursDeValidation } =
    // TODO(judithp): [Apollo] Use Apollo instead.
    useGQLMutation(inscrireAuProgrammePrimary, {
      invalidateQueryKeys: [
        [patientParIdentifiantQuery, { identifiant: patient.id }],
      ],
    })

  const { mutate: refuserInscription, isPending: enCoursDeRefus } =
    // TODO(judithp): [Apollo] Use Apollo instead.
    useGQLMutation(refuserInscriptionAuProgrammePrimary, {
      invalidateQueryKeys: [
        [patientParIdentifiantQuery, { identifiant: patient.id }],
      ],
    })

  const loading = enCoursDeValidation || enCoursDeRefus

  return (
    <>
      <button
        className="flex items-center gap-2 rounded-lg px-4 py-3 text-h6 shadow-md transition-shadow hover:shadow-lg active:shadow-sm"
        onClick={() => setModaleOuverte(true)}
      >
        <Logo className="text-yellow" />
        <span>{libelleDuBoutonDInscription()}</span>
        <ChevronRight className="text-[14px]" />
      </button>
      <Modal
        loading={loading}
        open={modaleOuverte}
        onClose={() => setModaleOuverte(false)}
        disabled={medecinReferent === null}
        onConfirm={() => {
          validerInscription(
            {
              identifiantProfilPatient: patient.id,
              identifiantMedecinReferent: medecinReferent!.id,
            },
            {
              onError: () => {
                toast.error(
                  t(
                    "programmePrimary.uneErreurEstSurvenueLorsDeLaValidationDeLInscription",
                  ),
                )
              },
              onSuccess: () => {
                toast.success(t("programmePrimary.inscriptionValidee"))
                setModaleOuverte(false)
              },
            },
          )
        }}
        title={t("programmePrimary.inscriptionAuProgramme")}
        confirmButtonTitle={t("programmePrimary.inscrire")}
        cancelButton={!afficherBoutonRefuser()}
        dangerButton={afficherBoutonRefuser() && t("programmePrimary.refuser")}
        onDangerConfirm={() => {
          refuserInscription(
            { identifiantProfilPatient: patient.id },
            {
              onError: () => {
                toast.error(
                  t(
                    "programmePrimary.uneErreurEstSurvenueLorsDuRefusDeLInscription",
                  ),
                )
              },
              onSuccess: () => {
                toast.success(t("programmePrimary.inscriptionRefusee"))
                setModaleOuverte(false)
              },
            },
          )
        }}
        dangerConfirmationTitle={t(
          "programmePrimary.etesVousSurDeRefuserLInscription",
        )}
        autosize
        size="small"
      >
        <div className="flex flex-1 flex-col gap-4 text-left">
          <Logo className="self-center text-h2 text-yellow" />
          <div className="text-p-small">
            {t(
              "programmePrimary.lInscriptionAuProgrammeNecessiteUnChoixMedecinReferent",
            )}
          </div>
          <div className="flex">
            <div className="flex w-full items-center">
              <div className="text-h5 font-light text-dark-plum">
                {t("medecinReferentLibelle")}
              </div>
              <div className="flex-grow"></div>
            </div>
            {medecins && medecins.length > 0 && (
              <ListeDesMedecinsReferents
                medecins={medecins}
                selection={medecinReferent}
                setSelection={setMedecinReferent}
              />
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}
