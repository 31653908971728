import {
  RendezVousQueryQuery,
  StatutRendezVous,
  TypeDeRendezVous,
} from "@data/gql/graphql"
import { rendezVousQuery } from "@data/rendezVous/queries/rendezVous"
import { useGraphQLQuery } from "@data/useGraphQL"
import { PageLayout } from "@ds"
import { EnteteTag, Tabs } from "@primary/design-system"
import { EntetePatient } from "@features/rendezVous/detailPreconsultation/components/EntetePatient"
import { ListeDeRendezVousParPatient } from "@features/rendezVous/listeDeRendezVous/ListeDeRendezVousParPatient"
import { appRoute, patientRoute } from "@infra/navigation/router"
import { useNavigate } from "@tanstack/react-router"
import { format, formatRelative, isToday } from "date-fns"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { fr } from "date-fns/locale"
import frLocaleJson from "@locale/fr.json"
import { ContextePatient } from "../components/ContextePatient"
import { patientParIdentifiantQuery } from "@data/patient/patientParIdentifiantQuery"
import { BoutonMettreEnFormeSyntheseContexte } from "../components/BoutonMettreEnFormeSyntheseContexte"
import { Depistages } from "./Depistages"
import { useTour } from "@reactour/tour"
import { usePreferences } from "@infra/preferences/usePreferences"
import { useFlags } from "launchdarkly-react-client-sdk"
import { stepTourDepistagesPatient } from "@features/tour/TourDepistages"

export const Patient = () => {
  const { id } = patientRoute.useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [index, setIndex] = useState(0)

  // TODO(judithp): [Apollo] Use Apollo instead.
  const { data } = useGraphQLQuery({
    document: rendezVousQuery,
    variables: { identifiantProfil: id },
    keepDataWhileLoading: true,
  })

  // TODO(judithp): [Apollo] Use Apollo instead.
  const { data: patientData } = useGraphQLQuery({
    document: patientParIdentifiantQuery,
    variables: { identifiant: id },
  })

  const { tourDepistagesPatient, setPreferences } = usePreferences()

  const { setIsOpen, setSteps } = useTour()
  const { lotPousserLesDepistagesDansDoctolib } = useFlags()
  useEffect(() => {
    if (
      lotPousserLesDepistagesDansDoctolib &&
      !tourDepistagesPatient &&
      patientData?.data?.patientParIdentifiant?.depistages &&
      patientData.data.patientParIdentifiant.depistages.length > 0
    ) {
      setTimeout(() => {
        if (setSteps) {
          setSteps([stepTourDepistagesPatient])
        }
        setIsOpen(true)
        setPreferences({ tourDepistagesPatient: true })
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientData])

  const rendezVous = data?.data?.rendezVous.filter(
    (rdv) => rdv.statut !== StatutRendezVous.Annule,
  )
  const children = [
    {
      title: t("profil"),
      showCount: false,
      content: patientData?.data && (
        <>
          <ContextePatient
            identifiantProfil={id}
            patient={patientData?.data.patientParIdentifiant}
            boutonMettreEnForme={BoutonMettreEnFormeSyntheseContexte}
          />
          <div
            onClick={() => {
              // FIXME: empty callback
            }}
          >
            <Depistages patientId={patientData.data.patientParIdentifiant.id} />
          </div>
        </>
      ),
    },
    {
      title: t("consultations"),
      showCount: false,
      content: rendezVous && (
        <ListeDeRendezVousParPatient rendezVous={rendezVous} />
      ),
    },
  ]

  const rendezVousAAfficher = selectionnerLeProchainRdv(rendezVous)

  return (
    <PageLayout className="flex flex-col">
      {patientData?.data?.patientParIdentifiant && (
        <div className="mx-10 mt-4 flex flex-1 flex-col">
          <EntetePatient
            patient={patientData?.data?.patientParIdentifiant}
            info={
              rendezVousAAfficher !== undefined && (
                <div className="flex flex-row gap-1">
                  <EnteteTag
                    color="bg-secondary-blue"
                    content={
                      t("consultation") +
                      " · " +
                      formatRelative(
                        rendezVousAAfficher.dateHeureDeDebut,
                        new Date(),
                        {
                          locale: {
                            ...fr,
                            formatRelative: (token) =>
                              frLocaleJson["formatRelative"][token],
                          },
                        },
                      )
                    }
                  />
                  <EnteteTag
                    color="bg-secondary-blue"
                    icon={
                      rendezVousAAfficher.medecin?.photo && (
                        <img
                          className="h-4 w-4 rounded-xl"
                          src={rendezVousAAfficher.medecin.photo}
                          alt={rendezVousAAfficher.medecin.nom ?? undefined}
                        />
                      )
                    }
                    content={
                      rendezVousAAfficher.medecin.civilite +
                      " " +
                      rendezVousAAfficher.medecin.nom
                    }
                  />
                </div>
              )
            }
            onBack={() =>
              navigate({
                to: appRoute.to,
                search: { date: format(new Date(), "yyyy-MM-dd") },
              })
            }
            noBorder={true}
          />
          <div className="my-6">
            <Tabs
              selectedIndex={index}
              onChange={(index) => {
                setIndex(index)
              }}
              children={children}
              fullWidth
            />
          </div>
        </div>
      )}
    </PageLayout>
  )
}
function selectionnerLeProchainRdv(
  rendezVous: RendezVousQueryQuery["rendezVous"] | undefined,
) {
  const rendezVousDuJour = rendezVous?.filter((rdv) =>
    isToday(new Date(rdv.dateHeureDeDebut)),
  )
  const prochainsRdv = rendezVous?.filter(
    (rdv) => rdv.typeDeRendezVous === TypeDeRendezVous.AVenir,
  )

  return rendezVousDuJour && rendezVousDuJour.length > 0
    ? rendezVousDuJour[0]
    : prochainsRdv && prochainsRdv.length > 0
      ? prochainsRdv[0]
      : undefined
}
