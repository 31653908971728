import { format, roundToNearestMinutes, isBefore, isEqual } from "date-fns"

type RdvPartiel = {
  dateHeureDeDebut: Date
  patientEnSalleDAttente: boolean
  patient: {
    nouveauPatient: boolean
    nom: string
  }
  preconsultation: {
    preparation: {
      statut: string
    }
  }
}

export const trierParQuartDHeure = <T extends RdvPartiel>(input: T[]) => {
  const result: { [key: string]: T[] } = {}
  input.forEach((rdv) => {
    const { dateHeureDeDebut } = rdv

    const trancheHoraire = roundToNearestMinutes(dateHeureDeDebut, {
      roundingMethod: "floor",
      nearestTo: 15,
    })
    const heure = format(trancheHoraire, "HH:mm")

    result[heure] = result[heure] || []
    result[heure].push(rdv)

    result[heure].sort((rdv1, rdv2) => {
      if (rdv1.patientEnSalleDAttente !== rdv2.patientEnSalleDAttente) {
        return rdv1.patientEnSalleDAttente ? -1 : 1
      }

      if (rdv1.patient.nouveauPatient !== rdv2.patient.nouveauPatient) {
        return rdv1.patient.nouveauPatient ? -1 : 1
      }

      const statutOrder: { [key: string]: number } = {
        A_PREPARER: 1,
        PREPARATION_TERMINEE: 2,
      }

      if (
        statutOrder[rdv1.preconsultation.preparation.statut] !==
        statutOrder[rdv2.preconsultation.preparation.statut]
      ) {
        return (
          (statutOrder[rdv1.preconsultation.preparation.statut] || 3) -
          (statutOrder[rdv2.preconsultation.preparation.statut] || 3)
        )
      }
      if (isBefore(rdv1.dateHeureDeDebut, rdv2.dateHeureDeDebut)) {
        return -1
      }
      if (isEqual(rdv2.dateHeureDeDebut, rdv1.dateHeureDeDebut)) {
        return rdv1.patient.nom.localeCompare(rdv2.patient.nom)
      }

      return 0
    })
  })

  return result
}
