import { Cross } from "@primary/design-system"
import { useTranslation } from "react-i18next"
import { useEffect, useRef } from "react"

export type SearchBarProps = {
  icon?: React.ReactNode
  placeholder?: string
  onType: (recherche: string) => void
  onClose?: () => void
}

export const SearchBar = ({
  icon,
  placeholder,
  onClose,
  onType,
}: SearchBarProps) => {
  const { t } = useTranslation()
  const recherche = useRef<HTMLInputElement | null>(null)
  useEffect(() => {
    recherche.current?.focus()
  }, [])
  return (
    <div className="flex flex-1 items-center gap-2 p-2">
      {icon && icon}
      <input
        className="w-full text-p-small text-grey focus:outline-none"
        placeholder={placeholder || t("rechercher")}
        autoFocus
        data-testid="recherche-patient-input"
        onInput={(e: React.FormEvent<HTMLInputElement>) =>
          (e.target as HTMLInputElement).value.length > 2
            ? onType((e.target as HTMLInputElement).value)
            : onType("")
        }
        ref={recherche}
      />
      {onClose && (
        <button data-testid="close" onClick={onClose}>
          <Cross className="text-p-medium text-black" />
        </button>
      )}
    </div>
  )
}
