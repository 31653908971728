import { useNavigate } from "@tanstack/react-router"
import { InformationsDeRendezVous } from "../InformationsDeRendezVous"
import { grouperParTypeDeRendezVous } from "./grouperParTypeDeRendezVous"
import { useTranslation } from "react-i18next"
import { RendezVousQueryQuery, TypeDeRendezVous } from "@data/gql/graphql"
import { GroupedList, RowsByGroupName } from "@primary/design-system"
import { patientRoute, preconsultationRoute } from "@infra/navigation/router"

type ListeDeRendezVousProps = {
  rendezVous: RendezVousQueryQuery["rendezVous"]
}
export const ListeDeRendezVousParPatient = ({
  rendezVous,
}: ListeDeRendezVousProps) => {
  const { t } = useTranslation()

  const result: {
    [key: string]: { [key: string]: RendezVousQueryQuery["rendezVous"] }
  } = grouperParTypeDeRendezVous(rendezVous)

  const donneesDeConsultation: RowsByGroupName = Object.entries(result)
    .sort((a) => (a[0] === TypeDeRendezVous.AVenir ? -1 : 1)) //permet d'afficher les rdvs à venir toujours en premier
    .map(([key, rdvParMois]) => {
      const children = Object.entries(rdvParMois).map(([key, value]) => (
        <RendezVousParMois key={key} mois={key} rdvs={value} />
      ))
      return {
        key: t(key.toLocaleLowerCase()),
        children,
      }
    })
  return <GroupedList groupedRows={donneesDeConsultation} />
}

type RendezVousParMoisProps = {
  mois: string
  rdvs: RendezVousQueryQuery["rendezVous"]
}

const RendezVousParMois = ({ mois, rdvs }: RendezVousParMoisProps) => {
  const navigate = useNavigate()
  return (
    <div key={mois} data-testid={mois}>
      <div className="py-4 text-h7">{mois}</div>
      {rdvs.map((informations) => (
        <InformationsDeRendezVous
          key={informations.id}
          formatDate={"dd MMM yyyy"}
          displayPatientName={false}
          rendezVous={informations}
          onClick={() => {
            if (informations.rendezVousDePrevention) {
              navigate({
                to: patientRoute.to,
                params: { id: informations.patient.id },
              })
            } else {
              navigate({
                to: preconsultationRoute.to,
                params: { id: informations.id },
              })
            }
          }}
          peutMarquerANePasFaire={false}
        />
      ))}
    </div>
  )
}
