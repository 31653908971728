import { useTranslation } from "react-i18next"
import { useEffect } from "react"
import { usePreferences } from "@infra/preferences/usePreferences"
import { useNavigate } from "@tanstack/react-router"
import { appRoute } from "@infra/navigation/router"
import {
  CabinetFragmentDoc,
  CabinetsDocument,
  getFragmentData,
  useQuery,
} from "@primary/data"

export const ChargementDesCabinets = () => {
  const { t } = useTranslation()

  const { data } = useQuery(CabinetsDocument, {
    fetchPolicy: "cache-first",
  })
  const { identifiantDuCabinet, setPreferences } = usePreferences()
  const navigate = useNavigate()

  useEffect(() => {
    if (!data || data.cabinets.length === 0) {
      return
    }

    if (!identifiantDuCabinet) {
      const identifiantDuPremierCabinet = getFragmentData(
        CabinetFragmentDoc,
        data.cabinets[0],
      ).id
      setPreferences({ identifiantDuCabinet: identifiantDuPremierCabinet })
    }

    navigate({ to: appRoute.to })
  }, [data, setPreferences, identifiantDuCabinet, navigate])

  if (!data || data.cabinets.length === 0) {
    return <div data-testid="chargement">{t("accesAAucunCabinet")}</div>
  }

  return <div data-testid="chargement">{t("chargement")}</div>
}
