import { classNames, PrimaryApp, Dot, Warning } from "@primary/design-system"
import { capitalize } from "@utils/capitalize"
import { format } from "date-fns"
import { useTranslation } from "react-i18next"
import { CabinetTag } from "@features/messagerie/components/CabinetTag"

export type CompteProps = {
  identifiantUtilisateur: string
  numeroDeTelephone: string
  email?: string | null | undefined
}

type CabinetProps = {
  id: string
}

export type PatientTrouveProps = {
  patient: {
    id: string
    nom: string
    prenom?: string | null | undefined
    sexe?: string | null | undefined
    dateDeNaissance?: Date | null | undefined
    numeroDeTelephone?: string | null | undefined
    compte?: CompteProps | null | undefined
    cabinetPrincipal: CabinetProps
  }
  selectPatient: (patientSelectionne: PatientSelectionne) => void
  selected?: boolean | null | undefined
  disabled?: boolean | null | undefined
  disabledReason?: string | null | undefined
}

export interface PatientSelectionne {
  id: string
  cabinetPrincipal: CabinetPrincipal
}

export interface CabinetPrincipal {
  id: string
}

export const PatientTrouve = ({
  patient,
  selectPatient,
  selected,
  disabled,
  disabledReason,
}: PatientTrouveProps) => {
  const { t } = useTranslation()
  const patientPossedeLApplication = patient.compte

  return (
    <div className="border-b border-b-extra-light-grey">
      <div
        data-testid={"patient-trouve-" + patient.id}
        className={classNames("flex flex-1 items-center rounded-xl p-3", {
          "bg-extra-light-grey": selected,
          "hover:bg-extra-light-grey": !disabled,
          "hover:cursor-pointer": !disabled,
        })}
        onClick={() => !disabled && selectPatient(patient)}
      >
        <div
          className={classNames("flex w-full flex-col gap-1", {
            "text-grey": disabled,
            "text-black": !disabled,
          })}
        >
          <div className="flex gap-1">
            <div className="text-h5">
              {capitalize(patient.prenom) + " " + patient.nom}
            </div>
            {patientPossedeLApplication && <PrimaryApp />}
            <div className="flex-grow"></div>
            <CabinetTag cabinetId={patient.cabinetPrincipal.id} />
          </div>
          <div className="flex items-center gap-2 text-p-tiny">
            <div>{patient.sexe && t(patient.sexe).at(0)}</div>
            {patient.dateDeNaissance && (
              <>
                <Dot />
                <div>
                  {patient.dateDeNaissance &&
                    format(patient.dateDeNaissance, "dd/MM/yyyy")}
                </div>
              </>
            )}
            {patient.numeroDeTelephone && (
              <>
                <Dot />
                <div>{patient.numeroDeTelephone}</div>
              </>
            )}
            {disabled && (
              <>
                <Dot />
                <div className="flex items-center gap-1 text-h6 font-normal text-unsure">
                  <div>{disabledReason}</div>
                  <Warning className={"h-5 w-5"} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
