import { GroupedList, RowsByGroupName } from "@primary/design-system"
import { InformationsDeRendezVous } from "../InformationsDeRendezVous"
import { useNavigate } from "@tanstack/react-router"
import { patientRoute, preconsultationRoute } from "@infra/navigation/router"
import { RendezVousParCabinetAPI } from "@data/rendezVous/queries/rendezVousParCabinetQuery"
import { trierParQuartDHeure } from "./trierParQuartDHeure"
import { RendezVousParCabinetQueryQuery } from "@data/gql/graphql"

type ListeDeRendezVousProps = {
  rendezVous: RendezVousParCabinetQueryQuery["rendezVousParCabinet"]
  peutMarquerANePasFaire: boolean
}

export const ListeDeRendezVousParCabinet = ({
  rendezVous,
  peutMarquerANePasFaire,
}: ListeDeRendezVousProps) => {
  const navigate = useNavigate()

  const result: { [key: string]: RendezVousParCabinetAPI[] } =
    trierParQuartDHeure(rendezVous)
  const donneesDeConsultation: RowsByGroupName = Object.entries(result)
    .sort()
    .map(([key, value]) => {
      const children = (
        <>
          {value.map((informations) => (
            <InformationsDeRendezVous
              key={informations.id}
              rendezVous={informations}
              onClick={() => {
                if (informations.rendezVousDePrevention) {
                  navigate({
                    to: patientRoute.to,
                    params: { id: informations.patient.id },
                  })
                } else {
                  navigate({
                    to: preconsultationRoute.to,
                    params: { id: informations.id },
                  })
                }
              }}
              peutMarquerANePasFaire={peutMarquerANePasFaire}
            />
          ))}
        </>
      )
      return {
        key,
        children,
      }
    })
  return <GroupedList groupedRows={donneesDeConsultation} />
}
