import { estUnBebe, estUnEnfant } from "@features/patient/agePatient"
import bebe from "../../assets/bebe.png"
import garcon from "../../assets/garcon.png"
import fille from "../../assets/fille.png"
import homme from "../../assets/homme.png"
import femme from "../../assets/femme.png"

export interface EmojiProps {
  dateDeNaissance?: Date | null
  sexe?: string | null
}

export const Emoji = ({ dateDeNaissance, sexe }: EmojiProps) => {
  if (dateDeNaissance) {
    if (estUnBebe(dateDeNaissance)) {
      return <img className="h-3 w-3" src={bebe} alt="bébé" />
    }
    if (estUnEnfant(dateDeNaissance)) {
      const emoji = sexe === "male" ? garcon : fille
      return (
        <img
          className="h-3 w-3"
          src={emoji}
          alt={sexe === "male" ? "garcon" : "fille"}
        />
      )
    }
    const emoji = sexe === "male" ? homme : femme
    return (
      <img
        className="h-3 w-3"
        src={emoji}
        alt={sexe === "male" ? "homme" : "femme"}
      />
    )
  }

  if (sexe) {
    const emoji = sexe === "male" ? homme : femme
    return (
      <img
        className="h-3 w-3"
        src={emoji}
        alt={sexe === "male" ? "homme" : "femme"}
      />
    )
  }
  return <></>
}
