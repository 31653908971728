import { Switch } from "@headlessui/react"
import { useTranslation } from "react-i18next"
import { usePreferences } from "@infra/preferences/usePreferences"
import { Flag } from "@primary/design-system"

export const ToggleMessagesATraiter = () => {
  const { t } = useTranslation()
  const { messagesATraiterUniquement, setPreferences } = usePreferences()

  return (
    <div className="flex items-center gap-1 pl-2">
      <Switch
        checked={messagesATraiterUniquement}
        onChange={() =>
          setPreferences({
            messagesATraiterUniquement: !messagesATraiterUniquement,
          })
        }
        className="group inline-flex h-6 w-11 items-center rounded-full bg-gray-200 data-[disabled]:cursor-not-allowed data-[checked]:bg-dark-plum data-[disabled]:opacity-50"
        data-testid="toggle-messages-a-traiter"
      >
        <span className="size-4 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6" />
      </Switch>
      <Flag
        className="rounded p-0.5 text-p-medium"
        data-testid="demande-aide-medecin-icone"
      />
      <div className="text-p-tiny">{t("messagerie.aTraiterUniquement")}</div>
    </div>
  )
}
