export const capitalize = (str: string | null | undefined) => {
  if (!str) return ""
  return str
    .split(/(\s|-)/)
    .map(
      (word) =>
        word.charAt(0).toUpperCase() + word.slice(1).toLocaleLowerCase(),
    )
    .join("")
}
