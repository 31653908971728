import { graphql } from "@data/gql"

export const enregistrerVaccins = graphql(`
  mutation enregistrerVaccins(
    $identifiantDuRendezVous: String!
    $vaccins: [VaccinInput!]!
  ) {
    enregistrerVaccins(
      identifiantRendezVous: $identifiantDuRendezVous
      vaccins: $vaccins
    ) {
      id
    }
  }
`)
