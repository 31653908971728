import { useChannelStateContext, useChatContext } from "stream-chat-react"
import { buildNomComplet, Soignant } from "../domain/Soignant"
import { toast } from "@infra/toast/toast"
import { useTranslation } from "react-i18next"

export const useDemandeAideMedecin = () => {
  const { t } = useTranslation()
  const { client } = useChatContext()
  const { channel } = useChannelStateContext()

  const demanderAideMedecin = async () => {
    const conversationAvecUnMedecin =
      channel?.state?.members &&
      Object.values(channel.state?.members)
        .map((member) => member.user)
        .some((user) => user?.role === "soignant" && user?.metier === "medecin")

    if (conversationAvecUnMedecin) {
      const demandeRealiseePar = buildNomComplet(
        client.user as Soignant,
      ) as string
      await channel?.updatePartial({
        set: {
          demandeAideMedecin: true,
          demandeAideMedecinPar: demandeRealiseePar,
        },
      })
      await channel?.sendMessage(
        {
          //@ts-expect-error(force SDK to generate message id)
          id: null,
          type: "system",
          text: t("messagerie.demandeRealiseeMessage", {
            qui: demandeRealiseePar,
          }),
          user_id: client?.userID,
          silent: true,
          event: { type: "channel.updated" },
          demandeAideMedecin: true,
        },
        { skip_push: true },
      )
      toast.success(t("messagerie.demandeRealiseeToast"))
    } else {
      toast.warning(t("messagerie.demandeImpossibleToast"))
    }
  }

  const retirerDemandeAideMedecin = async () => {
    const demandeRetireePar = buildNomComplet(client.user as Soignant)
    await channel?.updatePartial({
      unset: ["demandeAideMedecin", "demandeAideMedecinPar"],
    })
    await channel?.sendMessage(
      {
        //@ts-expect-error(force SDK to generate message id)
        id: null,
        type: "system",
        text: t("messagerie.demandeRetireeMessage", { qui: demandeRetireePar }),
        user_id: client?.userID,
        silent: true,
        event: { type: "channel.updated" },
      },
      { skip_push: true },
    )
  }
  return { demanderAideMedecin, retirerDemandeAideMedecin }
}
