import { useEffect, useState } from "react"
import { usePreferences } from "@infra/preferences/usePreferences"
import { useChatContext } from "stream-chat-react"
import { Soignant } from "../domain/Soignant"

export const useListeSoignantsDuCabinet = () => {
  const { identifiantDuCabinet } = usePreferences()
  const { client } = useChatContext()
  const [listeSoignantsDuCabinet, setListeSoignantsDuCabinet] =
    useState<Soignant[]>()

  const genererLaListeDeSoignantsDuCabinet = async () => {
    return identifiantDuCabinet
      ? await client
          ?.queryUsers({
            role: "soignant",
            teams: { $contains: identifiantDuCabinet },
          })
          .then((response) =>
            response.users.map((u) => {
              const soignant: Soignant = {
                id: u.id,
                civilite: u.civilite as string,
                prenom: u.prenom as string,
                nom: u.nom as string,
                urlAvatar: u.urlAvatar as string,
                metier: u.metier as string,
              }
              return soignant
            }),
          )
      : []
  }

  useEffect(() => {
    genererLaListeDeSoignantsDuCabinet().then((soignants) => {
      setListeSoignantsDuCabinet(soignants)
    })
  }, [identifiantDuCabinet])

  return {
    listeSoignantsDuCabinet,
  }
}
