import { graphql } from "@data/gql"

export const envoyerPreconsultationDansDoctolib = graphql(`
  mutation envoyerLaPreconsultationDansDoctolib(
    $identifiantDuRendezVous: String!
  ) {
    envoyerLaPreconsultationDansDoctolib(
      identifiantRendezVous: $identifiantDuRendezVous
    )
  }
`)
