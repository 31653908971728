import { graphql } from "@data/gql"

export const enregistrerSynthese = graphql(`
  mutation enregistrerSyntheseMutation(
    $identifiantDuRendezVous: String!
    $contenu: String!
  ) {
    enregistrerSynthese(
      identifiantRendezVous: $identifiantDuRendezVous
      contenu: $contenu
    ) {
      id
    }
  }
`)
