import { useEffect, useRef } from "react"
import { Subject } from "rxjs"

export const useSubjectFactory = <T>(
  subject: Subject<T>,
  value: T,
): Subject<T> => {
  const prevValue = useRef(value)
  const valueChangeSubject = useRef(subject)

  useEffect(() => {
    if (
      (typeof value === "string" && value !== prevValue.current) ||
      JSON.stringify(value) !== JSON.stringify(prevValue.current)
    ) {
      valueChangeSubject.current.next(value)
    }
    prevValue.current = value
  }, [value])

  return valueChangeSubject.current
}
