import { useEffect, useMemo } from "react"
import { ChannelFilters, ChannelSort, DefaultGenerics } from "stream-chat"
import {
  ChannelListMessenger,
  ChannelListMessengerProps,
  MAX_QUERY_CHANNELS_LIMIT,
  useChannelListContext,
  useChatContext,
} from "stream-chat-react"
import uniqBy from "lodash.uniqby"
import { di } from "@di"
import { useObservable } from "@utils/useObservable"

export const CustomList = (
  props: ChannelListMessengerProps<DefaultGenerics> & {
    filters: ChannelFilters<DefaultGenerics>
    idFiltreInboxChoisi: string
  },
) => {
  const maBoiteChannelsStream = di.StreamChatService.maBoiteChannels
  const { channels, setChannels } = useChannelListContext()
  const maBoiteChannels = useObservable(maBoiteChannelsStream, [])
  useEffect(() => {
    if (props.idFiltreInboxChoisi === "ma-boite") {
      setChannels(uniqBy([...channels, ...maBoiteChannels], "cid"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maBoiteChannels, props.idFiltreInboxChoisi, setChannels])
  const { client } = useChatContext()
  const filters = props.filters

  // memoize props
  const filterString = useMemo(() => JSON.stringify(filters), [filters])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const queryChannels = async () => {
    try {
      const options = {
        limit: MAX_QUERY_CHANNELS_LIMIT,
        message_limit: 300,
        watch: true,
      }
      const sort =
        client.user?.metier === "medecin"
          ? ({
              demandeAideMedecin: 1,
              last_message_at: -1,
            } as unknown as ChannelSort)
          : undefined

      const channelQueryResponse = await client.queryChannels(
        filters,
        sort,
        options,
      )

      let newChannels = uniqBy([...channelQueryResponse, ...channels], "cid")

      if (props.idFiltreInboxChoisi === "ma-boite") {
        newChannels = uniqBy([...newChannels, ...maBoiteChannels], "cid")
      }

      setChannels(newChannels)
    } catch (err) {
      console.warn(err)
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      queryChannels()
    }, 5000)
    return () => clearInterval(intervalId)
  }, [filterString, queryChannels])

  return <ChannelListMessenger {...props} />
}
