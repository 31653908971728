import { Avatar, classNames } from "@primary/design-system"

export type ParticipantProps = {
  id: string
  civilite?: string
  prenom?: string
  nom?: string
  urlAvatar?: string
  metier?: string
  className?: string
}

export const Participant = ({
  id,
  civilite,
  prenom,
  nom,
  urlAvatar,
  className,
}: ParticipantProps) => {
  return (
    <div
      key={id}
      data-testid={"participants-" + id}
      title={[civilite, prenom, nom].filter((i) => !!i).join(" ")}
    >
      {urlAvatar ? (
        <img
          className={classNames("h-5 w-5 rounded-full", className)}
          src={urlAvatar}
        />
      ) : (
        <Avatar
          name={[prenom, nom].filter((i) => !!i).join(" ")}
          style="x-small"
          color="bg-secondary-blue"
          className={className}
        />
      )}
    </div>
  )
}
