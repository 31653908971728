/* eslint-disable @typescript-eslint/no-explicit-any */
const hasOwn = {}.hasOwnProperty

export function classNames(...args: any[]) {
  let classes = ""

  for (const arg of args) {
    if (arg) {
      classes = appendClass(classes, parseValue(arg))
    }
  }

  return classes
}

function parseValue(arg: any) {
  if (typeof arg === "string") {
    return arg
  }

  if (typeof arg !== "object" || arg === null) {
    return ""
  }

  if (Array.isArray(arg)) {
    // eslint-disable-next-line prefer-spread
    return classNames.apply(null, arg)
  }

  if (
    arg.toString !== Object.prototype.toString &&
    !arg.toString.toString().includes("[native code]")
  ) {
    return arg.toString()
  }

  let classes = ""

  for (const key in arg) {
    if (hasOwn.call(arg, key) && arg[key]) {
      classes = appendClass(classes, key)
    }
  }

  return classes
}

function appendClass(value: any, newClass: any) {
  if (!newClass) {
    return value
  }

  return value ? value + " " + newClass : newClass
}
