import { membreEquipeMedicaleConnecteQuery } from "@data/equipeMedicale/membreEquipeMedicaleConnecteQuery"
import { useGraphQLQuery } from "@data/useGraphQL"
import { Avatar, ChevronDown, ChevronUp } from "@primary/design-system"
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react"
import { useAuth } from "@infra/auth"
import { usePreferences } from "@infra/preferences/usePreferences"
import { useTranslation } from "react-i18next"
import { Fragment } from "react"

export const UserProfile = () => {
  // TODO(judithp): [Apollo] Use Apollo instead.
  const { data } = useGraphQLQuery({
    document: membreEquipeMedicaleConnecteQuery,
  })
  const { t } = useTranslation()
  const { logout } = useAuth()
  const { sideBarOuverte, setPreferences } = usePreferences()

  const utilisateur = data?.data?.membreEquipeMedicaleConnecte
  const nomUtilisateur = utilisateur?.prenom + " " + utilisateur?.nom

  const profilePic = (
    <div data-testid="user-profile-pic">
      {utilisateur?.photo ? (
        <div className="h-8 w-8 cursor-pointer">
          <img className="rounded border-white" src={utilisateur.photo} />
        </div>
      ) : (
        <div className="cursor-pointer">
          <Avatar name={nomUtilisateur} />
        </div>
      )}
    </div>
  )

  return (
    <div className="mb-6 mr-6 flex flex-col">
      {!sideBarOuverte && utilisateur && (
        <div
          className="pl-4"
          onClick={() => setPreferences({ sideBarOuverte: true })}
        >
          {profilePic}
        </div>
      )}
      {sideBarOuverte && utilisateur && (
        <Menu>
          <MenuButton as={Fragment}>
            {({ open }) => (
              <button className="flex flex-1 items-center justify-between gap-2 pl-8 pt-2">
                {profilePic}
                <div className="truncate text-p-small">{nomUtilisateur}</div>
                {open ? (
                  <ChevronDown className="h-4 w-4" />
                ) : (
                  <ChevronUp className="h-4 w-4" />
                )}
              </button>
            )}
          </MenuButton>
          <MenuItems
            anchor="top start"
            className="mx-4 min-w-52 rounded-xl bg-white p-3 shadow-light-shadow"
          >
            <MenuItem>
              <a
                className="block cursor-pointer rounded-lg p-2 text-p-small hover:bg-extra-light-grey"
                onClick={() => logout()}
              >
                {t("meDeconnecter")}
              </a>
            </MenuItem>
            {import.meta.env.MODE === "staging" && (
              <MenuItem>
                <a
                  className="block cursor-pointer rounded-lg p-2 text-p-small hover:bg-extra-light-grey"
                  onClick={() => {
                    throw new Error("Crash test")
                  }}
                >
                  {t("crash")}
                </a>
              </MenuItem>
            )}
          </MenuItems>
        </Menu>
      )}
    </div>
  )
}
