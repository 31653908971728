import { useTranslation } from "react-i18next"
import { isAfter, isBefore, isEqual } from "date-fns"
import { CardDepistage } from "../components/CardDepistage"
import { classNames } from "@primary/design-system"
import {
  useQuery,
  PatientParIdentifiantDocument,
  DepistagePatientFragmentDoc,
  getFragmentData,
  StatutProchainDepistage,
} from "@primary/data"
import { useMemo } from "react"

export type DepistagesProps = {
  patientId: string
  onChange?: () => void
}

export const Depistages = ({ patientId, onChange }: DepistagesProps) => {
  const { t } = useTranslation()

  const { loading, data } = useQuery(PatientParIdentifiantDocument, {
    variables: { identifiant: patientId },
  })

  const today = Date.now()

  const rawDepistages =
    data?.patientParIdentifiant?.depistages.map((d) =>
      getFragmentData(DepistagePatientFragmentDoc, d),
    ) ?? []

  const depistagesOrder = useMemo(
    () =>
      rawDepistages.sort((a, b) => {
        const order = [
          StatutProchainDepistage.EnRetard,
          StatutProchainDepistage.AFaire,
          StatutProchainDepistage.AJour,
        ]
        const statutComparison =
          order.indexOf(a.statutProchainDepistage) -
          order.indexOf(b.statutProchainDepistage)
        if (statutComparison !== 0) return statutComparison
        return (
          new Date(a.dateProchainDepistage).getTime() -
          new Date(b.dateProchainDepistage).getTime()
        )
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rawDepistages.length],
  )

  const depistages = rawDepistages.sort(
    (a, b) => depistagesOrder.indexOf(a) - depistagesOrder.indexOf(b),
  )

  if (loading) {
    return <div />
  }

  const depistagesEligibles = depistages.filter(
    (depistage) =>
      isBefore(depistage.dateDEligibilite, today) ||
      isEqual(depistage.dateDEligibilite, today),
  )
  const depistagesNonEligibles = depistages.filter((depistage) =>
    isAfter(depistage.dateDEligibilite, today),
  )

  return (
    <div id="depistages">
      <div
        className={classNames(
          "text-h4",
          depistages.length === 0 ? "mb-2" : "mb-6",
        )}
        data-testid="depistages"
      >
        {t("depistages")}
      </div>
      {depistages.length === 0 && (
        <div className="text-p-tiny italic text-grey">
          {t("eligibleAPartirde16ans")}
        </div>
      )}
      {depistagesEligibles.length > 0 && (
        <div className="mb-6">
          <div className="flex flex-col gap-2">
            {depistagesEligibles.map((depistage) => (
              <CardDepistage
                depistage={depistage}
                key={depistage.id}
                onChange={onChange}
              />
            ))}
          </div>
        </div>
      )}
      {depistagesNonEligibles.length > 0 && (
        <>
          <div className="mb-2 text-h7 text-grey">
            {t("bientotEligibles").toUpperCase()}
          </div>
          <div className="flex flex-col gap-2">
            {depistagesNonEligibles.map((depistage) => (
              <CardDepistage
                depistage={depistage}
                key={depistage.nom}
                onChange={onChange}
              />
            ))}
          </div>
        </>
      )}
    </div>
  )
}
