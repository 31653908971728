import { graphql } from "@data/gql"

export const marquerPreconsultationANePasFaire = graphql(`
  mutation marquerPreconsultationANePasFaire(
    $identifiantDuRendezVous: String!
  ) {
    marquerPreconsultationANePasFaire(
      identifiantRendezVous: $identifiantDuRendezVous
    )
  }
`)
