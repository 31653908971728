import { graphql } from "@data/gql"

export const membreEquipeMedicaleConnecteQuery = graphql(`
  query membreEquipeMedicale {
    membreEquipeMedicaleConnecte {
      civilite
      nom
      prenom
      photo
    }
  }
`)
