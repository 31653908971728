import { CheckRound, Cross, Warning } from "@primary/design-system"
import { ReactNode } from "react"
import {
  toast as toastifyToast,
  ToastOptions,
  ToastPosition,
  TypeOptions,
} from "react-toastify"
import "@infra/toast/toast.css"

export { toast as toastify } from "react-toastify"

const toastOptions: ToastOptions<unknown> = {
  autoClose: 5000,
  hideProgressBar: true,
  className: (context?: {
    type?: TypeOptions
    defaultClassName?: string
    position?: ToastPosition
    rtl?: boolean
  }) =>
    contextClass[context?.type || "default"] +
    " flex py-3 px-4 rounded-xl items-start justify-between overflow-hidden cursor-pointer mb-1",
  closeButton: ({ closeToast }) => (
    <div onClick={closeToast} data-testid={"close-toast-button"}>
      <Cross className="text-h4" />
    </div>
  ),
}

export const toast = {
  success: (msg: string, options?: ToastOptions) => {
    return toastifyToast.success(msg, {
      icon: () => <CheckRound className="text-h4" />,
      ...toastOptions,
      ...options,
    })
  },
  warning(msg: string | ReactNode, options?: ToastOptions) {
    return toastifyToast.warning(msg, {
      icon: () => <Warning className="text-h3" />,
      ...toastOptions,
      ...options,
    })
  },
  //TODO: customiser les différents types de toast
  error(msg: string, options?: ToastOptions) {
    return toastifyToast.error(msg, {
      ...toastOptions,
      ...options,
    })
  },
  info(msg: string | ReactNode, options?: ToastOptions) {
    return toastifyToast.info(msg, {
      ...toastOptions,
      ...options,
    })
  },
}

const contextClass = {
  success: "bg-valid",
  error: "bg-error",
  info: "bg-extra-light-grey",
  warning: "bg-unsure",
  default: "bg-extra-light-grey",
}
