import { DropDownList } from "@primary/design-system"
import { usePreferences } from "@infra/preferences/usePreferences"
import { useNavigate } from "@tanstack/react-router"
import { chargementPageRoute } from "@infra/navigation/router"
import { useEffect, useMemo } from "react"
import {
  CabinetFragmentDoc,
  CabinetsDocument,
  getFragmentData,
  useQuery,
} from "@primary/data"

export const SelecteurDeCabinet = () => {
  const { identifiantDuCabinet, setPreferences } = usePreferences()
  const navigate = useNavigate()

  const { data } = useQuery(CabinetsDocument, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  })

  const items = useMemo(
    () =>
      data?.cabinets.map((cabinet) => {
        const { id, nom } = getFragmentData(CabinetFragmentDoc, cabinet)
        return { id, name: nom }
      }),
    [data],
  )
  const cabinetPrefere = useMemo(
    () => items?.find(({ id }) => id === identifiantDuCabinet),
    [items, identifiantDuCabinet],
  )

  useEffect(() => {
    if (items !== undefined && cabinetPrefere === undefined) {
      navigate({ to: chargementPageRoute.to })
      return
    }
  }, [cabinetPrefere, navigate, items])

  return (
    <DropDownList
      selectedItem={cabinetPrefere || { id: "", name: "" }}
      setSelectedItem={({ id }) => setPreferences({ identifiantDuCabinet: id })}
      items={items ?? []}
    />
  )
}
