import { useGraphQLQuery } from "@data/useGraphQL"
import { documentParIdentifiantQuery } from "@data/patient/documentParIdentifiantQuery"
import { RefObject, useLayoutEffect, useRef, useState } from "react"
import { Document, Page } from "react-pdf"
import { DocumentParIdentifiantQueryQuery } from "@data/gql/graphql"
import { ExecutionResult } from "graphql"
import { QueryObserverResult } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { classNames } from "@primary/design-system"

const DEFAULT_WIDTH = 424
const PADDING_PDF_SHADOW = 8

const useWidth = (target: RefObject<HTMLDivElement>) => {
  const [width, setWidth] = useState(DEFAULT_WIDTH)

  useLayoutEffect(() => {
    setWidth(target.current?.getBoundingClientRect().width ?? DEFAULT_WIDTH)
  }, [target])

  return width
}

export const PrevisualisationDocument = ({
  identifiantDuDocument,
  nom,
  className,
  allPages = false,
  openOnClick = false,
}: {
  identifiantDuDocument: string
  nom: string
  className?: string
  allPages?: boolean
  openOnClick?: boolean
}) => {
  const { t } = useTranslation()
  const wrapperDiv = useRef<HTMLDivElement>(null)
  // @ts-expect-error("FIXME")

  const width = useWidth(wrapperDiv)
  const [numPages, setNumPages] = useState(1)

  const [pdfProtegeParMotDePasse, setPdfProtegeParMotDePasse] = useState(false)
  // TODO(judithp): [Apollo] Use Apollo instead.
  const { data: document, refetch } = useGraphQLQuery({
    document: documentParIdentifiantQuery,
    variables: { identifiant: identifiantDuDocument },
    staleTime: 45000,
  })
  const adresse = document?.data?.demandeDeTelechargement.adresse
  const openDocument = openOnClick
    ? () => {
        refetch().then(
          (
            res:
              | QueryObserverResult<
                  ExecutionResult<DocumentParIdentifiantQueryQuery>
                >
              | undefined,
          ) => {
            if (adresse) {
              window.open(
                res?.data?.data?.demandeDeTelechargement?.adresse,
                "_blank",
              )
            }
          },
        )
      }
    : // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
  const type = new URL(adresse ?? "file://none").pathname.split(".").pop() ?? ""
  if (type === "jpg" || type === "jpeg" || type === "png") {
    return (
      <div
        ref={wrapperDiv}
        className={classNames(
          "relative overflow-hidden rounded",
          className,
          openOnClick && "cursor-pointer",
        )}
        onClick={openDocument}
        title={nom}
      >
        <div
          className="absolute -bottom-4 -left-4 -right-4 -top-4 bg-cover blur-lg"
          style={{ backgroundImage: `url(${adresse})` }}
        />
        <div
          className="absolute bottom-0 left-0 right-0 top-0 bg-contain bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${adresse})` }}
        />
      </div>
    )
  }
  if (type === "pdf") {
    return (
      <div
        className={className}
        title={nom}
        onClick={openDocument}
        ref={wrapperDiv}
        style={{ padding: allPages ? PADDING_PDF_SHADOW : 0 }}
      >
        {adresse && (
          <>
            {!pdfProtegeParMotDePasse && (
              <Document
                className={classNames(
                  "h-full w-full rounded",
                  openOnClick && "cursor-pointer",
                  !allPages && "overflow-hidden",
                )}
                file={adresse}
                onLoadSuccess={({ numPages }) =>
                  setNumPages(allPages ? numPages : 1)
                }
                onPassword={() => {
                  setPdfProtegeParMotDePasse(true)
                }}
                loading={
                  <div className="flex h-full w-full items-center justify-center">
                    {t("messagerie.chargementPdf")}
                  </div>
                }
                error={
                  <div className="flex h-full w-full items-center justify-center">
                    {t("messagerie.chargementPdfEnErreur")}
                  </div>
                }
              >
                {Array.from({ length: numPages })
                  .map((_, i) => i + 1)
                  .map((index) => (
                    <Page
                      key={index}
                      pageNumber={index}
                      width={width - (allPages ? PADDING_PDF_SHADOW * 2 : 0)}
                      className={classNames(
                        allPages && "mb-2 overflow-hidden rounded shadow-md",
                      )}
                    />
                  ))}
              </Document>
            )}
            {pdfProtegeParMotDePasse && (
              <div
                className={classNames(
                  "flex h-full w-full items-center justify-center rounded bg-dark-plum text-white",
                  openOnClick && "cursor-pointer",
                )}
              >
                {t("messagerie.pdfProtege")}
              </div>
            )}
          </>
        )}
      </div>
    )
  }
  return <></>
}
