import { mettreEnFormeLaSyntheseContexte } from "@data/patient/contextePatient/mettreEnFormeLaSyntheseContexte"
import { patientParIdentifiantQuery } from "@data/patient/patientParIdentifiantQuery"
import { useGQLMutation } from "@data/useGraphQL"
import { BoutonMettreEnForme } from "@features/rendezVous/detailPreconsultation/components/BoutonMettreEnForme"

export type BoutonMettreEnFormeSyntheseContexteProps = {
  markdown: string
  setMarkdown: (markdown: string) => void
  identifiantProfil: string
}

export const BoutonMettreEnFormeSyntheseContexte = ({
  markdown,
  setMarkdown,
  identifiantProfil,
}: BoutonMettreEnFormeSyntheseContexteProps) => {
  // TODO(judithp): [Apollo] Use Apollo instead.
  const { mutate, isPending } = useGQLMutation(
    mettreEnFormeLaSyntheseContexte,
    {
      invalidateQueryKeys: [
        [patientParIdentifiantQuery, { identifiant: identifiantProfil }],
      ],
    },
  )
  return (
    <div data-testid={"bouton-synthese-contexte-mise-en-forme"}>
      <BoutonMettreEnForme
        contenu={markdown}
        setContenu={setMarkdown}
        mettreEnForme={() => {
          return new Promise((resolve, reject) =>
            mutate(
              { contenu: markdown },
              {
                onSuccess: (data) =>
                  resolve(data?.data?.miseEnFormeSyntheseContexte ?? markdown),
                onError: reject,
              },
            ),
          )
        }}
        miseEnFormeEnCours={isPending}
      />
    </div>
  )
}
