import { useEffect } from "react"
import {
  BehaviorSubject,
  Observable,
  Subject,
  asyncScheduler,
  audit,
  delay,
  filter,
  mergeWith,
  startWith,
  tap,
  throttleTime,
} from "rxjs"
import { useSubjectFactory } from "./useSubjectFactory"

interface Options<T> {
  throttleDueTime: number
  immediate: boolean
  filter: (value: T) => boolean
}

/**
 * React Hook that throttles the [mutate] function call when the [value] changes by
 * waiting for the [isFetching] to be false. Additionally, it will call the [mutate]
 * only once.
 *
 * Warning: This hook does not reload if mutate or throttleDueTime changes.
 *
 * @param mutate the function that will be called after throttling
 * @param isFetching a boolean used to throttle the [mutate] function
 * @param value the value that will be wtached and passed to the [mutate] function
 * @param options the time to wait before calling the [mutate] function and if the [mutate] function should be called immediately
 */
export const useThrottleMutateOnChange = <T>(
  mutate: (value: T) => unknown,
  isFetching: boolean,
  value: T,
  options?: Partial<Options<T>>,
) => {
  const valueChangeSubject = useSubjectFactory(new Subject<T>(), value)
  const isFetchingSubject = useSubjectFactory(
    new BehaviorSubject<boolean>(isFetching),
    isFetching,
  )

  useEffect(() => {
    const subscription = valueChangeSubject
      .pipe(
        // Décale l'appel de la fonction [mutate] pour éviter des appels répétés, l'option [throttleDueTime] est utile pour les tests.
        throttleTime(options?.throttleDueTime ?? 0, asyncScheduler, {
          leading: false,
          trailing: true,
        }),
        // Ajoute un évènement initial pour appeler la fonction [mutate] immédiatement si l'option [immediate] est activée.
        // Cet évènement initial est déclenché après un délai de 10ms pour éviter des appels répétés sur les premiers rendus (qui peuvent être faits en grand nombre).
        options?.immediate
          ? mergeWith(new Observable<T>().pipe(startWith(value), delay(10)))
          : tap(),
        // Attend que [isFetching] soit faux pour appeler la fonction [mutate].
        audit(() =>
          isFetchingSubject.pipe(filter((isFetching) => !isFetching)),
        ),
        // Filtre les valeurs trop courtes pour éviter des appels inutiles.
        filter(options?.filter ?? (() => true)),
      )
      .subscribe((value) => {
        mutate(value)
      })

    return () => {
      subscription.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
