import { useFlags } from "launchdarkly-react-client-sdk"
import { BoutonInscrireAuProgrammePrimary } from "@features/rendezVous/detailPreconsultation/components/BoutonInscrireAuProgrammePrimary"
import {
  PatientParIdentifiantQueryQuery,
  StatutInscriptionProgrammePrimaryCare,
} from "@data/gql/graphql"
import { Avatar } from "@primary/design-system"
import { useTranslation } from "react-i18next"

interface ProgrammePrimaryProps {
  patient: PatientParIdentifiantQueryQuery["patientParIdentifiant"]
}

const StatutProgrammePrimary = ({ patient }: ProgrammePrimaryProps) => {
  const { t } = useTranslation()
  const medecinReferent = patient.programme.medecinReferent?.medecin
  return (
    <div
      className="flex w-52 items-start gap-2 rounded-lg px-4 py-3 text-h6 shadow-lg transition-shadow"
      data-testid="programme-primary-medecin-referent"
    >
      {medecinReferent?.photo ? (
        <img className="h-5 w-5 rounded" src={medecinReferent.photo} />
      ) : (
        <Avatar
          name={medecinReferent?.nom ? medecinReferent?.nom : ""}
          style="small"
        />
      )}
      <div className="flex flex-col">
        <div className="text-h5 text-dark-plum">
          {medecinReferent?.civilite} {medecinReferent?.nom}
        </div>
        <div className="text-p-tiny text-grey">{t("medecinReferent")}</div>
      </div>
    </div>
  )
}

export const ProgrammePrimary = ({ patient }: ProgrammePrimaryProps) => {
  const { programmePrimaryLot1 } = useFlags()

  if (!programmePrimaryLot1) {
    return <></>
  }
  switch (patient.programme.statut) {
    case StatutInscriptionProgrammePrimaryCare.Inscrit:
      return <StatutProgrammePrimary patient={patient} />
    case StatutInscriptionProgrammePrimaryCare.NonInscrit:
    case StatutInscriptionProgrammePrimaryCare.Refuse:
    case StatutInscriptionProgrammePrimaryCare.Interesse:
    case StatutInscriptionProgrammePrimaryCare.Preinscrit:
      return <BoutonInscrireAuProgrammePrimary patient={patient} />
  }
}
