import { useEffect, useState } from "react"
import { useGraphQLQuery } from "@data/useGraphQL"
import { patientParIdentifiantQuery } from "@data/patient/patientParIdentifiantQuery"
import { PatientParIdentifiantQueryQuery } from "@data/gql/graphql"

export type PatientDuChannel =
  PatientParIdentifiantQueryQuery["patientParIdentifiant"]

export const usePatientDuChannel = (idProfilPatient: string) => {
  const [patient, setPatient] = useState<PatientDuChannel | null>(null)
  const [profilPrincipal, setProfilPrincipal] = useState<boolean | null>(null)
  const [nonOnboarde, setNonOnboarde] = useState<boolean | null>(null)

  // TODO(judithp): [Apollo] Use Apollo instead.
  const { data: donneesPatient } = useGraphQLQuery({
    document: patientParIdentifiantQuery,
    variables: { identifiant: idProfilPatient },
    enabled: idProfilPatient !== undefined,
    staleTime: 10000,
  })

  useEffect(() => {
    if (donneesPatient?.data?.patientParIdentifiant) {
      setPatient(donneesPatient.data.patientParIdentifiant)
      setProfilPrincipal(
        donneesPatient?.data?.patientParIdentifiant?.compte?.profilPrincipal
          ?.id === donneesPatient?.data?.patientParIdentifiant?.id,
      )
      setNonOnboarde(
        donneesPatient?.data?.patientParIdentifiant.compte === null,
      )
    } else {
      setPatient({ id: idProfilPatient } as PatientDuChannel)
      setProfilPrincipal(null)
      setNonOnboarde(null)
    }
  }, [donneesPatient, idProfilPatient])

  return { patient, profilPrincipal, nonOnboarde }
}
