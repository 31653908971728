import { TypedDocumentString } from "@data/gql/graphql"
import { IAuthService } from "@infra/auth/IAuthService"
import { QueryClient, QueryKey } from "@tanstack/react-query"
import { ExecutionResult } from "graphql"

export interface IGraphQLQueryClient {
  queryClient: QueryClient
  fetch<TResult, TVariables>(
    document: TypedDocumentString<TResult, TVariables>,
    variables: TVariables | undefined,
    invalidateQueryKeys?: QueryKey[],
  ): Promise<ExecutionResult<TResult>>
  queryFn<TResult, TVariables>(
    document: TypedDocumentString<TResult, TVariables>,
    variables: TVariables | undefined,
    invalidateQueryKeys?: QueryKey[],
  ): Promise<ExecutionResult<TResult>>
}

export class GraphQLQueryClient implements IGraphQLQueryClient {
  constructor(authService: IAuthService) {
    this.#authService = authService
  }

  queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  })

  #authService: IAuthService

  async fetch<TResult, TVariables>(
    document: TypedDocumentString<TResult, TVariables>,
    variables: TVariables,
    invalidateQueryKeys: QueryKey[] = [],
  ): Promise<ExecutionResult<TResult>> {
    return this.queryClient.fetchQuery({
      queryKey: [document, variables],
      queryFn: () => this.queryFn(document, variables, invalidateQueryKeys),
    })
  }

  async queryFn<TResult, TVariables>(
    document: TypedDocumentString<TResult, TVariables>,
    variables: TVariables,
    invalidateQueryKeys: QueryKey[] = [],
  ): Promise<ExecutionResult<TResult>> {
    const { getToken } = this.#authService

    const token = await getToken()

    const response = await fetch(import.meta.env.VITE_BACKEND_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query: document.toString(),
        variables: variables,
      }),
    })

    const result = await response.json()

    await Promise.all(
      invalidateQueryKeys.map((key) =>
        this.queryClient.invalidateQueries({
          queryKey: key,
        }),
      ),
    )

    return result
  }
}
