import { graphql } from "@data/gql"

export const enregistrerSyntheseContexte = graphql(`
  mutation enregistrerSyntheseContexte(
    $identifiantProfilPatient: String!
    $contenu: String!
  ) {
    enregistrerSyntheseContexte(
      identifiantProfilPatient: $identifiantProfilPatient
      contenu: $contenu
    ) {
      synthese {
        contenu
      }
    }
  }
`)
