export const Logo = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
  <div data-testid="logo">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      fill="none"
      viewBox="0 0 700 146"
      {...props}
    >
      <path
        fill="currentColor"
        d="M497.293 112.152c-16.162 0-28.281-8.714-28.281-24.084 0-15.052 10.458-25.034 28.996-26.143l22.605-.546c0-5.862-6.15-13.003-17.558-13.003-11.725 0-16.772 6.577-18.515 11.647l-15.845-8.08c4.912-11.726 15.845-22.183 34.858-22.183 25.035 0 36.918 16.32 36.918 31.69v50.702h-19.805v-8.714c-3.962 6.179-12.599 8.714-23.373 8.714Zm-8.158-24.876c0 5.546 4.595 9.032 11.091 9.032 10.616 0 20.756-6.972 20.756-17.112l.02-1.238-21.727.128c-6.634.01-10.14 3.645-10.14 9.19ZM333.375 112.107V31.274h19.806v8.581c3.644-6.654 11.71-10.126 21.851-10.126 10.774 0 19.345 3.155 24.415 10.443 4.436-5.704 12.447-10.443 25.598-10.443 18.538 0 32.076 12.186 32.076 28.19v54.188h-19.806v-48.01c0-10.298-6.831-15.721-16.02-15.721-9.982 0-16.144 6.056-16.144 15.722v48.009h-19.806v-48.01c0-10.298-6.833-15.764-16.022-15.764-9.982 0-16.142 6.1-16.142 15.765v48.009h-19.806ZM301.684 112.153V31.288h19.805v80.865h-19.805ZM237.141 31.27v80.882h19.434v-47.11c0-9.484 6.064-16.636 15.859-16.636a14.174 14.174 0 0 1 12.48 6.038l.598.857 9.263-20.797-.865-.441c-4.558-2.324-10.639-4.304-15.891-4.304-9.951 0-17.868 4.965-21.444 11.495V31.27h-19.434ZM142.887 145.194V31.274h19.012v9.277c4.715-6.236 14.434-10.802 25.233-10.802 21.141 0 39.104 15.365 39.104 41.07 0 24.335-17.811 41.335-39.104 41.335-10.799 0-20.67-4.984-25.233-11.22v44.26h-19.012Zm19.708-74.375c0 12.776 8.126 22.118 21.662 22.118 13.689 0 21.663-9.342 21.663-22.118 0-12.929-7.974-22.271-21.663-22.271-13.536 0-21.662 9.342-21.662 22.27ZM552.367 31.271v80.882h19.435v-47.11c0-9.484 6.064-16.636 15.859-16.636a14.175 14.175 0 0 1 12.48 6.038l.598.857 9.263-20.797-.866-.441c-4.557-2.324-10.639-4.304-15.891-4.304-9.95 0-17.867 4.965-21.443 11.495v-9.984h-19.435ZM658.081 80.411l-19.817-49.136h-21.362l30.492 75.475-2.286 5.77-13.197 32.663h21.356l46.028-113.908h-21.362L658.081 80.41ZM311.584 24.332c-6.699 0-12.131-5.43-12.131-12.13 0-6.698 5.432-12.129 12.131-12.129s12.131 5.43 12.131 12.13c0 6.698-5.432 12.129-12.131 12.129Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M61.709 86.322 49.863 115.64l18.364 7.42L81.96 89.067l.006.006 8.065-19.983 12.294-30.427-18.363-7.42L73.81 56.372 37.277 25.714l-12.73 15.172 28.702 24.086.005-.003L66.01 75.676l-4.302 10.646Zm-8.404 2.668 4.585-11.254-9.48-7.972-36.026 11.438 5.994 18.877L53.305 88.99Zm57.885-1.25L95.232 74.348 87.434 93.66l11.025 9.252L111.19 87.74Z"
        clipRule="evenodd"
      />
    </svg>
  </div>
)
